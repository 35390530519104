import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import InputField from "./FormFields";
import googleLogo from "./../../Assets/Images/google.svg";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector, connect } from "react-redux";
import { login } from "../../services/api";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import AnnotationInfo from "./../../Assets/Images/annotation-info.svg";
import { BackArrow } from "../../Assets/icons";
import { resetPasswordAPI } from "../../services/api";

function ForgotPassword({ loginError }) {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);

    const notify = (type) => {
        if (type == "success") {
            return toast.success("Script is successfully installed");
        } else {
            return toast.error("Script is not installed.");
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        if (data.email) {
            // setIsFormSubmitted(true);
            // dispatch(login({ email: data.email }));
            verifyInstallation(data.email);
        }
    };

    useEffect(() => {
        const jwtCookie = Cookies.get("jwt");
        if (jwtCookie) {
            window.location.href = "/dashboard";
        }
    }, [userData]);

    if (loginError) {
        notify();
    }

    const verifyInstallation = async (email) => {
        setIsVerifying(true);
        const verificationPromise = new Promise(async (resolve, reject) => {
            try {
                const response = await resetPasswordAPI(email);
                if (response.status === 200) {
                    setIsFormSubmitted(true);
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 2000);

                    resolve("Please check your registered email");
                } else if (response.status === 404) {
                    reject("Email not registered");
                } else {
                    reject("Error occurred. Please try again.");
                }
            } catch (error) {
                setIsVerifying(false);
                reject("Could not save");
            }
        });

        toast.promise(verificationPromise, {
            loading: "Verifying email ...",
            success: "Please check you registred email",
            error: (err) => err,
        });
    };

    return (
        <div>
            <form className="mx-w400 mx-auto" onSubmit={handleSubmit(onSubmit)}>
                <Toaster position="bottom-center" reverseOrder={false} />
                <h1 className="h2 mb-2">Forgot Password</h1>
                <p className="text-muted mb-5">
                    Enter your registered email for verification
                </p>
                <div class="frame-forgot-password text-muted mb-5">
                    <img className="annotation-info" src={AnnotationInfo} />
                    <p class="if-an-account-with">
                        If an account with that email address exists, you&#39;ll
                        receive an email with instructions on how to reset your
                        password.
                    </p>
                </div>
                <InputField
                    label="Email"
                    placeholder="user@email.com"
                    type="text"
                    name="email"
                    register={register("email", { required: true })}
                />
                {errors.email && errors.email.type === "required" && (
                    <p className="error-message">Email address is required.</p>
                )}

                <div className="form-group">
                    <Button color="primary" block>
                        Reset Password
                    </Button>
                </div>
                <div className="form-group text-center">
                    <p className="text-muted forgot-password-signin">
                        <BackArrow />
                        Back to{" "}
                        <Link to="/login" className="text-primary-2 h7 fw-400">
                            {" "}
                            Sign in{" "}
                        </Link>
                    </p>
                </div>
            </form>
            <Toaster position="bottom-center" reverseOrder={false} />
        </div>
    );
}

const mapStateToProps = (state) => ({
    loginError: state.user.error,
});

export default connect(mapStateToProps)(ForgotPassword);
