import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getSessionRecordListData,
    getSessionReplayRawData,
    getSessionReplayErrorListData,
    getResourceAndTimeData,
} from "../../services/api";

export const getSessionList = createAsyncThunk(
    "sessionrecord/getSessionList",
    async ({ userId, page, limit }, thunkAPI) => {
        try {
            const response = await getSessionRecordListData(
                userId,
                page,
                limit
            );
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getSessionReplayData = createAsyncThunk(
    "sessionrecord/getSessionReplayData",
    async (sessionid, thunkAPI) => {
        try {
            const response = await getSessionReplayRawData(sessionid);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const getSessionReplayErrorList = createAsyncThunk(
    "sessionrecord/getSessionReplayErrorList",
    async (sessionid, thunkAPI) => {
        try {
            const response = await getSessionReplayErrorListData(sessionid);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getResourceAndTime = createAsyncThunk(
    "sessionrecord/getResourceAndTime",
    async (sessionid, thunkAPI) => {
        try {
            const response = await getResourceAndTimeData(sessionid);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const sessionrecordSlice = createSlice({
    name: "sessionrecord",
    initialState: {
        sessionRecordData: null,
        error: null,
        sessionRecordRawData: null,
        sessionReplayErrors: null,
        sessionResourceData: null,
    },
    reducers: {},
    extraReducers: {
        [getSessionList.pending]: (state) => {
            state.status = "loading";
        },
        [getSessionList.fulfilled]: (state, { payload }) => {
            state.sessionRecordData = payload.data;
            state.status = "fulfilled";
        },
        [getSessionList.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
        [getSessionReplayData.pending]: (state) => {
            state.status = "loading";
        },
        [getSessionReplayData.fulfilled]: (state, { payload }) => {
            state.sessionRecordRawData = payload;
            state.status = "fulfilled";
        },
        [getSessionReplayData.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
        [getSessionReplayErrorList.pending]: (state) => {
            state.status = "loading";
        },
        [getSessionReplayErrorList.fulfilled]: (state, { payload }) => {
            state.sessionReplayErrors = payload;
            state.status = "fulfilled";
        },
        [getSessionReplayErrorList.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
        [getResourceAndTime.pending]: (state) => {
            state.status = "loading";
        },
        [getResourceAndTime.fulfilled]: (state, { payload }) => {
            state.sessionResourceData = payload;
            state.status = "fulfilled";
        },
        [getResourceAndTime.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
    },
});

export const sessionRecordReducer = sessionrecordSlice.reducer;
