import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getResourceAndTime } from "../../../redux/sessionrecord/sessionRecordSlice";
import GanttChart from "./GanttChart";
import { Table, Tooltip, Tag } from "antd";

function ErrorList(props) {
    const { errorlist, onListItemClick } = props;

    const firstErrorTime =
        errorlist && errorlist.details.length > 0
            ? errorlist.details[0].error_time
            : 0;

    const dispatch = useDispatch();
    const { session_id } = useParams();

    useEffect(() => {
        dispatch(getResourceAndTime(session_id));
    }, [session_id]);

    const sessionRecordRawData = useSelector(
        (state) => state.sessionrecord.sessionResourceData
    );

    const resourceList = sessionRecordRawData
        ? sessionRecordRawData.details
        : [];

    const transformData = (data) => {
        const minStartTime = Math.min(...data.map((item) => item.starttime));

        return data.map((item, index) => ({
            id: `task-${item.id}`,
            name: item.name,
            start: (item.starttime - minStartTime) * 1000,
            end: (item.starttime - minStartTime + item.duration) * 1000,
            y: index,
            color: item.cachestatus === "cached" ? "#82ca9d" : "#8884d8",
        }));
    };

    const transformedData = transformData(resourceList);

    const columnsNew = [
        {
            title: "Time Taken",
            dataIndex: "duration",
            key: "duration",
            with: 200,
            // render: (text) => ()`${text.toFixed(1)} ms`,
            // onClick={() => onListItemClick(record.error_time)}
            render: (text) => (
                <span
                    className="move-cursor-row"
                    onClick={() =>
                        onListItemClick(
                            text.toFixed(1) / 1000 < 1
                                ? 1000
                                : (text.toFixed(1) / 1000) * 1000
                        )
                    }
                >
                    {text.toFixed(1)} ms
                </span>
            ),
        },
        {
            title: "Resource Type",
            dataIndex: "initiatortype",
            key: "initiatortype",
            width: 200,
            render: (tag) => {
                let color = tag.length > 5 ? "geekblue" : "green";
                if (tag === "xmlhttprequest") {
                    color = "volcano";
                }
                return (
                    <Tag color={color} key={tag}>
                        {tag.toUpperCase()}
                    </Tag>
                );
            },
        },
        {
            title: "Status Code",
            dataIndex: "status",
            key: "status",
            render: (status) => {
                let color;
                if (status >= 200 && status < 300) {
                    color = "green";
                } else if (status >= 300 && status < 400) {
                    color = "blue";
                } else if (status >= 400 && status < 500) {
                    color = "orange";
                } else {
                    color = "red";
                }
                return (
                    <Tag color={color} key={status}>
                        {status}
                    </Tag>
                );
            },
        },
        {
            title: "URL",
            dataIndex: "name",
            key: "name",
            render: (text) => (
                <Tooltip title={text}>
                    <span>
                        <a
                            href={text}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ cursor: "pointer" }}
                        >
                            {text.length > 50
                                ? `${text.substring(0, 50)}...`
                                : text}
                        </a>
                    </span>
                </Tooltip>
            ),
        },
    ];

    return (
        <div>
            <Table
                dataSource={resourceList}
                columns={columnsNew}
                rowKey="id"
                pagination={{ pageSize: 10 }}
            />
            {/* <GanttChart
                data={transformedData}
                firstErrorTime={firstErrorTime}
            /> */}
        </div>
    );
}

export default ErrorList;
