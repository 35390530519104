import React, { useState, useEffect, useRef } from "react";
import "./_style.scss";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { Button, ButtonGroup } from "reactstrap";
import closeIcon from "../../Assets/Images/x-circle.svg";
import CustomTooltip from "../Charts/Tooltip";
import { getTotalSessionDateFilter } from "../../redux/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";

function PopupMenu({
    onClose,
    firstColor,
    strokeColor,
    id,
    rawData,
    userId,
    parentComponent,
}) {
    const dispatch = useDispatch();
    const { totalSessionRangeData } = useSelector((state) => state.dashboard);
    const [activeIndex, setActiveIndex] = useState(1);
    const [currentTotalSession, setCurrentSession] = useState(rawData);

    useEffect(() => {
        let args = {
            userId,
            range: buttons[activeIndex],
        };
        dispatch(getTotalSessionDateFilter(args));
    }, []);

    useEffect(() => {
        setCurrentSession(totalSessionRangeData);
    }, [totalSessionRangeData]);

    const getFilteredData = (range) => {
        let args = {
            userId,
            range,
        };
        dispatch(getTotalSessionDateFilter(args));
    };

    const buttons = ["12 Months", "3 Months", "30 Days", "7 Days", "24 hours"];

    let data;
    if (currentTotalSession?.length === 0) {
        data = [{ date: "No Data", count: 0 }];
    } else {
        data = currentTotalSession?.map((item) => {
            const date = new Date(item.date);
            const day = date.getDate();
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const monthName = monthNames[date.getMonth()];
            const formattedDate = `${monthName} ${day}`;

            return {
                date: formattedDate,
                count: Number(item.count),
            };
        });
    }

    return (
        <div className={"popup-container card"}>
            <div className="popup-tital">
                <div className="dash-card-title mb-0 h6">Total session</div>
                <div className="close-icon">
                    <img src={closeIcon} onClick={onClose} />
                </div>
            </div>

            <div className={"date-filter-popup"}>
                <ButtonGroup className="w-100 rounded-5 group-tab mt-2 button-group-custom">
                    {buttons.map((label, index) => (
                        <Button
                            key={index}
                            size="sm"
                            color="light"
                            className={`h8 rounded-5 tab-item border-0 fw-100 text-position-custom ${
                                activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => {
                                setActiveIndex(index);
                                getFilteredData(label);
                            }}
                        >
                            {label}
                        </Button>
                    ))}
                </ButtonGroup>
            </div>

            <div className="chart-content">
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={1100}
                        height={500}
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid stroke="transparent" />
                        <defs>
                            <linearGradient
                                id={`colorUv-${id}`}
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="5%"
                                    stopColor={firstColor}
                                    stopOpacity={0.5}
                                />
                                <stop
                                    offset="95%"
                                    stopColor={firstColor}
                                    stopOpacity={0}
                                />
                            </linearGradient>
                            <linearGradient
                                id={`colorUv-${id}`}
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="5%"
                                    stopColor={firstColor}
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="95%"
                                    stopColor={firstColor}
                                    stopOpacity={0}
                                />
                            </linearGradient>
                        </defs>
                        <XAxis
                            axisLine={{ stroke: "#f2edfc" }}
                            dataKey="date"
                            tickLine={false}
                            tick={{ fontSize: 12 }}
                        />
                        <YAxis
                            tickLine={false}
                            axisLine={{ stroke: "#f2edfc" }}
                            tick={{ fontSize: 12 }}
                        />
                        <Tooltip
                            wrapperStyle={{ outline: "none" }}
                            content={<CustomTooltip />}
                        />
                        <Area
                            type="linear"
                            dataKey="count"
                            stroke={strokeColor}
                            strokeWidth={2}
                            fill={`url(#colorUv-${id})`}
                            // dot={{ fill: "#f2edfc" }}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
export default PopupMenu;
