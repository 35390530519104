// import React, { useEffect, useRef, useState } from "react";
// import { useParams, useSearchParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import h337 from "heatmap.js";
// import { fetchFullScreenShort } from "../../../redux/company/companySlice";
// import { getHeatMapMouseData } from "../../../redux/heatmap/heatmapSlice";
// import Lottie from "react-lottie";
// import * as animationData from "../../../Assets/Gif/heatmapherogif.json";

// function FullScreenHeatMap(props) {
//     const [searchParams] = useSearchParams();

//     const pagename = searchParams.get("pagename");
//     const pageurl = searchParams.get("pageurl");
//     const dispatch = useDispatch();
//     const containerRef = useRef(null);
//     const imageRef = useRef(null);
//     const heatmapContainer = useRef();
//     const url = decodeURIComponent(pageurl);
//     const mouseData = useSelector((state) => state.company.mouseData);
//     const pagesData = mouseData ? mouseData.details : [];

//     const { heatmapData } = useSelector((state) => state.heatmap);
//     const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         dispatch(getHeatMapMouseData(url));
//         dispatch(fetchFullScreenShort(pagename));
//     }, [dispatch]);

//     useEffect(() => {
//         if (containerRef.current && imageSize.scaleX && imageSize.scaleY) {
//             var config = {
//                 container: document.querySelector(".heatmap"),
//                 radius: 10,
//                 maxOpacity: 0.5,
//                 minOpacity: 0,
//                 blur: 0.75,
//             };

//             const heatmapInstance = h337.create(config);
//             const points = [];

//             const scaleX =
//                 heatmapContainer.current.offsetWidth / imageSize.width;
//             const scaleY =
//                 heatmapContainer.current.offsetHeight / imageSize.height;

//             heatmapData?.forEach((dataPoints) => {
//                 dataPoints?.data.forEach((mouseEvent) => {
//                     let point = {
//                         x: mouseEvent.x * imageSize.scaleX,
//                         y: mouseEvent.y * imageSize.scaleY,
//                         value: 1,
//                     };
//                     points.push(point);
//                 });
//             });

//             const formattedData = {
//                 max: 5,
//                 data: points.map((d) => ({
//                     x: d.x / scaleX,
//                     y: d.y / scaleY,
//                 })),
//             };

//             heatmapInstance.setData(formattedData);
//         }
//     }, [heatmapData, imageSize.scaleX, imageSize.scaleY]);

//     const handleImageLoad = () => {
//         const { naturalWidth, naturalHeight } = imageRef.current;

//         heatmapContainer.current.style.width = `${naturalWidth}px`;
//         heatmapContainer.current.style.height = `${naturalHeight}px`;

//         setImageSize({
//             width: naturalWidth,
//             height: naturalHeight,
//             scaleX: heatmapContainer.current.offsetWidth / naturalWidth,
//             scaleY: heatmapContainer.current.offsetHeight / naturalHeight,
//         });

//         const timer = setTimeout(() => {
//             applyStyles();
//         }, 5000);
//     };

//     const applyStyles = () => {
//         if (heatmapContainer.current) {
//             heatmapContainer.current.style.width = "1400px";
//             heatmapContainer.current.style.opacity = "1"; // Set opacity to 1

//             const canvas =
//                 heatmapContainer.current.querySelector(".heatmap-canvas");
//             if (canvas) {
//                 canvas.style.width = "1400px";
//             }
//             setLoading(false);
//         }
//     };

//     return (
//         <div
//             className={"heatmap-container"}
//             style={{ left: "17%" }}
//             ref={containerRef}
//         >
//             <div className={`background`}>
//                 <div className="bounding-box" ref={heatmapContainer}>
//                     <img
//                         ref={imageRef}
//                         src={pagesData.screenshot_url}
//                         alt=""
//                         onLoad={handleImageLoad}
//                     />
//                     <div className="heatmap" width="860"></div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default FullScreenHeatMap;

import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import h337 from "heatmap.js";
import { fetchFullScreenShort } from "../../../redux/company/companySlice";
import { getHeatMapMouseData } from "../../../redux/heatmap/heatmapSlice";
import Lottie from "react-lottie";
import * as animationData from "../../../Assets/Gif/heatmapherogif.json";
import MouseMoveHeatmap from "./MouseMoveHeatmap";

function FullScreenHeatMap(props) {
    return (
        <div style={{ left: "17%", position: "absolute" }}>
            <MouseMoveHeatmap dynamicWidth={"1400px"} />
        </div>
    );
}

export default FullScreenHeatMap;
