import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import { getTopClickCount } from "../../../redux/dashboard/dashboardSlice";
import { ThreeDotsIcon } from "../../../Assets/icons";

function TopClicks(props) {
    const dispatch = useDispatch();

    const { topClickCount, status, error } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        if (props.userId) {
            dispatch(getTopClickCount(props.userId));
        }
    }, [dispatch]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const topPagesObj = topClickCount
        ? topClickCount.map((obj) => {
              let name = obj.page_url.split("/").filter(Boolean).pop();
              name = capitalizeFirstLetter(name);
              let page = obj.page_url;
              if (page.length > 20) {
                  const url = new URL(page);
                  let shortUrl = url.hostname + url.pathname;
                  if (shortUrl.length > 10) {
                      shortUrl =
                          shortUrl.substring(0, 5) +
                          "..." +
                          shortUrl.substring(
                              shortUrl.length - 5,
                              shortUrl.length
                          );
                  }
                  page = shortUrl;
              }
              return {
                  ...obj,
                  page,
                  name,
              };
          })
        : [];

    let maxClicks;
    if (topPagesObj) {
        maxClicks = Math.max(
            ...topPagesObj.map((item) => Number(item.count + 1))
        );
    } else {
        return <div>Loading...</div>;
    }

    function getUniqueRecords(data) {
        let result = {};

        data.forEach((item) => {
            if (!result[item.page_url]) {
                result[item.page_url] = 0;
            }
            result[item.page_url] += parseInt(item.count);
        });

        return Object.entries(result).map(([page_url, count]) => {
            return {
                page_url: page_url,
                count: count,
            };
        });
    }

    const filterdData = getUniqueRecords(topPagesObj);

    return (
        <Col md="6">
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h4 className="dash-card-title mb-0 h6">Top Clicks</h4>
                        <a href="#!">
                            <ThreeDotsIcon />
                        </a>
                    </div>
                </div>
                <div className="card-body scroll--height pt-0 top-click-section">
                    {filterdData.map((item, index) => {
                        const progressPercentage =
                            (item.count / maxClicks) * 100;
                        return (
                            <div key={index} className="progress-item">
                                <div className="progress-label">
                                    <span className="left">
                                        {item.page_url}
                                    </span>
                                    <div className="right">
                                        <a
                                            href="/heatmap"
                                            className="d-flex align-items-center"
                                        >
                                            {item.count} Clicked
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                className="ms-1"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6 12L10 8L6 4"
                                                    stroke="#292934"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="progress">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            width: `${progressPercentage}%`,
                                        }}
                                        aria-valuenow={progressPercentage}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Col>
    );
}

export default TopClicks;
