import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert } from "../../redux/alerts/alertsSlice";

import TotalSession from "./Dashboard/TotalSession";
import CurrentSession from "./Dashboard/CurrentSession";
import SessionDuration from "./Dashboard/SessionDuration";
function CreateAlert() {
    const [alertCriteria, setAlertCriteria] = useState("");
    const [condition, setCondition] = useState("");
    const [threshold, setThreshold] = useState("");
    const [email, setEmail] = useState("");
    const [alertName, setAlertName] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const criteriaOptions = ["Total user", "Current session", "Bounce rate"];
    const conditionOptions = ["Less than", "Greater than", "Equal"];
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const userObject = useSelector((state) => state.user.userData);
    const userId = userObject?.user?.uniqueid;
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const notify = () => toast.success("Alert created successfully");

    const validateField = (field, value) => {
        let newErrors = { ...errors };
        switch (field) {
            case "alertCriteria":
                if (!value)
                    newErrors.alertCriteria = "Please select an alert criteria";
                else delete newErrors.alertCriteria;
                break;
            case "alertName":
                if (!value) newErrors.alertName = "Alert name required";
                else delete newErrors.alertName;
                break;
            case "condition":
                if (!value) newErrors.condition = "Please select a condition";
                else delete newErrors.condition;
                break;
            case "threshold":
                if (!value) newErrors.threshold = "Please enter a threshold";
                else if (isNaN(value) || value < 0)
                    newErrors.threshold = "Please enter a valid threshold";
                else delete newErrors.threshold;
                break;
            case "email":
                if (!value) newErrors.email = "Please enter your email";
                else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value))
                    newErrors.email = "Please enter a valid email";
                else delete newErrors.email;
                break;
            default:
                break;
        }
        setErrors(newErrors);
    };

    const validateForm = () => {
        let formIsValid = true;
        let newErrors = {};

        if (!alertCriteria) {
            newErrors.alertCriteria = "Please select an alert criteria";
            formIsValid = false;
        }

        if (!alertName) {
            newErrors.alertName = "Alert name is required";
            formIsValid = false;
        }

        if (!condition) {
            newErrors.condition = "Condition is required";
            formIsValid = false;
        }

        if (!threshold) {
            newErrors.threshold = "Please enter a threshold";
            formIsValid = false;
        } else if (isNaN(threshold) || threshold < 0) {
            newErrors.threshold = "Please enter a valid threshold";
            formIsValid = false;
        }

        if (!email) {
            newErrors.email = "Please enter your email";
            formIsValid = false;
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
            newErrors.email = "Please enter a valid email";
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const alertData = {
            alertCriteria,
            condition,
            threshold,
            email,
            alertName,
        };
        setButtonDisabled(true);

        dispatch(addAlert({ alertData }))
            .then(unwrapResult)
            .then((response) => {
                notify();
                setTimeout(() => {
                    navigate("/alerts");
                }, 3000);
            })
            .catch((rejectedValueOrSerializedError) => {
                console.error(
                    "Failed to add alert:",
                    rejectedValueOrSerializedError
                );
            });
    };

    return (
        <div className="main--wrapper">
            <header className="header--wrapper">
                <div className="header--inner container">
                    <div className="left">
                        <h1 className="h4 mb-0 d-flex align-items-center">
                            Alerts
                            <a href="/">
                                <svg
                                    width="17"
                                    height="17"
                                    className="ms-2"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_1075_2463)">
                                        <path
                                            d="M8.50033 11.3337V8.50033M8.50033 5.66699H8.50741M15.5837 8.50033C15.5837 12.4123 12.4123 15.5837 8.50033 15.5837C4.58831 15.5837 1.41699 12.4123 1.41699 8.50033C1.41699 4.58831 4.58831 1.41699 8.50033 1.41699C12.4123 1.41699 15.5837 4.58831 15.5837 8.50033Z"
                                            stroke="#B0B0BB"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1075_2463">
                                            <rect
                                                width="17"
                                                height="17"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </h1>
                    </div>
                    <div className="right d-flex">
                        <div className="input-wrapper me-2">
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control fw-semibold form-control-sm bg-light"
                            />
                            <svg
                                width="20"
                                className="absolute--right"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.5 18L14.5834 15.0833M16.6667 10.0833C16.6667 13.9954 13.4954 17.1667 9.58333 17.1667C5.67132 17.1667 2.5 13.9954 2.5 10.0833C2.5 6.17132 5.67132 3 9.58333 3C13.4954 3 16.6667 6.17132 16.6667 10.0833Z"
                                    stroke="#C2C0C9"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                        <Button
                            color="secondary"
                            size="sm"
                            className="notification--btn px-2 ms-2"
                        >
                            <svg
                                className="btn-icon"
                                width="20"
                                height="23"
                                viewBox="0 0 20 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.35395 20.5C8.05908 21.1224 8.98533 21.5 9.99977 21.5C11.0142 21.5 11.9405 21.1224 12.6456 20.5M15.9998 7.5C15.9998 5.9087 15.3676 4.38258 14.2424 3.25736C13.1172 2.13214 11.5911 1.5 9.99977 1.5C8.40848 1.5 6.88235 2.13214 5.75713 3.25736C4.63192 4.38258 3.99977 5.9087 3.99977 7.5C3.99977 10.5902 3.22024 12.706 2.34944 14.1054C1.6149 15.2859 1.24763 15.8761 1.2611 16.0408C1.27601 16.2231 1.31463 16.2926 1.46155 16.4016C1.59423 16.5 2.19237 16.5 3.38863 16.5H16.6109C17.8072 16.5 18.4053 16.5 18.538 16.4016C18.6849 16.2926 18.7235 16.2231 18.7384 16.0408C18.7519 15.8761 18.3846 15.2859 17.6501 14.1054C16.7793 12.706 15.9998 10.5902 15.9998 7.5Z"
                                    stroke="#292934"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="badge badge-danger">03</span>
                        </Button>
                    </div>
                </div>
            </header>
            <div className="content">
                <div className="content-inner">
                    <div className="row g-4">
                        <div className="col-md-7">
                            <div className="card">
                                <div className="card-body">
                                    <div className="alert-box--wrapper">
                                        <svg
                                            width="68"
                                            height="68"
                                            viewBox="0 0 68 68"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                opacity="0.04"
                                                cx="34"
                                                cy="34"
                                                r="34"
                                                fill="#7B3FE4"
                                            />
                                            <circle
                                                opacity="0.1"
                                                cx="34"
                                                cy="34"
                                                r="24"
                                                fill="#7B3FE4"
                                            />
                                            <path
                                                d="M31.354 43C32.0591 43.6224 32.9853 44 33.9998 44C35.0142 44 35.9405 43.6224 36.6456 43M39.9998 30V24M36.9998 27H42.9998M34.9998 24.0839C34.6715 24.0284 34.3371 24 33.9998 24C32.4085 24 30.8824 24.6321 29.7571 25.7574C28.6319 26.8826 27.9998 28.4087 27.9998 30C27.9998 33.0902 27.2202 35.206 26.3494 36.6054C25.6149 37.7859 25.2476 38.3761 25.2611 38.5408C25.276 38.7231 25.3146 38.7926 25.4616 38.9016C25.5942 39 26.1924 39 27.3886 39H40.6109C41.8071 39 42.4052 39 42.5379 38.9016C42.6848 38.7926 42.7235 38.7231 42.7384 38.5408C42.7518 38.3761 42.3845 37.7858 41.65 36.6052C41.1579 35.8144 40.695 34.7948 40.3855 33.5"
                                                stroke="#7B3FE4"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <h2 className="h5 my-3 py-1">
                                            Add your alert
                                        </h2>
                                        {/* <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <select
                                                    className="form-select"
                                                    value={alertCriteria}
                                                    onChange={(e) =>
                                                        setAlertCriteria(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option>
                                                        Performance Thresholds
                                                    </option>
                                                    {criteriaOptions.map(
                                                        (option, index) => (
                                                            <option
                                                                key={index}
                                                                value={option}
                                                            >
                                                                {option}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>

                                            <div className="row form-group gx-2">
                                                <div className="col-sm-6 col-md-3">
                                                    <div className="form-group">
                                                        <select
                                                            className="form-select"
                                                            value={condition}
                                                            onChange={(e) =>
                                                                setCondition(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <option>
                                                                Condition
                                                            </option>
                                                            {conditionOptions.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <option
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-9">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Add alert threshold"
                                                            className="form-control"
                                                            value={threshold}
                                                            onChange={(e) =>
                                                                setThreshold(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={(e) =>
                                                        setEmail(e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <button
                                                    className="btn btn-primary rounded-12 w-100"
                                                    type="submit"
                                                >
                                                    <svg
                                                        width="21"
                                                        height="20"
                                                        viewBox="0 0 21 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="me-1"
                                                    >
                                                        <path
                                                            d="M10.5003 4.16699V15.8337M4.66699 10.0003H16.3337"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    Create Alert
                                                </button>
                                            </div>
                                        </form> */}
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    placeholder="Enter alert name"
                                                    className="form-control"
                                                    value={alertName}
                                                    onChange={(e) => {
                                                        setAlertName(
                                                            e.target.value
                                                        );
                                                        validateField(
                                                            "alertName",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                {errors.alertName && (
                                                    <p className="text-danger error-message">
                                                        {errors.alertName}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="form-select"
                                                    value={alertCriteria}
                                                    onChange={(e) => {
                                                        setAlertCriteria(
                                                            e.target.value
                                                        );
                                                        validateField(
                                                            "alertCriteria",
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        Performance Thresholds
                                                    </option>
                                                    {criteriaOptions.map(
                                                        (option, index) => (
                                                            <option
                                                                key={index}
                                                                value={option}
                                                            >
                                                                {option}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                {errors.alertCriteria && (
                                                    <p className="text-danger error-message">
                                                        {errors.alertCriteria}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="row form-group gx-2">
                                                <div className="col-sm-6 col-md-3">
                                                    <div className="form-group">
                                                        <select
                                                            className="form-select"
                                                            value={condition}
                                                            onChange={(e) => {
                                                                setCondition(
                                                                    e.target
                                                                        .value
                                                                );
                                                                validateField(
                                                                    "condition",
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        >
                                                            <option value="">
                                                                Condition
                                                            </option>
                                                            {conditionOptions.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <option
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                        {errors.condition && (
                                                            <p className="text-danger error-message">
                                                                {
                                                                    errors.condition
                                                                }
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-9">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Add alert threshold"
                                                            className="form-control"
                                                            value={threshold}
                                                            onChange={(e) => {
                                                                setThreshold(
                                                                    e.target
                                                                        .value
                                                                );
                                                                validateField(
                                                                    "threshold",
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                        {errors.threshold && (
                                                            <p className="text-danger error-message">
                                                                {
                                                                    errors.threshold
                                                                }
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(
                                                            e.target.value
                                                        );
                                                        validateField(
                                                            "email",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                {errors.email && (
                                                    <p className="text-danger error-message">
                                                        {errors.email}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <button
                                                    className="btn btn-primary rounded-12 w-100"
                                                    type="submit"
                                                    disabled={buttonDisabled}
                                                >
                                                    Create Alert
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body pe-0">
                                <div className="d-flex justify-content-between">
                                    <h2 className="h5 text-gray mb-0">
                                        Recent heatmaps
                                    </h2>
                                    <Dropdown
                                        isOpen={dropdownOpen}
                                        toggle={toggle}
                                    >
                                        <DropdownToggle className="btn-sm d-flex align-items-center">
                                            <svg
                                                className="dropdown-left-icon"
                                                width="18"
                                                height="19"
                                                viewBox="0 0 18 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M16.5 7.83366H1.5M12.3333 1.16699V4.50033M5.66667 1.16699V4.50033M5.5 17.8337H12.5C13.9001 17.8337 14.6002 17.8337 15.135 17.5612C15.6054 17.3215 15.9878 16.939 16.2275 16.4686C16.5 15.9339 16.5 15.2338 16.5 13.8337V6.83366C16.5 5.43353 16.5 4.73346 16.2275 4.19868C15.9878 3.72828 15.6054 3.34583 15.135 3.10614C14.6002 2.83366 13.9001 2.83366 12.5 2.83366H5.5C4.09987 2.83366 3.3998 2.83366 2.86502 3.10614C2.39462 3.34583 2.01217 3.72828 1.77248 4.19868C1.5 4.73346 1.5 5.43353 1.5 6.83366V13.8337C1.5 15.2338 1.5 15.9339 1.77248 16.4686C2.01217 16.939 2.39462 17.3215 2.86502 17.5612C3.3998 17.8337 4.09987 17.8337 5.5 17.8337Z"
                                                    stroke="#74747F"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            12 Months
                                            <svg
                                                className="dropdown-right-icon"
                                                width="10"
                                                height="7"
                                                viewBox="0 0 10 7"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1 1.5L5 5.5L9 1.5"
                                                    stroke="#74747F"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>
                                                3 Months
                                            </DropdownItem>
                                            <DropdownItem>
                                                6 Months
                                            </DropdownItem>
                                            <DropdownItem>
                                                9 Months
                                            </DropdownItem>
                                            <DropdownItem>
                                                12 Months
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div className="dash-vertical-wrapper">
                                    <div className="dash-item border-bottom border-light-2 card-body px-0">
                                        <TotalSession userId={userId} md={12} />
                                    </div>
                                    <div className="dash-item border-bottom border-light-2 card-body px-0">
                                        <CurrentSession
                                            userId={userId}
                                            md={12}
                                        />
                                    </div>
                                    <div className="dash-item border-bottom border-light-2 card-body px-0">
                                        <SessionDuration
                                            userId={userId}
                                            md={12}
                                        />
                                    </div>
                                    {/* <div className="dash-item border-bottom border-light-2 card-body px-0">
                                        <div className="dash-card-header">
                                            <h4 className="dash-card-title mb-0 h6">
                                                Cuurent Session
                                            </h4>
                                            <span className="text-danger h6 mb-0 icon-item d-flex align-items-center">
                                                1.23%
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="none"
                                                    className="ms-2"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <rect
                                                        width="20"
                                                        height="20"
                                                        fill="#FBE1E6"
                                                        rx="10"
                                                    ></rect>
                                                    <path
                                                        stroke="#BC3852"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                        d="M6.667 6.667l6.667 6.667m0 0V6.667m0 6.667H6.667"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="dash-card-body">
                                            <h3 className="h2">146</h3>
                                            <div className="chart-wrapper">
                                                <AreaChartComponent
                                                    id={"blue"}
                                                    firstColor={"#4E2892"}
                                                    strokeColor={"#7b3fe4"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dash-item border-bottom border-light-2 card-body px-0">
                                        <div className="dash-card-header">
                                            <h4 className="dash-card-title mb-0 h6">
                                                Total Session
                                            </h4>
                                            <span className="text-info h6 mb-0 icon-item d-flex align-items-center">
                                                2.15%
                                                <svg
                                                    className="ms-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="none"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <rect
                                                        width="20"
                                                        height="20"
                                                        fill="#E1F1F5"
                                                        rx="10"
                                                    ></rect>
                                                    <path
                                                        stroke="#378A9C"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                        d="M6.667 13.334l6.667-6.667m0 0H6.667m6.667 0v6.667"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="dash-card-body">
                                            <h3 className="h2">1,245</h3>
                                            <div className="chart-wrapper">
                                                <AreaChartComponent
                                                    id={"blue"}
                                                    firstColor={"#4E2892"}
                                                    strokeColor={"#7b3fe4"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dash-item border-bottom border-light-2 card-body px-0">
                                        <div className="dash-card-header">
                                            <h4 className="dash-card-title mb-0 h6">
                                                Session Duration
                                            </h4>
                                            <span className="text-info h6 mb-0 icon-item d-flex align-items-center">
                                                2.15%
                                                <svg
                                                    className="ms-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="none"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <rect
                                                        width="20"
                                                        height="20"
                                                        fill="#E1F1F5"
                                                        rx="10"
                                                    ></rect>
                                                    <path
                                                        stroke="#378A9C"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                        d="M6.667 13.334l6.667-6.667m0 0H6.667m6.667 0v6.667"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="dash-card-body">
                                            <h3 className="h2">12 Min</h3>
                                            <div className="chart-wrapper">
                                                <AreaChartComponent
                                                    id={"blue"}
                                                    firstColor={"#4E2892"}
                                                    strokeColor={"#7b3fe4"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dash-item card-body px-0">
                                        <div className="dash-card-header">
                                            <h4 className="dash-card-title mb-0 h6">
                                                Bounce rate
                                            </h4>
                                            <span className="text-info h6 mb-0 icon-item d-flex align-items-center">
                                                2.15%
                                                <svg
                                                    className="ms-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="none"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <rect
                                                        width="20"
                                                        height="20"
                                                        fill="#E1F1F5"
                                                        rx="10"
                                                    ></rect>
                                                    <path
                                                        stroke="#378A9C"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                        d="M6.667 13.334l6.667-6.667m0 0H6.667m6.667 0v6.667"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="dash-card-body">
                                            <div className="chart-wrapper w-100">
                                                <AreaChartComponent
                                                    id={"blue"}
                                                    firstColor={"#4E2892"}
                                                    strokeColor={"#7b3fe4"}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster position="bottom-center" reverseOrder={false} />
        </div>
    );
}

export default CreateAlert;
