import React from "react";
import { AreaChart, Area, Tooltip } from "recharts";
import CustomTooltip from "./Tooltip";

export default function AreaChartComponent({
    firstColor,
    strokeColor,
    id,
    data,
}) {
    let currentSessionChart;
    if (firstColor == "#fd7e14") {
        currentSessionChart = data?.map((item) => {
            return {
                date: item.name,
                count: Number(item.Today),
            };
        });
    }

    const datas = data?.map((item) => {
        const date = new Date(item.date);
        const day = date.getDate();
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const monthName = monthNames[date.getMonth()];
        const formattedDate = `${monthName} ${day}`;

        return {
            date: formattedDate,
            count: Number(item.count),
        };
    });

    return (
        <AreaChart
            width={200}
            height={82}
            data={firstColor == "#fd7e14" ? currentSessionChart : datas}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
            }}
        >
            <defs>
                <linearGradient
                    id={`colorUv-${id}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                >
                    <stop
                        offset="5%"
                        stopColor={firstColor}
                        stopOpacity={0.5}
                    />
                    <stop offset="95%" stopColor={firstColor} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                        offset="5%"
                        stopColor={firstColor}
                        stopOpacity={0.8}
                    />
                    <stop offset="95%" stopColor={firstColor} stopOpacity={0} />
                </linearGradient>
            </defs>
            <Tooltip
                wrapperStyle={{ outline: "none" }}
                content={<CustomTooltip />}
            />
            <Area
                strokeWidth={1.5}
                type="monotone"
                dataKey="count"
                stroke={strokeColor}
                fillOpacity={1}
                fill={`url(#colorUv-${id})`}
            />
        </AreaChart>
    );
}
