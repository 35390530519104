import React, { useState, useEffect } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import InputField from "./FormFields";
import "./../../App.scss";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signUp, startSignUp } from "../../redux/user/userSlice";
import toast, { Toaster } from "react-hot-toast";
import GoogleSignIn from "../../redux/authentication/GoogleSignin";

function Signup(props) {
    const navigate = useNavigate();
    const notify = () => toast.error("User or email already exist");
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const userError = useSelector((state) => state.user.error);

    const [isAccepted, setIsAccepted] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data, e) => {
        if (data.name && data.email && data.password) {
            dispatch(startSignUp());
            dispatch(
                signUp({
                    name: data.name,
                    email: data.email,
                    password: data.password,
                })
            );
        }
    };

    useEffect(() => {
        if (userError) {
            notify();
        } else if (userData && userData.id) {
            navigate("/companydetails", { state: { data: "companydetails" } });
        }
    }, [userData, userError, navigate]);

    const acceptTerms = (data) => {
        setIsAccepted(!isAccepted);
    };

    return (
        <>
            <Toaster position="bottom-center" reverseOrder={false} />

            <form className="mx-w400 mx-auto" onSubmit={handleSubmit(onSubmit)}>
                <h1 className="h2 mb-2">Sign Up</h1>
                <p className="text-muted mb-5">
                    Welcome, please enter your details
                </p>
                <InputField
                    label="Name"
                    placeholder="Enter your name"
                    type="text"
                    name="name"
                    register={register("name", { required: true })}
                />
                {errors.name && errors.name.type === "required" && (
                    <p className="form--validation error-message">
                        Name is required.
                    </p>
                )}
                <InputField
                    label="Email"
                    placeholder="Enter your email"
                    type="text"
                    name="email"
                    register={register("email", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    })}
                />
                {errors.email && errors.email.type === "required" && (
                    <p className="form--validation error-message">
                        Email is required.
                    </p>
                )}
                {errors.email && errors.email.type === "pattern" && (
                    <p className="form--validation error-message">
                        Email is not valid.
                    </p>
                )}
                <InputField
                    label="Password"
                    placeholder="Enter your Password"
                    type="password"
                    instruction="Must be at least 8 characters"
                    name="password"
                    register={register("password", {
                        required: true,
                        minLength: 6,
                    })}
                />
                {errors.password && errors.password.type === "required" && (
                    <p className="form--validation error-message">
                        Password is required.
                    </p>
                )}
                {errors.password && errors.password.type === "minLength" && (
                    <p className="form--validation error-message">
                        Password should be at-least 6 characters.
                    </p>
                )}

                <FormGroup check inline className="form-group">
                    <Input
                        type="checkbox"
                        id="agree-term"
                        name="terms"
                        onChange={acceptTerms}
                        register={register("terms")}
                    />
                    <Label
                        check
                        for="agree-term"
                        className="fw-400 h7 text-muted"
                    >
                        I agree to all
                        <a
                            href="https://www.heatmaphero.com/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary-3 mx-1"
                        >
                            terms
                        </a>
                        and
                        <a
                            href="https://www.heatmaphero.com/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary-3 mx-1"
                        >
                            privacy policy
                        </a>
                    </Label>
                </FormGroup>

                <div className="form-group">
                    <Button color="primary" block disabled={!isAccepted}>
                        Get Started
                    </Button>
                </div>

                <div className="or-else-line-wrapper form-group">
                    <span className="line--item"></span>
                    <span className="text--item text-muted fw-400 h7 mx-1">
                        Or else
                    </span>
                    <span className="line--item"></span>
                </div>
                {/* <div className="form-group">
                    <Button color="light" block>
                        <img src={googleLogo} alt="" /> Sign in with Google
                    </Button>
                </div> */}
                {/* <div className="form-group text-center">
                    <GoogleSignIn comp={"singup"} />
                </div> */}
                <div className="form-group text-center">
                    <p className="text-muted">
                        Already have an account?{" "}
                        <Link to="/login" className="text-primary-2 h7 fw-400">
                            {" "}
                            Login{" "}
                        </Link>
                    </p>
                </div>
            </form>
        </>
    );
}

export default Signup;
