import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getDashboardTopPageLinkCount,
    getDashboardTotalSession,
    getDashboardCurrentSession,
    getDashboardSessionDuration,
    getDashboardTopPageClickCount,
    getUserCountData,
    getDeviceCountData,
    getCountryData,
    getTotalSessionRangeWiseData,
    getCurrentSessionPercentage,
    getCurrentSessionChartData,
    getBounceRateChartDataAPI,
    getHeavyResourcesData,
    getLongestDurationResourcesData,
    getReferrerCountsDataAPI,
} from "../../services/api";

export const getTopLinkCount = createAsyncThunk(
    "dashboard/getTopLinkCount",
    async (userId, thunkAPI) => {
        try {
            const response = await getDashboardTopPageLinkCount(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getHeaviestResources = createAsyncThunk(
    "dashboard/getHeaviestResources",
    async (userId, thunkAPI) => {
        try {
            const response = await getHeavyResourcesData(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getLongestDurationResources = createAsyncThunk(
    "dashboard/getLongestDurationResources",
    async (userId, thunkAPI) => {
        try {
            const response = await getLongestDurationResourcesData(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getTopClickCount = createAsyncThunk(
    "dashboard/getTopClickCount",
    async (userId, thunkAPI) => {
        try {
            const response = await getDashboardTopPageClickCount(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getTotalSession = createAsyncThunk(
    "dashboard/getTotalSession",
    async (userId, thunkAPI) => {
        try {
            const response = await getDashboardTotalSession(userId);
            let data = response.data.sort(
                (a, b) => new Date(a.date) - new Date(b.date)
            );
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getCurrentSession = createAsyncThunk(
    "dashboard/getCurrentSession",
    async (userId, thunkAPI) => {
        try {
            const response = await getDashboardCurrentSession(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getSessionDuration = createAsyncThunk(
    "dashboard/getSessionDuration",
    async (userId, thunkAPI) => {
        try {
            const response = await getDashboardSessionDuration(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getUserCount = createAsyncThunk(
    "dashboard/getUserCount",
    async (userId, thunkAPI) => {
        try {
            const response = await getUserCountData(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getDeviceCount = createAsyncThunk(
    "dashboard/getDeviceCount",
    async (userId, thunkAPI) => {
        try {
            const response = await getDeviceCountData(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getCountryWiseData = createAsyncThunk(
    "dashboard/getCountryWiseData",
    async (userId, thunkAPI) => {
        try {
            const response = await getCountryData(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getTotalSessionDateFilter = createAsyncThunk(
    "dashboard/getTotalSessionDateFilter",
    async (args, thunkAPI) => {
        try {
            const response = await getTotalSessionRangeWiseData(
                args.userId,
                args.range
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getCurrentSessionPercentageSlice = createAsyncThunk(
    "dashboard/getCurrentSessionPercentageSlice",
    async (args, thunkAPI) => {
        try {
            const response = await getCurrentSessionPercentage(args);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getCurrentSessionChartDataSlice = createAsyncThunk(
    "dashboard/getCurrentSessionChartDataSlice",
    async (args, thunkAPI) => {
        try {
            const response = await getCurrentSessionChartData(args);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getBounceRateChartData = createAsyncThunk(
    "dashboard/getBounceRateChartData",
    async (args, thunkAPI) => {
        try {
            const response = await getBounceRateChartDataAPI(args);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getReferrerCountsData = createAsyncThunk(
    "dashboard/getReferrerCountsData",
    async (userId, thunkAPI) => {
        try {
            const response = await getReferrerCountsDataAPI(userId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        topLinkCount: null,
        topClickCount: null,
        status: "idle",
        error: null,
        totalSession: null,
        currentSession: null,
        sessionDuration: null,
        userCountData: null,
        deviceCountData: null,
        countryWiseData: null,
        totalSessionRangeData: null,
        currentSessionPercentage: null,
        currentSessionChartDataList: null,
        bounceRateChartData: null,
        heavyResources: null,
        longestDurationResources: null,
        referrerCounts: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTopLinkCount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTopLinkCount.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.topLinkCount = action.payload;
            })
            .addCase(getTopLinkCount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getHeaviestResources.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getHeaviestResources.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.heavyResources = action.payload;
            })
            .addCase(getHeaviestResources.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getLongestDurationResources.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getLongestDurationResources.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.longestDurationResources = action.payload;
            })
            .addCase(getLongestDurationResources.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getTopClickCount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTopClickCount.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.topClickCount = action.payload;
            })
            .addCase(getTopClickCount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getTotalSession.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTotalSession.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.totalSession = action.payload;
            })
            .addCase(getTotalSession.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getCurrentSession.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getCurrentSession.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.currentSession = action.payload;
            })
            .addCase(getCurrentSession.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getSessionDuration.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSessionDuration.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.sessionDuration = action.payload;
            })
            .addCase(getSessionDuration.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getUserCount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getUserCount.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.userCountData = action.payload;
            })
            .addCase(getUserCount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getDeviceCount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getDeviceCount.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.deviceCountData = action.payload;
            })
            .addCase(getDeviceCount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getCountryWiseData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getCountryWiseData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.countryWiseData = action.payload;
            })
            .addCase(getCountryWiseData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getTotalSessionDateFilter.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTotalSessionDateFilter.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.totalSessionRangeData = action.payload;
            })
            .addCase(getTotalSessionDateFilter.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getCurrentSessionPercentageSlice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                getCurrentSessionPercentageSlice.fulfilled,
                (state, action) => {
                    state.status = "succeeded";
                    state.currentSessionPercentage = action.payload;
                }
            )
            .addCase(
                getCurrentSessionPercentageSlice.rejected,
                (state, action) => {
                    state.status = "failed";
                    state.error = action.payload.error;
                }
            )
            .addCase(getCurrentSessionChartDataSlice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                getCurrentSessionChartDataSlice.fulfilled,
                (state, action) => {
                    state.status = "succeeded";
                    state.currentSessionChartDataList = action.payload;
                }
            )
            .addCase(
                getCurrentSessionChartDataSlice.rejected,
                (state, action) => {
                    state.status = "failed";
                    state.error = action.payload.error;
                }
            )
            .addCase(getBounceRateChartData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getBounceRateChartData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.bounceRateChartData = action.payload;
            })
            .addCase(getBounceRateChartData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            })
            .addCase(getReferrerCountsData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getReferrerCountsData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.referrerCounts = action.payload;
            })
            .addCase(getReferrerCountsData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
            });
    },
});

export const dashboardReducer = dashboardSlice.reducer;
