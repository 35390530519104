import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchFullScreenShort } from "../../../redux/company/companySlice";
import { getHeatMapClickData } from "../../../redux/heatmap/heatmapSlice";
import h337 from "heatmap.js";
import Lottie from "react-lottie";
import * as animationData from "../../../Assets/Gif/heatmapherogif.json";

function ClickHeatmap({ dynamicWidth }) {
    const dispatch = useDispatch();
    const containerRef = useRef(null);
    const { pagename, pageurl } = useParams();
    const heatmapRef = useRef(null);
    const heatmapInstance = useRef();
    const heatmapContainer = useRef();
    const imageRef = useRef(null);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const { clickedHeatmapData } = useSelector((state) => state.heatmap);
    const url = decodeURIComponent(pageurl);
    const ENDPOINT = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(true);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const [originalSize, setOriginalSize] = useState({
        width: 1920,
        height: 1080,
    });

    useEffect(() => {
        dispatch(getHeatMapClickData(url));
        dispatch(fetchFullScreenShort(pagename));
    }, [dispatch]);

    useEffect(() => {
        if (
            containerRef.current &&
            (imageSize.width === 0 ||
                imageSize.height === 0 ||
                !clickedHeatmapData)
        ) {
            return; // Exit if image size isn't known yet or if there's no data
        }

        if (!heatmapInstance.current) {
            heatmapInstance.current = h337.create({
                container: heatmapRef.current,
                radius: 50,
            });
        }

        let points = [];
        const originalWidth = originalSize.width;
        const originalHeight = originalSize.height;
        const scaleX = imageSize.width / originalWidth;
        const scaleY = imageSize.height / originalHeight;

        clickedHeatmapData.forEach((dataPoints) => {
            let point = {
                x: Math.floor(dataPoints.x * scaleX), // Use x coordinate
                y: Math.floor(dataPoints.y * scaleY), // Use y coordinate (not dataPoints.x)
                value: 1,
            };
            points.push(point);
        });

        heatmapInstance.current.setData({
            max: 10,
            data: points,
        });
    }, [clickedHeatmapData, imageSize]);

    const handleImageLoad = () => {
        const { naturalWidth, naturalHeight } = imageRef.current;

        setOriginalSize({
            width: naturalWidth,
            height: naturalHeight,
        });

        heatmapRef.current.style.width = `${naturalWidth}px`;
        heatmapRef.current.style.height = `${naturalHeight}px`;

        setImageSize({
            width: naturalWidth,
            height: naturalHeight,
            scaleX: heatmapRef.current.offsetWidth / naturalWidth,
            scaleY: heatmapRef.current.offsetHeight / naturalHeight,
        });
        const timer = setTimeout(() => {
            applyStyles();
        }, 5000);
    };

    const applyStyles = () => {
        if (heatmapContainer.current) {
            heatmapContainer.current.style.width = dynamicWidth;
            heatmapContainer.current.style.opacity = "1";

            const canvas =
                heatmapContainer.current.querySelector(".heatmap-canvas");
            if (canvas) {
                canvas.style.width = dynamicWidth;
            }
            setLoading(false);
        }
    };

    const mouseData = useSelector((state) => state.company.mouseData);
    const pagesData = mouseData ? mouseData.details : [];

    return (
        <div className={"heatmap-container"} ref={containerRef}>
            <div className={`background ${loading ? "loading" : "loaded"}`}>
                <div className="bounding-box" ref={heatmapContainer}>
                    <img
                        ref={imageRef}
                        src={pagesData.screenshot_url}
                        alt=""
                        onLoad={handleImageLoad}
                    />
                    <div ref={heatmapRef} className="heatmap"></div>
                </div>
            </div>
            {loading && (
                <div className="heatmaphero-logo-loader">
                    <Lottie options={defaultOptions} height={100} width={100} />
                </div>
            )}{" "}
        </div>
    );
}

export default ClickHeatmap;
