// import { Button, Form } from "reactstrap";
// import InputField from "./InputField";

// export default function SignUpStep1() {
// 	return (
// 		<Form className="mx-w400 mx-auto">
// 			<h1 className="h2 mb-2">Company details</h1>
// 			<p className="text-muted mb-5">Welcome, please enter your details</p>
// 			<InputField
// 				label="Company name"
// 				placeholder="Enter company name"
// 				type="text"
// 			/>
// 			<InputField label="Company website" placeholder="http://" type="text" />
// 			<div className="form-group d-flex">
// 				<Button color="light" block disabled className="me-2">
// 					Back
// 				</Button>
// 				<Button color="primary" block disabled className="ms-2">
// 					Next
// 				</Button>
// 			</div>
// 			<div className="or-else-line-wrapper form-group">
// 				<span className="line--item"></span>
// 				<span className="text--item text-muted fw-400 h7 mx-1">Or else</span>
// 				<span className="line--item"></span>
// 			</div>
// 			<div className="form-group text-center">
// 				<p className="text-muted">
// 					Don’t have an account?{" "}
// 					<a href="#!" className="text-primary h7 fw-400">
// 						{" "}
// 						Login
// 					</a>
// 				</p>
// 			</div>
// 		</Form>
// 	);
// }

import React, { useState, useEffect } from "react";
import { Button, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { postCompanyDetails } from "../../redux/company/companySlice";
import InputField from "./FormFields";
import { useNavigate } from "react-router-dom";
import { JSIcon, NextJSIcon } from "../../Assets/icons";

export default function SignUpStep1() {
    const { register, handleSubmit, formState } = useForm({
        mode: "onBlur",
    });
    const { errors } = formState;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = useSelector((state) => state.user.userData);
    const [selectedIcon, setSelectedIcon] = useState("js");

    const companyDetailsStatus = useSelector((state) => state.company.status);

    const onSubmit = (data) => {
        dispatch(
            postCompanyDetails({
                user_id: userId.id,
                company_name: data.companyName,
                website_url: data.url,
                tech: selectedIcon,
            })
        );
    };

    useEffect(() => {
        if (companyDetailsStatus === "fulfilled") {
            navigate("/codeintegration");
        }
    }, [companyDetailsStatus, navigate]);

    return (
        <Form className="mx-w400 mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="h2 mb-2">Company details</h1>
            <p className="text-muted mb-5">
                Welcome, please enter your details
            </p>

            <InputField
                label="Company name"
                placeholder="Enter company name"
                type="text"
                name="companyName"
                register={register("companyName", {
                    required: "Company name is required.",
                    minLength: {
                        value: 2,
                        message:
                            "Company name should be at least 2 characters.",
                    },
                })}
            />
            {errors.companyName && (
                <p className="error-message">{errors.companyName.message}</p>
            )}

            <InputField
                label="Company website"
                placeholder="http://"
                type="text"
                name="url"
                register={register("url", {
                    required: "URL is required.",
                    pattern: {
                        value: /^(http|https):\/\/[^ "]+$/,
                        message: "Please enter a valid URL.",
                    },
                })}
            />
            {errors.url && (
                <p className="error-message">{errors.url.message}</p>
            )}

            <div className="scrtip-install-icon">
                <div
                    onClick={() => setSelectedIcon("js")}
                    className={selectedIcon === "js" ? "selected-icons" : ""}
                >
                    <JSIcon />
                </div>
                <div
                    onClick={() => setSelectedIcon("next")}
                    className={selectedIcon === "next" ? "selected-icons" : ""}
                >
                    <NextJSIcon />
                </div>
            </div>

            <div className="form-group d-flex">
                <Button type="submit" color="primary" block className="ms-2">
                    Next
                </Button>
            </div>
            <div className="or-else-line-wrapper form-group">
                <span className="line--item"></span>
                <span className="text--item text-muted fw-400 h7 mx-1">
                    Or else
                </span>
                <span className="line--item"></span>
            </div>
            <div className="form-group text-center">
                <p className="text-muted">
                    Don’t have an account?{" "}
                    <a href="#!" className="text-primary h7 fw-400">
                        {" "}
                        Login
                    </a>
                </p>
            </div>
        </Form>
    );
}
