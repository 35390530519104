import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import { getTotalSession } from "../../../redux/dashboard/dashboardSlice";
import AreaChartComponent from "../../Charts/AreaChart";
import PopupMenu from "../../Popups/ChartPopup";

function TotalSession({ userId, md, startDate, endDate }) {
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    let widthMD = md ? md : 4;

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const getTotalSessionData = useCallback(() => {
        dispatch(getTotalSession(userId, startDate, endDate));
    }, [dispatch, userId, startDate, endDate]);

    const { totalSession, status, error } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        getTotalSessionData();
    }, [getTotalSessionData]);

    const totalNumber = totalSession?.reduce(
        (accumulator, item) => accumulator + parseInt(item.count),
        0
    );

    return (
        <Col md={widthMD}>
            {/* <PopupMenu /> */}

            <div className="card" onClick={handleShowPopup}>
                <div className="card-body">
                    <div className="dash-card-header">
                        <h4 className="dash-card-title mb-0 h6">
                            Total Session
                        </h4>
                        <span className="text-info h6 mb-0 icon-item d-flex align-items-center">
                            2.15%
                            <svg
                                className="ms-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <rect
                                    width="20"
                                    height="20"
                                    fill="#E1F1F5"
                                    rx="10"
                                ></rect>
                                <path
                                    stroke="#378A9C"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M6.667 13.334l6.667-6.667m0 0H6.667m6.667 0v6.667"
                                ></path>
                            </svg>
                        </span>
                    </div>
                    <div className="dash-card-body">
                        <h3 className="h2">{totalNumber}</h3>
                        <div className="chart-wrapper">
                            <AreaChartComponent
                                id={"blue"}
                                firstColor={"#4E2892"}
                                strokeColor={"#7b3fe4"}
                                data={totalSession}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
                <div className="overlay">
                    <PopupMenu
                        onClose={handleClosePopup}
                        firstColor={"#4E2892"}
                        strokeColor={"#7b3fe4"}
                        id={"blue"}
                        rawData={totalSession}
                        userId={userId}
                        parentComponent={"totalSession"}
                    />
                </div>
            )}
        </Col>
    );
}

export default TotalSession;
