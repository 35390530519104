import React, { useState } from "react";
import HeatmapHeroLogo from "./../../Assets/Images/logo.svg";
import UserAvatar from "./../../Assets/Images/user-circle.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    DashboardIcon,
    HeatMapIcon,
    SessionReplayIcon,
    AlertIcon,
    ThreeDotsIcon,
} from "../../Assets/icons";
import UserDialogModel from "./UserDialogModel";

function Sidebar(props) {
    const navigate = useNavigate();
    if (!props?.userData) {
        navigate("/companydetails", { state: { data: "companydetails" } });
    }

    const userName = props?.userData?.user?.username;
    const companyName = props?.userData?.cd[0]?.website_url;

    // const currentPathname = window.location.pathname;
    const { pathname } = useLocation();

    // return (
    //     <Popup trigger={<button> Trigger</button>} position="right center">
    //         <div>Popup content here !!</div>
    //     </Popup>
    // );

    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <div className="sidebar--wrapper">
            <div className="logo--wrapper">
                <Link to="/" className="menu--link">
                    <img src={HeatmapHeroLogo} alt="" />
                </Link>
            </div>
            <div className="menu--list-wrapper pb-0">
                <ul className="menu">
                    <li className="menu--item">
                        <Link
                            to="/"
                            className={`menu--link ${
                                pathname === "/" ? "active" : ""
                            }`}
                        >
                            <span className="menu--icon">
                                <DashboardIcon />
                            </span>
                            <span className="menu--name">Dashboard</span>
                        </Link>
                    </li>
                    <li className="menu--item">
                        <Link
                            to="/heatmap"
                            className={`menu--link ${
                                pathname === "/heatmap" ? "active" : ""
                            }`}
                        >
                            <span className="menu--icon">
                                <HeatMapIcon />
                            </span>
                            <span className="menu--name">Heatmaps</span>
                        </Link>
                    </li>
                    <li className="menu--item">
                        <Link
                            to="/sessionreplay"
                            className={`menu--link ${
                                pathname === "/sessionreplay" ? "active" : ""
                            }`}
                        >
                            <span className="menu--icon">
                                <SessionReplayIcon />
                            </span>
                            <span className="menu--name">Session replay</span>
                        </Link>
                    </li>
                    <li className="menu--item">
                        <Link
                            to="/alerts"
                            className={`menu--link ${
                                pathname === "/alerts" ? "active" : ""
                            }`}
                        >
                            <span className="menu--icon">
                                <AlertIcon />
                            </span>
                            <span className="menu--name">Alerts</span>
                        </Link>
                    </li>
                </ul>
                {/* <ul className="menu mt-auto">
                    <li className="menu--item">
                        <Link
                            to="#!"
                            className={`menu--link ${
                                pathname === "/heatmaps" ? "active" : ""
                            }`}
                        >
                            <span className="menu--icon">
                                <svg
                                    width="15"
                                    height="17"
                                    viewBox="0 0 15 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.50026 10.6253C8.67387 10.6253 9.62526 9.67393 9.62526 8.50033C9.62526 7.32672 8.67387 6.37533 7.50026 6.37533C6.32666 6.37533 5.37526 7.32672 5.37526 8.50033C5.37526 9.67393 6.32666 10.6253 7.50026 10.6253Z"
                                        stroke="#B0B0BB"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M5.58003 13.7215L5.99401 14.6526C6.11708 14.9297 6.31792 15.1652 6.57217 15.3305C6.82643 15.4958 7.12318 15.5837 7.42642 15.5837C7.72967 15.5837 8.02641 15.4958 8.28067 15.3305C8.53492 15.1652 8.73576 14.9297 8.85883 14.6526L9.27281 13.7215C9.42018 13.3912 9.66806 13.1157 9.98114 12.9345C10.2962 12.7528 10.6607 12.6753 11.0224 12.7133L12.0353 12.8212C12.3368 12.8531 12.6411 12.7968 12.9113 12.6592C13.1815 12.5216 13.4059 12.3086 13.5574 12.0459C13.7092 11.7835 13.7814 11.4826 13.7655 11.1799C13.7496 10.8771 13.6461 10.5855 13.4677 10.3404L12.868 9.51639C12.6544 9.22079 12.5403 8.86499 12.5422 8.50033C12.5421 8.13666 12.6573 7.78232 12.8711 7.4882L13.4709 6.66417C13.6493 6.41907 13.7527 6.12746 13.7686 5.82472C13.7846 5.52198 13.7123 5.22113 13.5606 4.95866C13.4091 4.69603 13.1846 4.48301 12.9144 4.3454C12.6443 4.2078 12.34 4.15153 12.0385 4.18343L11.0255 4.29125C10.6638 4.32924 10.2993 4.25183 9.98429 4.07009C9.67059 3.88784 9.42264 3.61095 9.27596 3.27912L8.85883 2.34806C8.73576 2.07091 8.53492 1.83542 8.28067 1.67015C8.02641 1.50488 7.72967 1.41694 7.42642 1.41699C7.12318 1.41694 6.82643 1.50488 6.57217 1.67015C6.31792 1.83542 6.11708 2.07091 5.99401 2.34806L5.58003 3.27912C5.43336 3.61095 5.1854 3.88784 4.8717 4.07009C4.55664 4.25183 4.19217 4.32924 3.83045 4.29125L2.81438 4.18343C2.51287 4.15153 2.20858 4.2078 1.9384 4.3454C1.66822 4.48301 1.44377 4.69603 1.29226 4.95866C1.14054 5.22113 1.06827 5.52198 1.08419 5.82472C1.10012 6.12746 1.20357 6.41907 1.38198 6.66417L1.9817 7.4882C2.19557 7.78232 2.31075 8.13666 2.31068 8.50033C2.31075 8.86399 2.19557 9.21833 1.9817 9.51246L1.38198 10.3365C1.20357 10.5816 1.10012 10.8732 1.08419 11.1759C1.06827 11.4787 1.14054 11.7795 1.29226 12.042C1.44392 12.3045 1.6684 12.5174 1.93854 12.655C2.20867 12.7926 2.51289 12.8489 2.81438 12.8172L3.8273 12.7094C4.18903 12.6714 4.5535 12.7488 4.86855 12.9306C5.18343 13.1123 5.43253 13.3892 5.58003 13.7215Z"
                                        stroke="#B0B0BB"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                            <span className="menu--name">Settings</span>
                        </Link>
                    </li>
                    <li className="menu--item">
                        <Link
                            to="#!"
                            className={`menu--link ${
                                pathname === "/heatmapd" ? "active" : ""
                            }`}
                        >
                            <span className="menu--icon">
                                <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.95833 5.02083H7.5M3.95833 7.5H9.625M5.85932 11.75H10.475C11.6651 11.75 12.2602 11.75 12.7147 11.5184C13.1146 11.3147 13.4397 10.9896 13.6434 10.5897C13.875 10.1352 13.875 9.54011 13.875 8.35V4.525C13.875 3.33489 13.875 2.73983 13.6434 2.28527C13.4397 1.88543 13.1146 1.56034 12.7147 1.35661C12.2602 1.125 11.6651 1.125 10.475 1.125H4.525C3.33489 1.125 2.73983 1.125 2.28527 1.35661C1.88543 1.56034 1.56034 1.88543 1.35661 2.28527C1.125 2.73983 1.125 3.33489 1.125 4.525V13.4043C1.125 13.7817 1.125 13.9705 1.20237 14.0674C1.26966 14.1517 1.37169 14.2007 1.47955 14.2006C1.60357 14.2005 1.75093 14.0826 2.04566 13.8468L3.73536 12.495C4.08053 12.2189 4.25312 12.0808 4.4453 11.9827C4.61581 11.8955 4.7973 11.8319 4.98486 11.7934C5.19627 11.75 5.41728 11.75 5.85932 11.75Z"
                                        stroke="#B0B0BB"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                            <span className="menu--name">Support</span>
                        </Link>
                    </li>
                    <li className="menu--item">
                        <Link
                            to="#!"
                            className={`menu--link ${
                                pathname === "/heatmapf" ? "active" : ""
                            }`}
                        >
                            <span className="menu--icon">
                                <svg
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.76603 13.7692C4.19693 12.754 5.20298 12.042 6.37533 12.042H10.6253C11.7977 12.042 12.8037 12.754 13.2346 13.7692M11.3337 6.72949C11.3337 8.2943 10.0651 9.56283 8.50033 9.56283C6.93552 9.56283 5.66699 8.2943 5.66699 6.72949C5.66699 5.16469 6.93552 3.89616 8.50033 3.89616C10.0651 3.89616 11.3337 5.16469 11.3337 6.72949ZM15.5837 8.50033C15.5837 12.4123 12.4123 15.5837 8.50033 15.5837C4.58831 15.5837 1.41699 12.4123 1.41699 8.50033C1.41699 4.58831 4.58831 1.41699 8.50033 1.41699C12.4123 1.41699 15.5837 4.58831 15.5837 8.50033Z"
                                        stroke="#B0B0BB"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                            <span className="menu--name">Profile</span>
                        </Link>
                    </li>
                </ul> */}
            </div>
            <div className="user--profile--wrapper">
                <a
                    href="#!"
                    className="user--profile"
                    onClick={() => setModalOpen(true)}
                >
                    <img src={UserAvatar} alt="" className="user--image" />
                    <span className="user--content">
                        <span className="user--name">{userName}</span>
                        <span className="user--mail">{companyName}</span>
                    </span>
                    <ThreeDotsIcon />
                </a>
            </div>
            <UserDialogModel isOpen={isModalOpen} setIsOpen={setModalOpen} />
        </div>
    );
}

export default Sidebar;
