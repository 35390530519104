import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
    DashboardIcon,
    HeatMapIcon,
    SessionReplayIcon,
    AlertIcon,
    ThreeDotsIcon,
} from "../../Assets/icons";
import {
    SettingIcon,
    ProfileUserIcon,
    BellIcon,
    LogoutIcon,
} from "../../Assets/icons";

function ProfileSidebar({ setActiveSection, activeSection }) {
    const { pathname } = useLocation();
    return (
        <div className="menu--list-wrapper pb-0">
            <ul className="menu">
                <li
                    className="menu--item"
                    onClick={() => setActiveSection("profile")}
                >
                    <div
                        className={`menu--link profile-sidebar-courser ${
                            activeSection == "profile" ? "active" : ""
                        }`}
                    >
                        <span className="menu--icon">
                            <ProfileUserIcon />
                        </span>
                        <span className="menu--name profile-menu-text">
                            Profile
                        </span>
                    </div>
                </li>
                <li
                    className="menu--item"
                    onClick={() => setActiveSection("install")}
                >
                    <div
                        className={`menu--link profile-sidebar-courser ${
                            activeSection == "install" ? "active" : ""
                        }`}
                    >
                        <span className="menu--icon">
                            <SettingIcon />
                        </span>
                        <span className="menu--name profile-menu-text">
                            Install Script
                        </span>
                    </div>
                </li>
                <li
                    className="menu--item"
                    onClick={() => setActiveSection("organization")}
                >
                    <div
                        className={`menu--link profile-sidebar-courser ${
                            activeSection == "organization" ? "active" : ""
                        }`}
                    >
                        <span className="menu--icon">
                            <SettingIcon />
                        </span>
                        <span className="menu--name profile-menu-text">
                            Organization settings
                        </span>
                    </div>
                </li>
                <li
                    className="menu--item"
                    onClick={() => setActiveSection("notification")}
                >
                    <div
                        className={`menu--link profile-sidebar-courser ${
                            activeSection == "notification" ? "active" : ""
                        }`}
                    >
                        <span className="menu--icon">
                            <BellIcon />
                        </span>
                        <span className="menu--name profile-menu-text">
                            Notification settings
                        </span>
                    </div>
                </li>
                <li
                    className="menu--item"
                    onClick={() => setActiveSection("logout")}
                >
                    <div
                        className={`menu--link profile-sidebar-courser ${
                            activeSection == "logout" ? "active" : ""
                        }`}
                    >
                        <span className="menu--icon">
                            <LogoutIcon />
                        </span>
                        <span className="menu--name profile-menu-text logout-link">
                            Logout
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default ProfileSidebar;
