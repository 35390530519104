// import axios from 'axios';
// import Cookies from "js-cookie";

// export const signUpAPI = async (name, email, password) => {
//   const response = await axios.post('http://localhost:4001/user/signup', {
//     username: name,
//     password: password,
//     email: email,
//   });
//   return response;
// };

// export const submitCompanyDetails = async (companyData) => {
//   const response = await axios.post('http://localhost:4001/companydetails', companyData);
//   return response;
// }

// export const verifyInstallationAPI = async (uniqueid) => {
//   const response = await axios.get(`${baseUrl}/verifyinstallation/${uniqueid}`);
//   return response;
// };

// export const login = ({username, password}) => async (dispatch) => {
//   try {
//       const res = await axios.post("http://localhost:4001/user/login", {username, password});
//       const data = res.data;
//       Cookies.set("jwt", data.token);  // Save token to cookies
//       dispatch(loginSuccess(data.user));  // Assuming data.user contains user information
//   } catch (error) {
//       console.error("Error during login: ", error);
//       dispatch(loginFailure());  // Assuming you have loginFailure action defined
//   }
// };

// export const loginAPI = async (username, password) => {
//   const response = await axios.post("http://localhost:4001/user/login", {username, password});
//   return response;
// };

import axios from "axios";
import Cookies from "js-cookie";
import { loginSuccess, loginFailure } from "../redux/user/userSlice";
const baseUrl = process.env.REACT_APP_API_URL;

export const signUpAPI = async (name, email, password) => {
    const response = await axios.post(`${baseUrl}/user/signup`, {
        username: name,
        password: password,
        email: email,
    });
    return response;
};

export const googleSignInAPI = async (idToken) => {
    return axios.post("https://riddleretro.com/user/auth/google/callback", {
        id_token: idToken,
    });
};

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
};

const token = getCookie("jwt");

const config = {
    headers: { Authorization: `Bearer ${token}` },
};

export const submitCompanyDetails = async (companyData) => {
    const response = await axios.post(`${baseUrl}/companydetails`, companyData);
    return response;
};

export const getAlertsList = async () => {
    const response = await axios.get(`${baseUrl}/alerts`, config);
    return response;
};

export const deleteAlertAPI = async (alertId) => {
    const response = await axios.get(
        `${baseUrl}/alerts/deletealert?alertId=${alertId}`,
        config
    );
    return response;
};

export const submitAlertData = async (alertData) => {
    const response = await axios.post(
        `${baseUrl}/alerts/addalert`,
        alertData,
        config
    );
    return response;
};

export const verifyInstallationAPI = async (uniqueid) => {
    const response = await axios.get(
        `${baseUrl}/verifyinstallation/${uniqueid}`
    );
    return response;
};

export const getMouseData = async (url) => {
    const response = await axios.get(
        `${baseUrl}/mouse_data/domain?url=${url}`,
        config
    );
    return response;
};

export const getFullScreenShort = async (ID) => {
    const response = await axios.get(
        `${baseUrl}/mouse_data/heatmap_page?page_id=${ID}`,
        config
    );
    return response;
};

export const getDashboardTopPageLinkCount = async (userId) => {
    const response = await axios.post(
        `${baseUrl}/webanalyticsroutes/dashboardlistcount`,
        { userId: userId },
        config
    );
    return response;
};

export const getHeavyResourcesData = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/getHeavyResourcesData?userId=${userId}`,
        config
    );
    return response;
};

export const getLongestDurationResourcesData = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/getLongestDurationResources?userId=${userId}`,
        config
    );
    return response;
};

export const getReferrerCountsDataAPI = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/getReferrerCountsData?userId=${userId}`,
        config
    );
    return response;
};

export const getDashboardTopPageClickCount = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/userclickdata?userId=${userId}`,
        config
    );
    return response.data;
};

export const getDashboardTotalSession = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/totalsession?userId=${userId}`,
        config
    );
    return response.data;
};

export const getDashboardCurrentSession = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/getCurrentSession?userId=${userId}`,
        config
    );
    return response.data;
};

export const getDashboardSessionDuration = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/sessionduration?userId=${userId}`,
        config
    );
    return response.data;
};

export const getUserCountData = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/gettotalusers?userId=${userId}`,
        config
    );
    return response.data;
};

export const getDeviceCountData = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/getdevicecount?userId=${userId}`,
        config
    );
    return response.data;
};

export const getCountryData = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/getCountryData?userId=${userId}`,
        config
    );
    return response.data;
};

export const getHeatMapData = async (url) => {
    const response = await axios.get(
        `${baseUrl}/mouse_data/getmousemoveheatmapdata?webpage_url=${url}`,
        config
    );
    return response.data;
};

export const getClickedHeatMapData = async (url) => {
    const response = await axios.get(
        `${baseUrl}/mouse_data/getmouseclickheatmapdata?webpage_url=${url}`,
        config
    );
    return response.data;
};

export const getSessionRecordListData = async (
    userId,
    page = 1,
    limit = 10
) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/getSessionRecordList?userId=${userId}&page=${page}&limit=${limit}`,
        config
    );
    return response.data;
};

export const getSessionReplayRawData = async (sessionId) => {
    const response = await axios.get(
        `${baseUrl}/mouse_data/getsessionreplaydata?session_id=${sessionId}`,
        config
    );
    return response.data;
};

export const getSessionReplayErrorListData = async (sessionId) => {
    const response = await axios.get(
        `${baseUrl}/mouse_data/getsessionerro?session_id=${sessionId}`,
        config
    );
    return response.data;
};

export const getResourceAndTimeData = async (sessionId) => {
    const response = await axios.get(
        `${baseUrl}/mouse_data/getresourceandtime?session_id=${sessionId}`,
        config
    );
    return response.data;
};

export const getTotalSessionRangeWiseData = async (userId, range) => {
    const response = await axios.post(
        `${baseUrl}/webanalyticsroutes/getDateWiseTotalSessionData`,
        { userId, range },
        config
    );
    return response.data;
};

export const getCurrentSessionPercentage = async (userId, range) => {
    const response = await axios.post(
        `${baseUrl}/webanalyticsroutes/getCurrentSessionPercentageData`,
        { userId },
        config
    );
    return response.data;
};

export const getCurrentSessionChartData = async (userId, range) => {
    const response = await axios.post(
        `${baseUrl}/webanalyticsroutes/getCurrentSessionChartData`,
        { userId },
        config
    );
    return response.data;
};

export const getHeatMapPageData = async (webpage_url) => {
    const response = await axios.get(
        `${baseUrl}/mouse_data/getheatmapdetails?webpage_url=${webpage_url}`,
        config
    );
    return response.data;
};

export const getBounceRateChartDataAPI = async (userId) => {
    const response = await axios.get(
        `${baseUrl}/webanalyticsroutes/getDatewiseBounceRateData?userId=${userId}`,
        config
    );
    return response.data;
};

export const resetPasswordAPI = async (email) => {
    const response = await axios.get(
        `${baseUrl}/user/resetpassword?email=${email}`
    );
    return response.data;
};

// export const login = ({username, password}) => async (dispatch) => {
//   try {
//       const res = await axios.post("http://localhost:4001/user/login", {username, password});
//       const data = res.data;
//       console.log(res.data,"-----")
//       Cookies.set("jwt", data.token);  // Save token to cookies
//       dispatch(loginSuccess(data.user));  // Assuming data.user contains user information
//   } catch (error) {
//       console.error("Error during login: ", error);
//       dispatch(loginFailure());  // Assuming you have loginFailure action defined
//   }
// };

// export const login =
//     ({ username, password }) =>
//     async (dispatch) => {
//         try {
//             const res = await axios.post("http://localhost:4001/user/login", {
//                 username,
//                 password,
//             });
//             const data = res.data;
//             Cookies.set("jwt", data.token); // Save token to cookies
//             localStorage.setItem("userData", JSON.stringify(data.user));
//             dispatch(loginSuccess(data.user)); // Assuming data.user contains user information
//         } catch (error) {
//             console.error("Error during login: ", error);
//             dispatch(loginFailure()); // Assuming you have loginFailure action defined
//         }
//     };

export const login =
    ({ username, password }) =>
    async (dispatch) => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/login`,
                {
                    username,
                    password,
                }
            );
            const data = res.data;

            if (data && data.token) {
                Cookies.set("jwt", data.token, {
                    secure: true,
                    sameSite: "Strict",
                });
                localStorage.setItem("userData", JSON.stringify(data.user));
                dispatch(loginSuccess(data.user));
            } else {
                dispatch(loginFailure("Unexpected server response."));
            }
        } catch (error) {
            console.log(error);
            let errorMsg = "An error occurred during login.";
            if (
                error.response &&
                error.response.data &&
                error.response.data.error
            ) {
                errorMsg = error.response.data.error;
            }
            dispatch(loginFailure(errorMsg));
        }
    };
