import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JSIcon, NextJSIcon } from "../../../Assets/icons";
import { postCompanyDetails } from "../../../redux/company/companySlice";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import docco from "react-syntax-highlighter/dist/esm/styles/prism/one-light";
import { JSScript, NextJSScript } from "../Scripts/JSScript";

function InstallScript(props) {
    const dispatch = useDispatch();
    const userObject = useSelector((state) => state.user.userData);

    const [companyName, setCompanyName] = useState("");
    const [websiteURL, setWebsiteURL] = useState("");
    const [selectedIcon, setSelectedIcon] = useState("");
    const [websiteError, setWebsiteError] = useState(false);
    const companyDetailsStatus = useSelector((state) => state.company.status);
    const companyResponse = useSelector(
        (state) => state.company.companyResponse
    );
    const [isUpdated, setIsUpdated] = useState(
        userObject.cd.length ? true : false
    );
    const [script, setScript] = useState(
        userObject.cd.length
            ? JSScript({
                  uniqueId: userObject.user.uniqueid,
                  website_url: userObject.cd[0].website_url,
              })
            : ""
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            postCompanyDetails({
                user_id: userObject.user.id,
                company_name: companyName,
                website_url: websiteURL,
                tech: selectedIcon,
            })
        );
    };

    const validateWebsite = (url) => {
        const pattern = new RegExp(
            "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
            "i"
        );
        return !!pattern.test(url);
    };

    useEffect(() => {
        if (companyDetailsStatus === "fulfilled") {
            setIsUpdated(true);
            let scriptTech = selectedIcon === "js" ? JSScript : NextJSScript;

            const companyDetails = {
                id: companyResponse.details.id,
                user_id: companyResponse.details.user_id,
                website_url: companyResponse.details.website_url,
                created_at: companyResponse.details.created_at,
                company_name: companyResponse.details.company_name,
            };

            try {
                const localStorageData = localStorage.getItem("userData");
                if (!localStorageData) {
                    console.error("No data in localStorage");
                    return;
                }
                const parsedData = JSON.parse(localStorageData);

                if (!parsedData.cd) {
                    console.error("Invalid data structure");
                    return;
                }

                parsedData.cd.push(companyDetails);

                localStorage.setItem("userData", JSON.stringify(parsedData));
            } catch (e) {
                console.error("An error occurred:", e);
            }
            setScript(
                scriptTech({
                    uniqueId: companyResponse.uniqueId,
                    website_url: companyResponse.website_url,
                })
            );
        }
    }, [companyDetailsStatus]);

    return (
        <div className="profile-section">
            <div className="user-details-section">
                {isUpdated ? (
                    <pre className="border rounded-3 px-2 pb-2 mb-1">
                        <SyntaxHighlighter language="javascript" style={docco}>
                            {script}
                        </SyntaxHighlighter>
                    </pre>
                ) : (
                    // <form onSubmit={handleSubmit}>
                    //     <div className="form-group">
                    //         <input
                    //             type="text"
                    //             placeholder="Company Name"
                    //             className="form-control profile-text-box"
                    //             value={companyName}
                    //             onChange={(e) => setCompanyName(e.target.value)}
                    //         />
                    //     </div>
                    //     <div className="row form-group gx-2">
                    //         <div className="form-group">
                    //             <input
                    //                 type="text"
                    //                 placeholder="Website URL"
                    //                 className="form-control profile-text-box"
                    //                 value={websiteURL}
                    //                 onChange={(e) =>
                    //                     setWebsiteURL(e.target.value)
                    //                 }
                    //             />
                    //         </div>
                    //     </div>

                    //     <div className="scrtip-install-icon">
                    //         <div
                    //             onClick={() => setSelectedIcon("js")}
                    //             className={
                    //                 selectedIcon === "js"
                    //                     ? "selected-icons"
                    //                     : ""
                    //             }
                    //         >
                    //             <JSIcon />
                    //         </div>
                    //         <div
                    //             onClick={() => setSelectedIcon("next")}
                    //             className={
                    //                 selectedIcon === "next"
                    //                     ? "selected-icons"
                    //                     : ""
                    //             }
                    //         >
                    //             <NextJSIcon />
                    //         </div>
                    //     </div>

                    //     <div className="form-group">
                    //         <button
                    //             type="submit"
                    //             className="save-button btn btn-primary rounded-12 w-100"
                    //         >
                    //             Save
                    //         </button>
                    //     </div>
                    // </form>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Company Name"
                                className="form-control profile-text-box"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>
                        <div className="row form-group gx-2">
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Website URL"
                                    className={`form-control profile-text-box ${
                                        websiteError ? "error" : ""
                                    }`}
                                    value={websiteURL}
                                    onChange={(e) => {
                                        const isValid = validateWebsite(
                                            e.target.value
                                        );
                                        setWebsiteError(!isValid);
                                        setWebsiteURL(e.target.value);
                                    }}
                                />
                                {websiteError && (
                                    <div className="error-message">
                                        Invalid URL
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="scrtip-install-icon">
                            <div
                                onClick={() => setSelectedIcon("js")}
                                className={
                                    selectedIcon === "js"
                                        ? "selected-icons"
                                        : ""
                                }
                            >
                                <JSIcon />
                            </div>
                            <div
                                onClick={() => setSelectedIcon("next")}
                                className={
                                    selectedIcon === "next"
                                        ? "selected-icons"
                                        : ""
                                }
                            >
                                <NextJSIcon />
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                type="submit"
                                disabled={
                                    !companyName ||
                                    !websiteURL ||
                                    websiteError ||
                                    !selectedIcon
                                }
                                className="save-button btn btn-primary rounded-12 w-100"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}

export default InstallScript;
