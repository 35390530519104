import logoWhite from "./../../Assets/Images/logo-white.svg";
import Signup from "../Public/Signup";
import lightImage from "./../../Assets/Images/Light accent.svg";
import starImage from "./../../Assets/Images/stars-02.svg";

function SignupFlow({ routeName }) {
    return <Signup />;
}

export default SignupFlow;
