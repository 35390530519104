import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { logout } from "./authSlice";

export const performLogout = (dispatch, navigate) => {
    const handleLogout = async () => {
        try {
            Cookies.remove("jwt", { secure: true, sameSite: "Strict" });
            localStorage.removeItem("userData");
            dispatch(logout());
            setTimeout(() => {
                window.location.href = "/login";
            }, 100);
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };
    return handleLogout;
};
