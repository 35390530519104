// import React, { useEffect, useState, useRef } from "react";
// import { useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchFullScreenShort } from "../../../redux/company/companySlice";
// import { getHeatMapMouseData } from "../../../redux/heatmap/heatmapSlice";
// import socketIOClient from "socket.io-client";
// import h337 from "heatmap.js";

// function MouseMoveHeatmap(props) {
//     const dispatch = useDispatch();
//     const { pagename, pageurl } = useParams();
//     const socket = useRef();
//     const heatmapInstance = useRef();
//     const heatmapContainer = useRef();
//     const imageRef = useRef(null);
//     const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

//     const url = decodeURIComponent(pageurl); // change this to the URL of your page
//     const ENDPOINT = process.env.REACT_APP_API_URL;;

//     const [isImageLoaded, setImageLoaded] = useState(false);

//     useEffect(() => {
//         dispatch(getHeatMapMouseData(url));
//         dispatch(fetchFullScreenShort(pagename));
//     }, [dispatch, url, pagename]);

//     const { heatmapData } = useSelector((state) => state.heatmap);
//     const mouseData = useSelector((state) => state.company.mouseData);
//     const pagesData = mouseData ? mouseData.details : [];

//     // useEffect(() => {
//     //     dispatch(fetchFullScreenShort(pagename));
//     // }, [dispatch]);

//     useEffect(() => {
//         socket.current = socketIOClient(ENDPOINT);
//         heatmapInstance.current = h337.create({
//             container: heatmapContainer.current,
//             radius: 10,
//             maxOpacity: 0.5,
//             minOpacity: 0,
//             blur: 1,
//         });

//         // socket.current.emit("getDataForUrl", url);
//         // socket.current.on("mouseDataResponse", (data) => {
//         let points = [];
//         const originalWidth = 1920; // Replace these values with the size of the viewport when the data was captured
//         const originalHeight = 1080;

//         const scaleX = imageSize.width / originalWidth;
//         const scaleY = imageSize.height / originalHeight;

//         console.log(heatmapData);

//         heatmapData?.forEach((dataPoints) => {
//             console.log("dataPoints ===> ", dataPoints?.data);
//             dataPoints?.data.forEach((mouseEvent) => {
//                 let point = {
//                     x: mouseEvent.x * imageSize.scaleX,
//                     y: mouseEvent.y * imageSize.scaleY,
//                     value: 1,
//                 };
//                 points.push(point);
//             });
//         });

//         heatmapInstance.current.setData({
//             max: 5,
//             data: points,
//         });
//         // });

//         return () => {
//             socket.current.disconnect();
//         };
//     }, [imageSize]);

//     const handleImageLoad = () => {
//         const { naturalWidth, naturalHeight } = imageRef.current;
//         setImageSize({
//             width: naturalWidth,
//             height: naturalHeight,
//             scaleX: heatmapContainer.current.offsetWidth / naturalWidth,
//             scaleY: heatmapContainer.current.offsetHeight / naturalHeight,
//         });
//     };

//     // const mouseData = useSelector((state) => state.company.mouseData);
//     // const pagesData = mouseData ? mouseData.details : [];

//     return (
//         <div className="heatmap-container" ref={heatmapContainer}>
//             <div className="background">
//                 <div className="bounding-box">
//                     <img
//                         ref={imageRef}
//                         src={pagesData.screenshot_url}
//                         alt=""
//                         onLoad={handleImageLoad}
//                     />
//                     <div className="heatmap"></div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default MouseMoveHeatmap;

// import React, { useEffect, useState, useRef } from "react";
// import h337 from "heatmap.js";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import { fetchFullScreenShort } from "../../../redux/company/companySlice";
// import { getHeatMapMouseData } from "../../../redux/heatmap/heatmapSlice";
// import Lottie from "react-lottie";
// import * as animationData from "../../../Assets/Gif/heatmapherogif.json";

// function MouseMoveHeatmap({ data }) {
//     const dispatch = useDispatch();
//     const containerRef = useRef(null);
//     const imageRef = useRef(null);
//     const heatmapContainer = useRef();
//     const { pagename, pageurl } = useParams();
//     const url = decodeURIComponent(pageurl);
//     const heatmapInstance = useRef();

//     const mouseData = useSelector((state) => state.company.mouseData);
//     const pagesData = mouseData ? mouseData.details : [];

//     let points = [];

//     const defaultOptions = {
//         loop: true,
//         autoplay: true,
//         animationData: animationData,
//         rendererSettings: {
//             preserveAspectRatio: "xMidYMid slice",
//         },
//     };

//     useEffect(() => {
//         dispatch(getHeatMapMouseData(url));
//         dispatch(fetchFullScreenShort(pagename));
//     }, [dispatch]);

//     const { heatmapData } = useSelector((state) => state.heatmap);
//     const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
//     useEffect(() => {
//         if (containerRef.current && imageSize.scaleX && imageSize.scaleY) {
//             if (containerRef.current) {
//                 var config = {
//                     container: document.querySelector(".heatmap"),
//                     radius: 10,
//                     maxOpacity: 0.5,
//                     minOpacity: 0,
//                     blur: 0.75,
//                 };

//                 heatmapInstance.current = h337.create(config);
//                 heatmapInstance.current.setData({ max: 5, data: [] });

//                 const scaleX = heatmapContainer.current.offsetWidth / 860;
//                 const scaleY = heatmapContainer.current.offsetHeight / 729;

//                 heatmapData?.forEach((dataPoints) => {
//                     dataPoints?.data.forEach((mouseEvent) => {
//                         let point = {
//                             x: mouseEvent.x * imageSize.scaleX,
//                             y: mouseEvent.y * imageSize.scaleY,
//                             value: 1,
//                         };
//                         points.push(point);
//                     });
//                 });

//                 heatmapInstance.current.setData({
//                     max: 5,
//                     data: points,
//                 });

//                 const formattedData = {
//                     data: points.map((d) => ({
//                         x: d.x / scaleX,
//                         y: d.y / scaleY,
//                     })),
//                 };

//                 heatmapInstance.current.setData(formattedData);
//             }
//         }
//     }, [heatmapData, imageSize.scaleX, imageSize.scaleY]);

//     const handleImageLoad = () => {
//         const { naturalWidth, naturalHeight } = imageRef.current;
//         setImageSize({
//             width: naturalWidth,
//             height: naturalHeight,
//             scaleX: heatmapContainer.current.offsetWidth / naturalWidth,
//             scaleY: heatmapContainer.current.offsetHeight / naturalHeight,
//         });
//         heatmapContainer.current.style.width = "860px";
//         heatmapContainer.current.style.height = "729px";
//     };

//     // return (
//     //     <div className="heatmap-container loader-image">
//     //         <Lottie options={defaultOptions} height={200} width={200} />
//     //     </div>
//     // );

//     return (
//         <div className="heatmap-container" ref={containerRef}>
//             <div className="background">
//                 <div className="bounding-box" ref={heatmapContainer}>
//                     <img
//                         ref={imageRef}
//                         src={pagesData.screenshot_url}
//                         alt=""
//                         onLoad={handleImageLoad}
//                     />
//                     <div className="heatmap"></div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default MouseMoveHeatmap;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import h337 from "heatmap.js";
import { fetchFullScreenShort } from "../../../redux/company/companySlice";
import { getHeatMapMouseData } from "../../../redux/heatmap/heatmapSlice";
import Lottie from "react-lottie";
import * as animationData from "../../../Assets/Gif/heatmapherogif.json";

function MouseMoveHeatmap({ dynamicWidth }) {
    const dispatch = useDispatch();
    const containerRef = useRef(null);
    const imageRef = useRef(null);
    const heatmapContainer = useRef();
    const { pagename, pageurl } = useParams();
    const url = decodeURIComponent(pageurl);
    const [isLoading, setIsLoading] = useState(true);
    const heatmapInstanceRef = useRef(null);

    const mouseData = useSelector((state) => state.company.mouseData);
    const pagesData = mouseData ? mouseData.details : [];

    const { heatmapData } = useSelector((state) => state.heatmap);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [loading, setLoading] = useState(true);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    useEffect(() => {
        // if (heatmapData == null) {
        dispatch(getHeatMapMouseData(url));
        dispatch(fetchFullScreenShort(pagename));
        // }
    }, []);

    const createHeatmapInstance = () => {
        const oldCanvas = document.querySelector(".heatmap-canvas");
        if (oldCanvas) oldCanvas.remove();

        var config = {
            container: document.querySelector(".heatmap"),
            radius: 10,
            maxOpacity: 0.5,
            minOpacity: 0,
            blur: 0.75,
        };
        return h337.create(config);
    };

    useEffect(() => {
        if (containerRef.current && imageSize.scaleX && imageSize.scaleY) {
            var config = {
                container: document.querySelector(".heatmap"),
                radius: 10,
                maxOpacity: 0.5,
                minOpacity: 0,
                blur: 0.75,
            };

            // const heatmapInstance = h337.create(config);

            const heatmapInstance = createHeatmapInstance();
            heatmapInstanceRef.current = heatmapInstance;

            const points = [];

            const scaleX =
                heatmapContainer.current.offsetWidth / imageSize.width;
            const scaleY =
                heatmapContainer.current.offsetHeight / imageSize.height;

            heatmapData?.forEach((dataPoints) => {
                dataPoints?.data.forEach((mouseEvent) => {
                    let point = {
                        x: mouseEvent.x * imageSize.scaleX,
                        y: mouseEvent.y * imageSize.scaleY,
                        value: 1,
                    };
                    points.push(point);
                });
            });

            const formattedData = {
                max: 5,
                data: points.map((d) => ({
                    x: d.x / scaleX,
                    y: d.y / scaleY,
                })),
            };

            heatmapInstance.setData(formattedData);
        }

        return () => {
            const oldCanvas = document.querySelector("heatmap-canvas");
            if (oldCanvas) oldCanvas.remove();
        };
    }, [heatmapData, imageSize.scaleX, imageSize.scaleY]);

    const handleImageLoad = () => {
        const { naturalWidth, naturalHeight } = imageRef.current;

        heatmapContainer.current.style.width = `${naturalWidth}px`;
        heatmapContainer.current.style.height = `${naturalHeight}px`;

        setImageSize({
            width: naturalWidth,
            height: naturalHeight,
            scaleX: heatmapContainer.current.offsetWidth / naturalWidth,
            scaleY: heatmapContainer.current.offsetHeight / naturalHeight,
        });

        const timer = setTimeout(() => {
            applyStyles();
        }, 5000);
    };

    const applyStyles = () => {
        if (heatmapContainer.current) {
            heatmapContainer.current.style.width = dynamicWidth;
            heatmapContainer.current.style.opacity = "1"; // Set opacity to 1

            const canvas =
                heatmapContainer.current.querySelector(".heatmap-canvas");
            if (canvas) {
                canvas.style.width = dynamicWidth;
            }
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         applyStyles();
    //     }, 5000);
    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div className={"heatmap-container"} ref={containerRef}>
            <div className={`background ${loading ? "loading" : "loaded"}`}>
                <div className="bounding-box" ref={heatmapContainer}>
                    <img
                        ref={imageRef}
                        src={pagesData.screenshot_url}
                        alt=""
                        onLoad={handleImageLoad}
                    />
                    <div className="heatmap" width="860"></div>
                </div>
            </div>
            {loading && (
                <div className="heatmaphero-logo-loader">
                    <Lottie options={defaultOptions} height={100} width={100} />
                </div>
            )}{" "}
            {/* Optional: Add a loader */}
        </div>
    );
}

export default MouseMoveHeatmap;
