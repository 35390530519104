import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import h337 from "heatmap.js";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import * as animationData from "../../../Assets/Gif/heatmapherogif.json";
import { getHeatMapMouseData } from "../../../redux/heatmap/heatmapSlice";
import { fetchFullScreenShort } from "../../../redux/company/companySlice";
import Lottie from "react-lottie";

function ScrollHeatmap({ dynamicWidth }) {
    const dispatch = useDispatch();
    const containerRef = useRef(null);
    const imageRef = useRef(null);
    const heatmapContainer = useRef(null);
    const { pagename, pageurl } = useParams();
    const url = decodeURIComponent(pageurl);
    const [isLoading, setIsLoading] = useState(true);
    const heatmapInstanceRef = useRef(null);
    const scrollHeatmapInstanceRef = useRef(null);

    const mouseData = useSelector((state) => state.company.mouseData);
    const pagesData = mouseData ? mouseData.details : [];

    const { heatmapData } = useSelector((state) => state.heatmap);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [loading, setLoading] = useState(true);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    useEffect(() => {
        dispatch(getHeatMapMouseData(url));
        dispatch(fetchFullScreenShort(pagename));
    }, [dispatch, url, pagename]);

    const createHeatmapInstance = (selector) => {
        const container = document.querySelector(selector);
        if (container.querySelector(".heatmap-canvas")) {
            container.querySelector(".heatmap-canvas").remove();
        }
        return h337.create({
            container,
            radius: 10,
            maxOpacity: 0.5,
            minOpacity: 0,
            blur: 0.75,
        });
    };

    useEffect(() => {
        if (containerRef.current && imageSize.scaleX && imageSize.scaleY) {
            const heatmapInstance = createHeatmapInstance(".heatmap");
            const scrollHeatmapInstance =
                createHeatmapInstance(".scroll-heatmap");

            heatmapInstanceRef.current = heatmapInstance;
            scrollHeatmapInstanceRef.current = scrollHeatmapInstance;

            const points = [];
            const scrollPoints = [];

            const scaleX =
                heatmapContainer.current.offsetWidth / imageSize.width;
            const scaleY =
                heatmapContainer.current.offsetHeight / imageSize.height;

            heatmapData?.forEach((dataPoints) => {
                dataPoints?.data.forEach((mouseEvent) => {
                    points.push({
                        x: mouseEvent.x * scaleX,
                        y: mouseEvent.y * scaleY,
                        value: 1,
                    });
                    scrollPoints.push({
                        x: 10, // Fixed x position for scroll bar
                        y: mouseEvent.y * scaleY,
                        value: 1,
                    });
                });
            });

            heatmapInstance.setData({ max: 5, data: points });
            scrollHeatmapInstance.setData({ max: 5, data: scrollPoints });
        }
    }, [heatmapData, imageSize]);

    const handleImageLoad = () => {
        const { naturalWidth, naturalHeight } = imageRef.current;

        heatmapContainer.current.style.width = `${naturalWidth}px`;
        heatmapContainer.current.style.height = `${naturalHeight}px`;

        setImageSize({
            width: naturalWidth,
            height: naturalHeight,
            scaleX: heatmapContainer.current.offsetWidth / naturalWidth,
            scaleY: heatmapContainer.current.offsetHeight / naturalHeight,
        });

        setTimeout(applyStyles, 5000);
    };

    const applyStyles = () => {
        if (heatmapContainer.current) {
            heatmapContainer.current.style.width = dynamicWidth;
            heatmapContainer.current.style.opacity = "1";

            const canvas =
                heatmapContainer.current.querySelector(".heatmap-canvas");
            if (canvas) {
                canvas.style.width = dynamicWidth;
            }
            setLoading(false);
        }
    };

    return (
        <div className="heatmap-container" ref={containerRef}>
            <div className={`background ${loading ? "loading" : "loaded"}`}>
                <div className="bounding-box" ref={heatmapContainer}>
                    <img
                        ref={imageRef}
                        src={pagesData.screenshot_url}
                        alt=""
                        onLoad={handleImageLoad}
                    />
                    <div
                        className="heatmap"
                        style={{ width: "100%", height: "100%" }}
                    ></div>
                    <div
                        className="scroll-heatmap"
                        style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            width: "20px",
                            height: "100%",
                        }}
                    ></div>
                </div>
            </div>
            {loading && (
                <div className="heatmaphero-logo-loader">
                    <Lottie options={defaultOptions} height={100} width={100} />
                </div>
            )}
        </div>
    );
}

export default ScrollHeatmap;
