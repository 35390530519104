import logoWhite from "./../../Assets/Images/logo-white.svg";
// import PersonalDetail from "./../components/SignUpStep1";
import CompanyDetail from "../Public/SignUpStep2";
import CodeIntigration from "../Public/SignUpStep3";
// import SignIn from "./../components/SignIn";
import Signup from "../Public/Signup";
import lightImage from "./../../Assets/Images/Light accent.png";
import starImage from "./../../Assets/Images/stars-02.svg";
import Login from "../Public/Login";
import ForgotPassword from "../Public/ForgotPassword";
import SignupFlow from "../Public/SignupFlow";
import { Link, useLocation } from "react-router-dom";

function PublicSidebar({ routeName }) {
    const detailCLicked = () => {
        // console.log("Hello 123");
    };
    const location = useLocation();
    const pageName = location.pathname.split("/").pop();
    return (
        <div className="page--wrapper">
            <div className="sidebar--wrapper">
                <div className="sidebar--inner">
                    <div className="logo--wrapper">
                        <img src={logoWhite} alt="" className="logo--img" />
                    </div>

                    {routeName == "login" || routeName == "forgotpassword" ? (
                        <div className="login--sidebar--wrapper">
                            <div className="content--block">
                                <div>
                                    <div className="star">
                                        <img
                                            src={starImage}
                                            alt=""
                                            className="logo--img"
                                        />
                                    </div>
                                    <h3>
                                        Stay ahead of the game with our powerful
                                        user behaviour tool
                                    </h3>
                                    <div className="image wrapper">
                                        <img
                                            src={lightImage}
                                            alt=""
                                            className="login--side--img"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="progress--tracking--wrapper">
                            <div className="content--block">
                                <div className="tracking--block">
                                    <div
                                        className={`tracking--item ${
                                            pageName == "signup" ||
                                            pageName == "companydetails" ||
                                            pageName == "codeintegration"
                                                ? "done"
                                                : ""
                                        }`}
                                        onClick={detailCLicked}
                                    >
                                        <span className="tracking--title fw-400 h5">
                                            Your details
                                        </span>
                                        <span className="tracking--subtitle fw-400 h7">
                                            Enter your name, email & password
                                        </span>
                                    </div>
                                    <div
                                        className={`tracking--item ${
                                            pageName == "companydetails" ||
                                            pageName == "codeintegration"
                                                ? "done"
                                                : ""
                                        }`}
                                    >
                                        <span className="tracking--title fw-400 h5">
                                            Company details
                                        </span>
                                        <span className="tracking--subtitle fw-400 h7">
                                            Enter company name & website
                                        </span>
                                    </div>
                                    <div
                                        className={`tracking--item ${
                                            pageName == "codeintegration"
                                                ? "done"
                                                : ""
                                        }`}
                                        href="#!"
                                    >
                                        <span className="tracking--title fw-400 h5">
                                            Code intigration
                                        </span>
                                        <span className="tracking--subtitle fw-400 h7">
                                            Paste our code in your website
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <p className="text-light-blue mb-0 mt-auto">
                        hello@heatmaphero.com
                    </p>
                </div>
            </div>
            <div className="content--wrapper">
                <div className="form--wrapper">
                    {/* <PersonalDetail /> */}
                    {/* <CompanyDetail /> */}
                    {/* <CodeIntigration /> */}
                    {/* <SignIn /> */}
                    {routeName == "login" ? (
                        <Login />
                    ) : routeName == "forgotpassword" ? (
                        <ForgotPassword />
                    ) : routeName == "companydetails" ? (
                        <CompanyDetail />
                    ) : routeName == "codeintegration" ? (
                        <CodeIntigration />
                    ) : (
                        <SignupFlow />
                    )}
                </div>
            </div>
        </div>
    );
}

export default PublicSidebar;
