import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import chromeIcon from "./../../Assets/Images/chrome.svg";
import safariIcon from "./../../Assets/Images/safari.svg";
import pcIcon from "./../../Assets/Images/monitor.svg";
import appleIcon from "./../../Assets/Images/apple.svg";
import mobileIcon from "./../../Assets/Images/mobile.svg";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { Link } from "react-router-dom";
import Frustation from "./../../Assets/Images/frustrated.png";
import Sad from "./../../Assets/Images/sad.png";
import Happy from "./../../Assets/Images/smile.png";

import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { getSessionList } from "../../redux/sessionrecord/sessionRecordSlice";
import getCountryFlag, { convertMilliseconds } from "./Helper";
import {
    BrowserEdgeIcon,
    BrowserGoogleIcon,
    BrowserIEIcon,
    BrowserMozillaIcon,
    BrowserSafariIcon,
    BrowserYandexIcon,
    FrustationIcon,
    HappyIcon,
    OSAndroidIcon,
    OSBlackBerryIcon,
    OSChromeIcon,
    OSLinuxIcon,
    OSMacIcon,
    OSWindowsIcon,
    OSiOSIcon,
    PlayIcon,
    SadIcon,
} from "../../Assets/icons";
import { it } from "date-fns/locale";
import OsIcon from "./Helper/OsIcon";
import BrowserIcon from "./Helper/BrowserIcon";
function SessionReplay(props) {
    const dispatch = useDispatch();

    const userObject = useSelector((state) => state.user.userData);
    const userId = userObject?.user?.uniqueid;

    // useEffect(() => {
    //     dispatch(
    //         getSessionList({ userId: userId, page: currentPage, limit: 10 })
    //     );
    // }, [userId]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    // const [sessionList, setSessionList] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [errorList, setErrorList] = useState([]);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        dispatch(
            getSessionList({ userId: userId, page: currentPage, limit: 20 })
        ).then((result) => {
            if (result.payload) {
                // setSessionList(result.payload.data);
                setTotalPages(result.payload.totalPages);
                setErrorList(result.payload.error);
            }
        });
    }, [userId, currentPage, dispatch, refreshKey]);

    const refreshlist = () => {
        setRefreshKey((oldKey) => oldKey + 1);
    };

    const sessionRecordData = useSelector(
        (state) => state.sessionrecord.sessionRecordData
    );

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    if (!sessionRecordData) {
        return <>Loading...</>;
    }

    function getPageName(url) {
        if (!url) return "/";
        let parsedUrl = new URL(url);
        let pathname = parsedUrl.pathname;
        if (pathname === "/") {
            return pathname;
        }
        let components = pathname.split("/");
        let pageName = components[components.length - 1];
        return "/" + pageName;
    }

    function errorCountFunc(session_id) {
        let count = 0;
        for (let i = 0; i < errorList.length; i++) {
            if (errorList[i].session_id === session_id) {
                count++;
            }
        }
        return count;
    }

    const formatted = sessionRecordData.map((item) => {
        return {
            ...item,
            countryFlag: getCountryFlag(item.country),
            errorCount: errorCountFunc(item.session_id),
            durationText: convertMilliseconds(item.duration),
            startPage: getPageName(item.start_page),
            endPage: getPageName(item.end_page),
        };
    });

    const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        dispatch(getSessionList({ userId: userId, page: page, limit: 20 }));
    };
    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(startPage + 4, totalPages);

    const visiblePages = pagesArray.slice(startPage - 1, endPage);

    return (
        <div className="main--wrapper">
            <header className="header--wrapper">
                <div className="header--inner container">
                    <div className="left">
                        <h1 className="h4 mb-0 d-flex align-items-center">
                            Session replay
                        </h1>
                    </div>
                    <div className="right d-flex">
                        <div className="input-wrapper me-2">
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control fw-semibold form-control-sm bg-light"
                            />
                            <svg
                                width="20"
                                className="absolute--right"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.5 18L14.5834 15.0833M16.6667 10.0833C16.6667 13.9954 13.4954 17.1667 9.58333 17.1667C5.67132 17.1667 2.5 13.9954 2.5 10.0833C2.5 6.17132 5.67132 3 9.58333 3C13.4954 3 16.6667 6.17132 16.6667 10.0833Z"
                                    stroke="#C2C0C9"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle className="btn-sm d-flex align-items-center">
                                <svg
                                    className="dropdown-left-icon"
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.5 7.83366H1.5M12.3333 1.16699V4.50033M5.66667 1.16699V4.50033M5.5 17.8337H12.5C13.9001 17.8337 14.6002 17.8337 15.135 17.5612C15.6054 17.3215 15.9878 16.939 16.2275 16.4686C16.5 15.9339 16.5 15.2338 16.5 13.8337V6.83366C16.5 5.43353 16.5 4.73346 16.2275 4.19868C15.9878 3.72828 15.6054 3.34583 15.135 3.10614C14.6002 2.83366 13.9001 2.83366 12.5 2.83366H5.5C4.09987 2.83366 3.3998 2.83366 2.86502 3.10614C2.39462 3.34583 2.01217 3.72828 1.77248 4.19868C1.5 4.73346 1.5 5.43353 1.5 6.83366V13.8337C1.5 15.2338 1.5 15.9339 1.77248 16.4686C2.01217 16.939 2.39462 17.3215 2.86502 17.5612C3.3998 17.8337 4.09987 17.8337 5.5 17.8337Z"
                                        stroke="#74747F"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                12 Months
                                <svg
                                    className="dropdown-right-icon"
                                    width="10"
                                    height="7"
                                    viewBox="0 0 10 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L5 5.5L9 1.5"
                                        stroke="#74747F"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>3 Months</DropdownItem>
                                <DropdownItem>6 Months</DropdownItem>
                                <DropdownItem>9 Months</DropdownItem>
                                <DropdownItem>12 Months</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Button
                            color="secondary"
                            size="sm"
                            className="notification--btn px-2 ms-2"
                        >
                            <svg
                                className="btn-icon"
                                width="20"
                                height="23"
                                viewBox="0 0 20 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.35395 20.5C8.05908 21.1224 8.98533 21.5 9.99977 21.5C11.0142 21.5 11.9405 21.1224 12.6456 20.5M15.9998 7.5C15.9998 5.9087 15.3676 4.38258 14.2424 3.25736C13.1172 2.13214 11.5911 1.5 9.99977 1.5C8.40848 1.5 6.88235 2.13214 5.75713 3.25736C4.63192 4.38258 3.99977 5.9087 3.99977 7.5C3.99977 10.5902 3.22024 12.706 2.34944 14.1054C1.6149 15.2859 1.24763 15.8761 1.2611 16.0408C1.27601 16.2231 1.31463 16.2926 1.46155 16.4016C1.59423 16.5 2.19237 16.5 3.38863 16.5H16.6109C17.8072 16.5 18.4053 16.5 18.538 16.4016C18.6849 16.2926 18.7235 16.2231 18.7384 16.0408C18.7519 15.8761 18.3846 15.2859 17.6501 14.1054C16.7793 12.706 15.9998 10.5902 15.9998 7.5Z"
                                    stroke="#292934"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="badge badge-danger">03</span>
                        </Button>
                    </div>
                </div>
            </header>
            <div className="content">
                <div className="content-inner">
                    <div className="container">
                        <div className="recent-heatmapes-list">
                            <div className="d-flex align-items-center mb-3">
                                <h2 className="h5 text-primary-3 mb-0">
                                    {sessionRecordData.length} recordings
                                </h2>
                                <div className="ms-auto">
                                    <a
                                        href="/"
                                        className="btn p-2 lh-1 rounded-circle svg-hover"
                                    >
                                        <svg
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            className="svg-item"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.875 9.625V10.475C13.875 11.6651 13.875 12.2602 13.6434 12.7147C13.4397 13.1146 13.1146 13.4397 12.7147 13.6434C12.2602 13.875 11.6651 13.875 10.475 13.875H4.525C3.33489 13.875 2.73983 13.875 2.28527 13.6434C1.88543 13.4397 1.56034 13.1146 1.35661 12.7147C1.125 12.2602 1.125 11.6651 1.125 10.475V9.625M11.0417 6.08333L7.5 9.625M7.5 9.625L3.95833 6.08333M7.5 9.625V1.125"
                                                stroke="#B0B0BB"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </a>
                                    <button
                                        onClick={refreshlist}
                                        className="btn p-2 lh-1 rounded-circle svg-hover"
                                    >
                                        <svg
                                            width="17"
                                            height="17"
                                            viewBox="0 0 17 17"
                                            fill="none"
                                            className="svg-item"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.41699 9.91667C1.41699 9.91667 1.50293 10.5182 3.99252 13.0078C6.48211 15.4974 10.5185 15.4974 13.0081 13.0078C13.8902 12.1257 14.4598 11.0495 14.7168 9.91667M1.41699 9.91667V14.1667M1.41699 9.91667H5.66699M15.5837 7.08333C15.5837 7.08333 15.4977 6.48179 13.0081 3.99219C10.5185 1.5026 6.48211 1.5026 3.99252 3.99219C3.11045 4.87426 2.5409 5.95051 2.28387 7.08333M15.5837 7.08333V2.83333M15.5837 7.08333H11.3337"
                                                stroke="#B0B0BB"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="table-wrapper">
                                <div className="table-responsive text-nowrap">
                                    <Table className="m-0" striped={true}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-inline-flex form-check p-0 pt-1 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </th>
                                                <th>Action</th>
                                                <th>Date</th>
                                                <th>Country</th>
                                                <th>User</th>
                                                {/* <th>Actions</th> */}
                                                <th>Pages</th>
                                                <th>Duration</th>
                                                <th>Errors</th>
                                                <th>Frustation</th>
                                                <th>Device</th>
                                                <th>Landing Page</th>
                                                <th>Exit Page</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formatted.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                            <input
                                                                className="form-check-input m-0"
                                                                type="checkbox"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={`/recording/${item.session_id}`}
                                                            className="text-gray h7 d-inline-block mt-1"
                                                        >
                                                            <PlayIcon />
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        {new Date(
                                                            item.start_time
                                                        ).toLocaleString()}{" "}
                                                    </td>
                                                    <td>
                                                        <span className="country-item">
                                                            <img
                                                                src={
                                                                    item.countryFlag
                                                                }
                                                                alt={`${item.country} flag`}
                                                                className="me-1 flage-image"
                                                            />
                                                            {item.country}
                                                        </span>
                                                    </td>
                                                    <td>{item.session_id}</td>
                                                    {/* <td>56</td> */}
                                                    <td>{item.page_travel}</td>
                                                    <td>{item.durationText}</td>
                                                    <td>{item.errorCount}</td>
                                                    <td>
                                                        {item.frustration_level ==
                                                        "Very High" ? (
                                                            <FrustationIcon />
                                                        ) : item.frustration_level ==
                                                          "High" ? (
                                                            <SadIcon />
                                                        ) : (
                                                            <HappyIcon />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <BrowserIcon
                                                            browser={
                                                                item.browser
                                                            }
                                                        />
                                                        <span className="browser-item">
                                                            <OsIcon
                                                                os={item.os}
                                                            />
                                                        </span>
                                                        <span className="browser-item">
                                                            {item.device ===
                                                            "Desktop" ? (
                                                                <img
                                                                    src={pcIcon}
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        mobileIcon
                                                                    }
                                                                    alt=""
                                                                />
                                                            )}
                                                        </span>
                                                        {/* <span className="browser-item">
                                                            <img
                                                                src={safariIcon}
                                                                alt=""
                                                            />
                                                        </span> */}
                                                    </td>
                                                    <td>{item.startPage}</td>
                                                    <td>{item.endPage}</td>
                                                </tr>
                                            ))}

                                            {/* <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-inline-flex form-check p-0 w-auto align-items-center">
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href="/"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <svg
                                                            className="me-1"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
                                                                stroke="#7B3FE4"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Play
                                                    </a>
                                                </td>
                                                <td> 03 Feb, 20:34</td>
                                                <td>
                                                    <span className="country-item">
                                                        <img
                                                            src={saudiFlag}
                                                            alt="saudi arabia flag"
                                                            className="me-1"
                                                        />
                                                        Saudi Arabia
                                                    </span>
                                                </td>
                                                <td>34drfsdhr53</td>
                                                <td>56</td>
                                                <td>06</td>
                                                <td>3:45</td>
                                                <td>
                                                    <span className="browser-item">
                                                        <img
                                                            src={chromeIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={appleIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={pcIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="browser-item">
                                                        <img
                                                            src={safariIcon}
                                                            alt=""
                                                        />
                                                    </span>
                                                </td>
                                                <td>/</td>
                                                <td>/careers</td>
                                            </tr> */}
                                        </tbody>
                                    </Table>
                                    <div className="pagination-row px-2 py-3">
                                        <Pagination className="align-items-center">
                                            {/* <PaginationItem>
                                                <PaginationLink href="#">
                                                    1
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">
                                                    2
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">
                                                    3
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">
                                                    ...
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">
                                                    34
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">
                                                    35
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">
                                                    36
                                                </PaginationLink>
                                            </PaginationItem> */}
                                            {/* {console.log("-----", pagesArray)}
                                            {pagesArray.map((page, index) => (
                                                <PaginationItem key={index}>
                                                    <PaginationLink
                                                        onClick={() =>
                                                            handlePageChange(
                                                                page
                                                            )
                                                        }
                                                        href="#"
                                                    >
                                                        {page}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                            <PaginationItem className="ms-auto me-3">
                                                <PaginationLink
                                                    className="navigation"
                                                    href="#"
                                                >
                                                    <svg
                                                        className="me-2"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M15.8337 10.0003H4.16699M4.16699 10.0003L10.0003 15.8337M4.16699 10.0003L10.0003 4.16699"
                                                            stroke="#292934"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    Previous
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink
                                                    className="navigation"
                                                    next
                                                    href="#"
                                                >
                                                    Next
                                                    <svg
                                                        className="ms-2"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M4.16699 10.0003H15.8337M15.8337 10.0003L10.0003 4.16699M15.8337 10.0003L10.0003 15.8337"
                                                            stroke="#292934"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </PaginationLink>
                                            </PaginationItem> */}

                                            {visiblePages.map((page) => (
                                                <PaginationItem key={page}>
                                                    <PaginationLink
                                                        onClick={() =>
                                                            handlePageChange(
                                                                page
                                                            )
                                                        }
                                                        className={`${
                                                            page === currentPage
                                                                ? "pagination-active"
                                                                : ""
                                                        }`}
                                                        href="#"
                                                    >
                                                        {page}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                            <PaginationItem className="ms-auto me-3">
                                                <PaginationLink
                                                    className="navigation"
                                                    onClick={() =>
                                                        handlePageChange(
                                                            Math.max(
                                                                currentPage - 1,
                                                                1
                                                            )
                                                        )
                                                    }
                                                    href="#"
                                                >
                                                    <svg
                                                        className="me-2"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M15.8337 10.0003H4.16699M4.16699 10.0003L10.0003 15.8337M4.16699 10.0003L10.0003 4.16699"
                                                            stroke="#292934"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    Previous
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink
                                                    className="navigation"
                                                    onClick={() =>
                                                        handlePageChange(
                                                            Math.min(
                                                                currentPage + 1,
                                                                totalPages
                                                            )
                                                        )
                                                    }
                                                    href="#"
                                                >
                                                    Next
                                                    <svg
                                                        className="ms-2"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M4.16699 10.0003H15.8337M15.8337 10.0003L10.0003 4.16699M15.8337 10.0003L10.0003 15.8337"
                                                            stroke="#292934"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SessionReplay;
