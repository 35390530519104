import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import { companyReducer } from "./company/companySlice";
import { dashboardReducer } from "./dashboard/dashboardSlice";
import { heatmapReducer } from "./heatmap/heatmapSlice";
import { sessionRecordReducer } from "./sessionrecord/sessionRecordSlice";
import { alertsReducer } from "./alerts/alertsSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        company: companyReducer,
        dashboard: dashboardReducer,
        heatmap: heatmapReducer,
        sessionrecord: sessionRecordReducer,
        alerts: alertsReducer,
    },
});

export default store;
