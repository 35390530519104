import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
} from "reactstrap";
import PieChartComponent from "../../Charts/PieChart";
import { getDeviceCount } from "../../../redux/dashboard/dashboardSlice";

const formatData = (data) => {
    let output = {};

    if (!data) return [];

    data.forEach((item) => {
        output[item.device] = parseInt(item.count);
    });

    let total = 0;
    Object.values(output).forEach((value) => {
        total += value;
    });

    output["Total"] = total;

    return output;
};

const DeviceCount = (props) => {
    const dispatch = useDispatch();

    const { deviceCountData, status, error } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        if (props.userId) {
            dispatch(getDeviceCount(props.userId));
        }
    }, [dispatch]);

    const deviceData = formatData(deviceCountData);

    return (
        <Col md="6">
            <div className="card">
                <div className="card-body">
                    <div className="dash-card-header">
                        <h4 className="dash-card-title mb-0 h6">Devices</h4>
                        <span className="text-danger h6 mb-0 icon-item d-flex align-items-center">
                            {/* 1.23% */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                className="ms-2"
                                viewBox="0 0 20 20"
                            >
                                <rect
                                    width="20"
                                    height="20"
                                    fill="#FBE1E6"
                                    rx="10"
                                ></rect>
                                <path
                                    stroke="#BC3852"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M6.667 6.667l6.667 6.667m0 0V6.667m0 6.667H6.667"
                                ></path>
                            </svg>
                        </span>
                    </div>
                    <div className="dash-card-body chart-item-wrapper">
                        {/* <div className="left"> */}
                        <PieChartComponent
                            ChartTotalData={deviceData.Total}
                            ChartColorOne={"#ffc107"}
                            ChartColorTwo={"#6433B9"}
                            ChartValueOne={deviceData.Desktop}
                            ChartValueTwo={deviceData.Mobile}
                        />
                        {/* </div> */}
                        <div className="right">
                            <div className="chart-list-item circle-blue">
                                <h6 className="h7 fw-normal mb-1">
                                    Mobile user
                                </h6>
                                <h4 className="h5 mb-0">{deviceData.Mobile}</h4>
                            </div>
                            <div className="chart-list-item circle-orange">
                                <h6 className="h7 fw-normal mb-1">
                                    Desktop user
                                </h6>
                                <h4 className="h5 mb-0">
                                    {deviceData.Desktop}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default DeviceCount;
