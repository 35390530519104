import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import LineChartComponent from "../Charts/LineChart";
import {
    getTopLinkCount,
    getReferrerCountsData,
} from "../../redux/dashboard/dashboardSlice";
import TopPages from "./Dashboard/TopPages";
import TotalSession from "./Dashboard/TotalSession";
import CurrentSession from "./Dashboard/CurrentSession";
import SessionDuration from "./Dashboard/SessionDuration";
import TopClicks from "./Dashboard/TopClicks";
import UserCount from "./Dashboard/UserCount";
import DeviceCount from "./Dashboard/DeviceCount";
import CountryWiseData from "./Dashboard/CountryWiseData";
import DataWiseMap from "./Dashboard/DataWiseMap";
import Lottie from "react-lottie";
import * as animationData from "../../Assets/Gif/heatmapherogif.json";
import { useLocation } from "react-router-dom";
import HeavyResources from "./Dashboard/HeavyResources";
import SolwestResources from "./Dashboard/SolwestResources";
import Referer from "./Dashboard/Referer";

function Dashboard(props) {
    const dispatch = useDispatch();
    const userObject = useSelector((state) => state.user.userData);
    const userId = userObject?.user?.uniqueid;
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    useEffect(() => {
        if (userObject && userObject?.user) {
            dispatch(getTopLinkCount(userId));
            dispatch(getReferrerCountsData(userId));
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    }, [dispatch, userObject, location.search, userId]);

    // if (userObject.cd.length === 0) {
    //     return (
    //         <div className="main--wrapper">
    //             <header className="header--wrapper">
    //                 <div className="header--inner container">
    //                     <div className="left">
    //                         <h1 className="h4 mb-0">Get Started</h1>
    //                     </div>

    //                     <div className="right d-flex">
    //                         <Dropdown isOpen={dropdownOpen} toggle={toggle}>
    //                             <DropdownToggle className="btn-sm d-flex align-items-center">
    //                                 <svg
    //                                     className="dropdown-left-icon"
    //                                     width="18"
    //                                     height="19"
    //                                     viewBox="0 0 18 19"
    //                                     fill="none"
    //                                     xmlns="http://www.w3.org/2000/svg"
    //                                 >
    //                                     <path
    //                                         d="M16.5 7.83366H1.5M12.3333 1.16699V4.50033M5.66667 1.16699V4.50033M5.5 17.8337H12.5C13.9001 17.8337 14.6002 17.8337 15.135 17.5612C15.6054 17.3215 15.9878 16.939 16.2275 16.4686C16.5 15.9339 16.5 15.2338 16.5 13.8337V6.83366C16.5 5.43353 16.5 4.73346 16.2275 4.19868C15.9878 3.72828 15.6054 3.34583 15.135 3.10614C14.6002 2.83366 13.9001 2.83366 12.5 2.83366H5.5C4.09987 2.83366 3.3998 2.83366 2.86502 3.10614C2.39462 3.34583 2.01217 3.72828 1.77248 4.19868C1.5 4.73346 1.5 5.43353 1.5 6.83366V13.8337C1.5 15.2338 1.5 15.9339 1.77248 16.4686C2.01217 16.939 2.39462 17.3215 2.86502 17.5612C3.3998 17.8337 4.09987 17.8337 5.5 17.8337Z"
    //                                         stroke="#74747F"
    //                                         strokeWidth="1.5"
    //                                         strokeLinecap="round"
    //                                         strokeLinejoin="round"
    //                                     />
    //                                 </svg>
    //                                 12 Months
    //                                 <svg
    //                                     className="dropdown-right-icon"
    //                                     width="10"
    //                                     height="7"
    //                                     viewBox="0 0 10 7"
    //                                     fill="none"
    //                                     xmlns="http://www.w3.org/2000/svg"
    //                                 >
    //                                     <path
    //                                         d="M1 1.5L5 5.5L9 1.5"
    //                                         stroke="#74747F"
    //                                         strokeWidth="1.5"
    //                                         strokeLinecap="round"
    //                                         strokeLinejoin="round"
    //                                     />
    //                                 </svg>
    //                             </DropdownToggle>
    //                             <DropdownMenu>
    //                                 <DropdownItem>3 Months</DropdownItem>
    //                                 <DropdownItem>6 Months</DropdownItem>
    //                                 <DropdownItem>9 Months</DropdownItem>
    //                                 <DropdownItem>12 Months</DropdownItem>
    //                             </DropdownMenu>
    //                         </Dropdown>
    //                         <Button
    //                             color="secondary"
    //                             size="sm"
    //                             className="notification--btn px-2 ms-2"
    //                         >
    //                             <svg
    //                                 className="btn-icon"
    //                                 width="20"
    //                                 height="23"
    //                                 viewBox="0 0 20 23"
    //                                 fill="none"
    //                                 xmlns="http://www.w3.org/2000/svg"
    //                             >
    //                                 <path
    //                                     d="M7.35395 20.5C8.05908 21.1224 8.98533 21.5 9.99977 21.5C11.0142 21.5 11.9405 21.1224 12.6456 20.5M15.9998 7.5C15.9998 5.9087 15.3676 4.38258 14.2424 3.25736C13.1172 2.13214 11.5911 1.5 9.99977 1.5C8.40848 1.5 6.88235 2.13214 5.75713 3.25736C4.63192 4.38258 3.99977 5.9087 3.99977 7.5C3.99977 10.5902 3.22024 12.706 2.34944 14.1054C1.6149 15.2859 1.24763 15.8761 1.2611 16.0408C1.27601 16.2231 1.31463 16.2926 1.46155 16.4016C1.59423 16.5 2.19237 16.5 3.38863 16.5H16.6109C17.8072 16.5 18.4053 16.5 18.538 16.4016C18.6849 16.2926 18.7235 16.2231 18.7384 16.0408C18.7519 15.8761 18.3846 15.2859 17.6501 14.1054C16.7793 12.706 15.9998 10.5902 15.9998 7.5Z"
    //                                     stroke="#292934"
    //                                     strokeWidth="1.5"
    //                                     strokeLinecap="round"
    //                                     strokeLinejoin="round"
    //                                 />
    //                             </svg>
    //                             <span className="badge badge-danger">03</span>
    //                         </Button>
    //                     </div>
    //                 </div>
    //             </header>
    //             <div className="content">
    //                 <div className="content-inner">
    //                     <div className="container">
    //                         <Row className="g-4">
    //                             <div className="telescope-guy-image">
    //                                 <img src={telescopeGuy} alt="" />
    //                             </div>
    //                         </Row>
    //                         <Row>
    //                             <p className="script-section-title">
    //                                 Enhance Your Website Insights
    //                             </p>
    //                         </Row>
    //                         <Row>
    //                             <p>
    //                                 Install our script for a treasure trove of
    //                                 data about your website. Make informed
    //                                 decisions and outperform the competition.
    //                             </p>
    //                         </Row>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }

    if (loading) {
        return (
            <div className="heatmaphero-logo-loader">
                <Lottie options={defaultOptions} height={100} width={100} />
            </div>
        );
    }

    return (
        <>
            <div className="main--wrapper">
                <header className="header--wrapper">
                    <div className="header--inner container">
                        <div className="left">
                            <h1 className="h4 mb-0">Dashboard</h1>
                        </div>

                        <div className="right d-flex">
                            {/* <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle className="btn-sm d-flex align-items-center">

                                </DropdownToggle>
                            </Dropdown> */}
                            {/* <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggleDropdown}
                            >
                                <DropdownToggle caret>
                                    <DatePickerIcon />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DateRangeSelector
                                        onDateChange={handleDateChange}
                                    />
                                </DropdownMenu>
                            </Dropdown>
                            <Button
                                color="secondary"
                                size="sm"
                                className="notification--btn px-2 ms-2"
                            >
                                <svg
                                    className="btn-icon"
                                    width="20"
                                    height="23"
                                    viewBox="0 0 20 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.35395 20.5C8.05908 21.1224 8.98533 21.5 9.99977 21.5C11.0142 21.5 11.9405 21.1224 12.6456 20.5M15.9998 7.5C15.9998 5.9087 15.3676 4.38258 14.2424 3.25736C13.1172 2.13214 11.5911 1.5 9.99977 1.5C8.40848 1.5 6.88235 2.13214 5.75713 3.25736C4.63192 4.38258 3.99977 5.9087 3.99977 7.5C3.99977 10.5902 3.22024 12.706 2.34944 14.1054C1.6149 15.2859 1.24763 15.8761 1.2611 16.0408C1.27601 16.2231 1.31463 16.2926 1.46155 16.4016C1.59423 16.5 2.19237 16.5 3.38863 16.5H16.6109C17.8072 16.5 18.4053 16.5 18.538 16.4016C18.6849 16.2926 18.7235 16.2231 18.7384 16.0408C18.7519 15.8761 18.3846 15.2859 17.6501 14.1054C16.7793 12.706 15.9998 10.5902 15.9998 7.5Z"
                                        stroke="#292934"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span className="badge badge-danger">03</span>
                            </Button> */}
                        </div>
                    </div>
                </header>
                <div className="content">
                    <div className="content-inner">
                        <div className="container">
                            <Row className="g-4">
                                <TotalSession userId={userId} />
                                <CurrentSession userId={userId} />
                                <SessionDuration userId={userId} />
                            </Row>

                            <Row></Row>

                            <Row className="g-4 mt-0">
                                <TopPages userId={userId} />
                                <TopClicks userId={userId} />
                            </Row>
                            <Row className="g-4 mt-0">
                                <Col md="12">
                                    <div className="card">
                                        <div className="card-body pt-5">
                                            <LineChartComponent
                                                data={userObject}
                                            />
                                        </div>
                                        <div className="card-header">
                                            <div className="d-flex justify-content-evenly">
                                                <h4 className="dash-card-title mb-0 h7 chart-indicator-parent">
                                                    <div className="chart-indicator bounce-rate"></div>
                                                    Bounce Rate
                                                </h4>
                                                <h4 className="dash-card-title mb-0 h7 chart-indicator-parent">
                                                    <div className="chart-indicator engagement-rate"></div>
                                                    Engagement Rate
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="g-4 mt-0">
                                <HeavyResources userId={userId} />
                                {/* <SolwestResources userId={userId} /> */}
                                <Referer />
                            </Row>

                            <Row className="g-4 mt-0">
                                <UserCount userId={userId} />
                                <DeviceCount userId={userId} />
                            </Row>
                            <Row className="g-4 mt-0">
                                <Col md="12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="dash-card-header">
                                                <h2 className="dash-card-title mb-0 h6">
                                                    Country wise active users
                                                </h2>
                                            </div>
                                            <div className="dash-card-body scroll--height">
                                                <Row>
                                                    <Col md="8">
                                                        {/* <img
                                                        src={countryMap}
                                                        alt=""
                                                    /> */}
                                                        <DataWiseMap
                                                            // setTooltipContent={
                                                            //     setContent
                                                            // }
                                                            userId={userId}
                                                        />
                                                    </Col>
                                                    <CountryWiseData
                                                        userId={userId}
                                                    />
                                                    {/* <MapChart /> */}
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
