import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
} from "reactstrap";
import { getSessionDuration } from "../../../redux/dashboard/dashboardSlice";
import AreaChartComponent from "../../Charts/AreaChart";

function SessionDuration({ userId, md }) {
    const dispatch = useDispatch();
    let widthMD = md ? md : 4;

    const getSessionDurationData = useCallback(() => {
        dispatch(getSessionDuration(userId));
    }, [dispatch, userId]);

    const { sessionDuration, status, error } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        getSessionDurationData();
    }, [getSessionDurationData]);

    const dataThird = [
        {
            name: "Page A",
            uv: 100,
            pv: 2400,
            amt: 2400,
        },
        {
            name: "Page B",
            uv: 200,
            pv: 1398,
            amt: 2210,
        },
        {
            name: "Page C",
            uv: 150,
            pv: 9800,
            amt: 2290,
        },
        {
            name: "Page D",
            uv: 300,
            pv: 3908,
            amt: 2000,
        },
        {
            name: "Page E",
            uv: 350,
            pv: 4800,
            amt: 2181,
        },
        {
            name: "Page F",
            uv: 380,
            pv: 3800,
            amt: 2500,
        },
        {
            name: "Page G",
            uv: 480,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <Col md={widthMD}>
            <div className="card">
                <div className="card-body">
                    <div className="dash-card-header">
                        <h4 className="dash-card-title mb-0 h6">
                            Session Duration
                        </h4>
                        {/* <span className="text-info h6 mb-0 icon-item d-flex align-items-center">
                            2.15%
                            <svg
                                className="ms-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <rect
                                    width="20"
                                    height="20"
                                    fill="#E1F1F5"
                                    rx="10"
                                ></rect>
                                <path
                                    stroke="#378A9C"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M6.667 13.334l6.667-6.667m0 0H6.667m6.667 0v6.667"
                                ></path>
                            </svg>
                        </span> */}
                    </div>
                    <div className="dash-card-body">
                        <h3 className="h2">{sessionDuration}</h3>
                        <div className="chart-wrapper">
                            <AreaChartComponent
                                id={"cyne"}
                                firstColor={"#0DCAF1"}
                                strokeColor={"#44AAC0"}
                                data={dataThird}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
}

export default SessionDuration;
