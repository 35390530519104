import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import { fetchAlerts, deleteAlert } from "../../redux/alerts/alertsSlice";
import DotImage from "../../Assets/Images/dots-vertical.svg";

const PopoverMenu = ({ onDelete }) => (
    <div className="popover-menu">
        <button onClick={onDelete}>Delete</button>
    </div>
);

function Alerts() {
    const dispatch = useDispatch();
    const alertsList = useSelector((state) => state.alerts.alertsList);
    const userObject = useSelector((state) => state.user.userData);
    const [activePopoverId, setActivePopoverId] = useState(null);
    useEffect(() => {
        dispatch(fetchAlerts());
    }, []);

    const [openMenuId, setOpenMenuId] = useState(null);

    const handleMenuToggle = (id) => {
        setActivePopoverId(activePopoverId === id ? null : id);
    };

    const handleDelete = (alertId) => {
        console.log("Delete action for ID:", alertId);
        dispatch(deleteAlert(alertId));
        setActivePopoverId(null);
    };

    const alertsData = alertsList ? alertsList?.data : [];
    const websiteURL = userObject.cd[0].website_url;

    function shortenUrl(url, maxLength = 20) {
        if (url.length <= maxLength) {
            return url;
        }
        const firstPart = url.substr(0, maxLength / 2);
        const lastPart = url.substr(-maxLength / 2);
        return `${firstPart}...${lastPart}`;
    }

    return (
        <div className="d-flex flex-column main--wrapper">
            <header className="header--wrapper w-100">
                <div className="header--inner container">
                    <div className="left">
                        <h1 className="h4 mb-0 d-flex align-items-center">
                            Alerts
                            <a href="/">
                                <svg
                                    width="17"
                                    height="17"
                                    className="ms-2"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_1075_2463)">
                                        <path
                                            d="M8.50033 11.3337V8.50033M8.50033 5.66699H8.50741M15.5837 8.50033C15.5837 12.4123 12.4123 15.5837 8.50033 15.5837C4.58831 15.5837 1.41699 12.4123 1.41699 8.50033C1.41699 4.58831 4.58831 1.41699 8.50033 1.41699C12.4123 1.41699 15.5837 4.58831 15.5837 8.50033Z"
                                            stroke="#B0B0BB"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1075_2463">
                                            <rect
                                                width="17"
                                                height="17"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </h1>
                    </div>
                    <div className="right d-flex">
                        <div className="me-2">
                            <Link
                                to="/create-alert"
                                className="btn btn-sm btn-primary rounded-12"
                            >
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="me-1"
                                >
                                    <path
                                        d="M10.5003 4.16699V15.8337M4.66699 10.0003H16.3337"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Create Alert
                            </Link>
                        </div>
                        <div className="input-wrapper me-2">
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control fw-semibold form-control-sm bg-light"
                            />
                            <svg
                                width="20"
                                className="absolute--right"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.5 18L14.5834 15.0833M16.6667 10.0833C16.6667 13.9954 13.4954 17.1667 9.58333 17.1667C5.67132 17.1667 2.5 13.9954 2.5 10.0833C2.5 6.17132 5.67132 3 9.58333 3C13.4954 3 16.6667 6.17132 16.6667 10.0833Z"
                                    stroke="#C2C0C9"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                        <Button
                            color="secondary"
                            size="sm"
                            className="notification--btn px-2 ms-2"
                        >
                            <svg
                                className="btn-icon"
                                width="20"
                                height="23"
                                viewBox="0 0 20 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.35395 20.5C8.05908 21.1224 8.98533 21.5 9.99977 21.5C11.0142 21.5 11.9405 21.1224 12.6456 20.5M15.9998 7.5C15.9998 5.9087 15.3676 4.38258 14.2424 3.25736C13.1172 2.13214 11.5911 1.5 9.99977 1.5C8.40848 1.5 6.88235 2.13214 5.75713 3.25736C4.63192 4.38258 3.99977 5.9087 3.99977 7.5C3.99977 10.5902 3.22024 12.706 2.34944 14.1054C1.6149 15.2859 1.24763 15.8761 1.2611 16.0408C1.27601 16.2231 1.31463 16.2926 1.46155 16.4016C1.59423 16.5 2.19237 16.5 3.38863 16.5H16.6109C17.8072 16.5 18.4053 16.5 18.538 16.4016C18.6849 16.2926 18.7235 16.2231 18.7384 16.0408C18.7519 15.8761 18.3846 15.2859 17.6501 14.1054C16.7793 12.706 15.9998 10.5902 15.9998 7.5Z"
                                    stroke="#292934"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="badge badge-danger">03</span>
                        </Button>
                    </div>
                </div>
            </header>
            {/* <div> */}
            {alertsData.length ? (
                <div className="content">
                    <div className="content-inner">
                        <div className="container">
                            <div className="recent-heatmapes-list">
                                <div className="alert-box-container">
                                    {alertsData.map((alert, index) => (
                                        <div className="alert-box">
                                            <div class="alert-box-row">
                                                <div class="alert-box-first-row">
                                                    <div class="text-wrapper">
                                                        Alert type
                                                    </div>
                                                    <img
                                                        class="dots-vertical"
                                                        src={DotImage}
                                                        onClick={() =>
                                                            handleMenuToggle(
                                                                alert.id
                                                            )
                                                        }
                                                    />
                                                    {activePopoverId ===
                                                        alert.id && (
                                                        <PopoverMenu
                                                            onDelete={() =>
                                                                handleDelete(
                                                                    alert.id
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </div>
                                                <div class="div-wrapper">
                                                    <div class="text-wrapper-2">
                                                        {
                                                            alert.performance_threshold
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="alert-box-row">
                                                <div class="text-wrapper">
                                                    More then
                                                </div>
                                                <div class="div-wrapper">
                                                    <div class="text-wrapper-2">
                                                        {alert.threshold_value}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="alert-box-row">
                                                <div class="text-wrapper">
                                                    Target page
                                                </div>
                                                <div class="div-wrapper">
                                                    <div class="text-wrapper-2">
                                                        {shortenUrl(websiteURL)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="content w-100 d-flex align-items-center justify-content-center">
                    <div className="content-inner">
                        <div className="alert-box--wrapper text-center w-400 mx-auto">
                            <svg
                                width="68"
                                height="68"
                                viewBox="0 0 68 68"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    class="pulse-big"
                                    opacity="0.04"
                                    cx="34"
                                    cy="34"
                                    r="34"
                                    fill="#7B3FE4"
                                />
                                <circle
                                    class="pulse-small"
                                    opacity="0.1"
                                    cx="34"
                                    cy="34"
                                    r="24"
                                    fill="#7B3FE4"
                                />
                                <path
                                    d="M31.354 43C32.0591 43.6224 32.9853 44 33.9998 44C35.0142 44 35.9405 43.6224 36.6456 43M39.9998 30V24M36.9998 27H42.9998M34.9998 24.0839C34.6715 24.0284 34.3371 24 33.9998 24C32.4085 24 30.8824 24.6321 29.7571 25.7574C28.6319 26.8826 27.9998 28.4087 27.9998 30C27.9998 33.0902 27.2202 35.206 26.3494 36.6054C25.6149 37.7859 25.2476 38.3761 25.2611 38.5408C25.276 38.7231 25.3146 38.7926 25.4616 38.9016C25.5942 39 26.1924 39 27.3886 39H40.6109C41.8071 39 42.4052 39 42.5379 38.9016C42.6848 38.7926 42.7235 38.7231 42.7384 38.5408C42.7518 38.3761 42.3845 37.7858 41.65 36.6052C41.1579 35.8144 40.695 34.7948 40.3855 33.5"
                                    stroke="#7B3FE4"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <h2 className="h5 mt-3 pt-2">
                                Setup your custom alerts
                            </h2>
                            <p className="mt-2 mb-4 text-muted">
                                Never miss any milestones of your website even
                                when you’re not on you computer.
                            </p>
                            {/* <a
                            href="/create-alert"
                            className="btn btn-sm btn-primary rounded-12"
                        > */}
                            <Link
                                to="/create-alert"
                                className="btn btn-sm btn-primary rounded-12"
                            >
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="me-1"
                                >
                                    <path
                                        d="M10.5003 4.16699V15.8337M4.66699 10.0003H16.3337"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Create Alert
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            {/* </div> */}
        </div>
    );
}

export default Alerts;
