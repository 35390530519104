import React, { useState } from "react";
import { Table, Tooltip } from "antd";
import { PlayCircleFilled, UpOutlined, DownOutlined } from "@ant-design/icons";
import {
    ErrorCriticalIcon,
    ErrorHighIcon,
    ErrorLowIcon,
    ErrorMediumIcon,
    PlayIcon,
} from "../../../Assets/icons";
import styled from "styled-components";

function ErrorListData(props) {
    const { errorlist, onListItemClick } = props;
    function determineErrorSeverity(error) {
        if (error.error_type === "network") {
            if (error.error_data.includes("Uncaught ReferenceError")) {
                return <ErrorCriticalIcon />;
            }
            return <ErrorHighIcon />;
        }

        switch (error.error_type) {
            case "network":
                return <ErrorHighIcon />;
            case "console":
                if (error.error_data.includes("Uncaught")) {
                    return <ErrorHighIcon />;
                } else if (error.error_data.includes("Warning:")) {
                    return <ErrorMediumIcon />;
                }
                return <ErrorLowIcon />;
            default:
                return <ErrorLowIcon />;
        }
    }

    const ExpandedContent = styled.div`
        min-height: 200px; // Adjust this value as needed
    `;

    const columns = [
        {
            title: "Severity",
            dataIndex: "id",
            key: "id",
            render: (text, record, index) => (
                <span className="text-lightgray position-relative d-inline-block me-2">
                    {determineErrorSeverity(record)}
                </span>
            ),
            width: "5%",
        },
        {
            title: "Play",
            dataIndex: "id",
            key: "id",
            render: (text, record, index) => (
                <span
                    className="text-lightgray position-relative d-inline-block me-2"
                    onClick={() => onListItemClick(record.error_time)}
                    style={{ cursor: "pointer" }}
                >
                    <PlayIcon />
                </span>
            ),
            width: "5%",
        },
        {
            title: "Occurred at",
            dataIndex: "error_time",
            key: "error_time",
            render: (text) => <span style={{ cursor: "pointer" }}>{text}</span>,
            width: "20%",
        },
        {
            title: "Session ID",
            dataIndex: "session_id",
            key: "session_id",
            render: (text, record) => (
                <Tooltip title={text}>
                    <span style={{ cursor: "pointer" }}>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: "Error Type",
            dataIndex: "error_type",
            key: "error_type",
            render: (text) => <span style={{ cursor: "pointer" }}>{text}</span>,
            width: "10%",
        },
    ];

    const CodeBlock = styled.pre`
        background-color: #f4f4f4;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 10px;
        white-space: pre-wrap;
        word-wrap: break-word;
    `;

    const expandedRowRender = (record) => {
        let formattedData;
        try {
            // Attempt to parse the JSON string
            const parsedData = JSON.parse(record.error_data);
            formattedData = JSON.stringify(parsedData, null, 2); // Pretty print with 2 spaces
        } catch (e) {
            // If parsing fails, use the original string
            formattedData = record.error_data;
        }

        return (
            <ExpandedContent>
                <CodeBlock>
                    <code>{formattedData}</code>
                </CodeBlock>
            </ExpandedContent>
        );
    };

    return (
        <Table
            className="table remove-last-border mb-0 hover-bg-change stable-table"
            dataSource={errorlist && errorlist?.details}
            columns={columns}
            rowKey="id"
            pagination={false}
            expandable={{
                expandedRowRender,
                rowExpandable: (record) => !!record.error_data,
            }}
        />
    );
}

export default ErrorListData;
