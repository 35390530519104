// import React from "react";
export default function InputField(props) {
	let inputContent;
	if (props.type === "password") {
		inputContent = (
			<>
				<div className="input-password-group">
					<input
						type={props.type}
						className={`form-control ${props.inputClass}`}
						placeholder={props.placeholder}
						name={props.name}
						{...props.register}
					/>
				</div>
				<span className="h8 fw-400 form-control-instruction text-lightgray">
					{props.instruction}
				</span>
			</>
		);
	} else {
		inputContent = (
			<input
				type={props.type}
				className={`form-control ${props.inputClass || ""}`}
				placeholder={props.placeholder}
				name={props.name}
				{...props.register}
			/>
		);
	}
	return (
		<div className="form-group">
			<label className={`label h7 mb-2 fw-400 ${props.labelClass}`}>
				{props.label}
			</label>
			{inputContent}
		</div>
	);
}
