import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getHeatMapData,
    getClickedHeatMapData,
    getHeatMapPageData,
} from "../../services/api";

export const getHeatMapMouseData = createAsyncThunk(
    "heatmap/getHeatMapMouseData",
    async (heatmapURL, thunkAPI) => {
        try {
            const response = await getHeatMapData(heatmapURL);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getHeatMapClickData = createAsyncThunk(
    "heatmap/getHeatMapClickData",
    async (heatmapURL, thunkAPI) => {
        try {
            const response = await getClickedHeatMapData(heatmapURL);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getHeatMapPageDataSlice = createAsyncThunk(
    "heatmap/getHeatMapPageDataSlice",
    async (heatmapURL, thunkAPI) => {
        try {
            const response = await getHeatMapPageData(heatmapURL);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const heatmapSlice = createSlice({
    name: "heatmap",
    initialState: {
        heatmapData: null,
        error: null,
        clickedHeatmapData: null,
        heatmapPageData: null,
    },
    reducers: {},
    extraReducers: {
        [getHeatMapMouseData.pending]: (state) => {
            state.status = "loading";
        },
        [getHeatMapMouseData.fulfilled]: (state, { payload }) => {
            state.heatmapData = payload.details;
            state.status = "fulfilled";
        },
        [getHeatMapMouseData.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
        [getHeatMapClickData.pending]: (state) => {
            state.status = "loading";
        },
        [getHeatMapClickData.fulfilled]: (state, { payload }) => {
            state.clickedHeatmapData = payload.details;
            state.status = "fulfilled";
        },
        [getHeatMapClickData.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
        [getHeatMapPageDataSlice.pending]: (state) => {
            state.status = "loading";
        },
        [getHeatMapPageDataSlice.fulfilled]: (state, { payload }) => {
            state.heatmapPageData = payload;
            state.status = "fulfilled";
        },
        [getHeatMapPageDataSlice.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
    },
});

export const heatmapReducer = heatmapSlice.reducer;
