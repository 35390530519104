import React, { useState } from "react";
import { Button, Form } from "reactstrap";
import { useSelector } from "react-redux";
import { verifyInstallationAPI } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import docco from "react-syntax-highlighter/dist/esm/styles/prism/one-light";
import toast, { Toaster } from "react-hot-toast";

export default function SignUpStep1() {
    let script = useSelector((state) => state.company.script);
    const uniqueIdResponse = useSelector((state) => state.company.uniqueId);
    const [isVerifying, setIsVerifying] = useState(false);
    const navigate = useNavigate();
    const notify = (type) => {
        if (type == "success") {
            return toast.success("Script is successfully installed");
        } else {
            return toast.error("Script is not installed.");
        }
    };

    const verifyInstallation = async () => {
        setIsVerifying(true);

        const verificationPromise = new Promise(async (resolve, reject) => {
            try {
                const uniqueId = uniqueIdResponse;
                const response = await verifyInstallationAPI(uniqueId);
                if (response.status === 200) {
                    setTimeout(() => {
                        navigate("/login");
                    }, 3000);
                    setIsVerifying(false);
                    resolve("Settings saved!");
                } else {
                    setIsVerifying(false);
                    reject("Could not save.");
                }
            } catch (error) {
                setIsVerifying(false);
                reject("Could not save.");
            }
        });

        toast.promise(verificationPromise, {
            loading: "Running check...",
            success: "Script is successfully installed",
            error: "Script is not installed.",
        });

        // // Await the result of the promise and set the state accordingly
        // try {
        //     await verificationPromise;
        //     notify("success");
        // } catch (error) {
        //     notify("error");
        // } finally {
        //     setIsVerifying(false);
        // }
    };

    // const verifyInstallation = async () => {
    //     setIsVerifying(true);
    //     try {
    //         const uniqueId = uniqueIdResponse;
    //         const response = await verifyInstallationAPI(uniqueId);
    //         if (response.status === 200) {
    //             notify("success");
    //             setIsVerifying(false);
    //             setTimeout(() => {
    //                 // navigate("/login");
    //                 console.log("Redirecting ...");
    //             }, 3000);
    //         } else {
    //             notify("error");
    //         }
    //     } catch (error) {
    //         notify("error");
    //     } finally {
    //         setIsVerifying(false);
    //     }
    // };

    return (
        <Form className="mx-w400 mx-auto  code-integration">
            <h1 className="h2 mb-2">Code Intigration</h1>
            <p className="text-muted mb-5">
                Install following code to track your site
            </p>
            <pre className="border rounded-3 px-2 pb-2 mb-1">
                <SyntaxHighlighter language="javascript" style={docco}>
                    {script}
                </SyntaxHighlighter>
            </pre>
            <p className="text-lightgray h8 fw-400">
                Click on text area to copy code
            </p>
            <div className="form-group d-flex">
                <Button color="light" block disabled className="me-2">
                    Back
                </Button>
                <Button
                    block
                    onClick={verifyInstallation}
                    disabled={isVerifying}
                    color="primary"
                    className="ms-2"
                >
                    Verify Installation
                </Button>
            </div>
            <div className="or-else-line-wrapper form-group">
                <span className="line--item"></span>
                <span className="text--item text-muted fw-400 h7 mx-1">
                    Or else
                </span>
                <span className="line--item"></span>
            </div>
            <div className="form-group text-center">
                <p className="text-muted">
                    Don’t have an account?{" "}
                    <a href="#!" className="text-primary h7 fw-400">
                        Login
                    </a>
                </p>
            </div>
            <Toaster position="bottom-center" reverseOrder={false} />
        </Form>
    );
}
