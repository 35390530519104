import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    submitCompanyDetails,
    getMouseData,
    getFullScreenShort,
} from "../../services/api";

export const postCompanyDetails = createAsyncThunk(
    "company/postCompanyDetails",
    async (companyData, thunkAPI) => {
        try {
            const response = await submitCompanyDetails(companyData);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const fetchMouseData = createAsyncThunk(
    "company/fetchMouseData",
    async (url, thunkAPI) => {
        try {
            const response = await getMouseData(url);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const fetchFullScreenShort = createAsyncThunk(
    "company/fetchFullScreenShort",
    async (url, thunkAPI) => {
        try {
            const response = await getFullScreenShort(url);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const companySlice = createSlice({
    name: "company",
    initialState: {
        companyData: null,
        error: null,
        fullPageScreenShort: null,
        companyResponse: null,
    },
    reducers: {},
    extraReducers: {
        [postCompanyDetails.pending]: (state) => {
            state.status = "loading";
        },
        [postCompanyDetails.fulfilled]: (state, { payload }) => {
            state.companyData = payload.details;
            state.script = payload.script;
            state.uniqueId = payload.uniqueId;
            state.status = "fulfilled";
            state.companyResponse = payload;
        },
        [postCompanyDetails.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
        [fetchMouseData.pending]: (state) => {
            state.status = "loading";
        },
        [fetchMouseData.fulfilled]: (state, { payload }) => {
            state.mouseData = payload;
            state.status = "fulfilled";
        },
        [fetchMouseData.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
        [fetchFullScreenShort.pending]: (state) => {
            state.status = "loading";
        },
        [fetchFullScreenShort.fulfilled]: (state, { payload }) => {
            state.mouseData = payload;
            state.status = "fulfilled";
        },
        [fetchFullScreenShort.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
    },
});

export const companyReducer = companySlice.reducer;
