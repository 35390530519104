import { getCode } from "country-list";

export default function getCountryFlag(countryName) {
    if (typeof countryName !== "undefined") {
        if (countryName == "United States")
            countryName = "United States of America";

        const code = getCode(countryName);
        if (typeof code !== "undefined" && code !== null) {
            if (countryName === "United Kingdom") {
                return `https://flagcdn.com/w320/gb.png`;
            }
            return `https://flagcdn.com/w320/${code.toLowerCase()}.png`;
        } else {
            console.error(
                `Invalid country code for country name: ${countryName}`
            );
            return null;
        }
    } else {
        console.error("countryName is undefined");
        return null;
    }
}

export function convertMilliseconds(ms) {
    let seconds = ms / 1000;
    if (seconds < 60) {
        return seconds.toFixed(2) + " sec";
    } else {
        let minutes = seconds / 60;
        if (minutes < 60) {
            return minutes.toFixed(2) + " min";
        } else {
            let hours = minutes / 60;
            return hours.toFixed(2) + " hr";
        }
    }
}

export const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + "...";
};

export const getMaxTransferSize = (list) => {
    if (!Array.isArray(list) || list.length === 0) {
        return 0;
    }

    return list.reduce((max, item) => {
        const size = parseInt(
            item.transfersize ? item.transfersize : item.duration
        );
        return isNaN(size) ? max : Math.max(max, size);
    }, 0);
};

export const normalizeTransferSize = (size, maxTransferSize) => {
    if (!maxTransferSize) return 0;
    const parsedSize = parseInt(size);
    return isNaN(parsedSize) ? 0 : (parsedSize / maxTransferSize) * 100;
};

export const extractFileName = (url) => {
    const urlWithoutParams = url.split("?")[0];
    const fullFileName = urlWithoutParams.split("/").pop();
    if (!fullFileName.includes(".")) return fullFileName;
    return fullFileName;
};

export const truncateUrl = (url) => {
    const maxLength = 20;
    if (url.length <= maxLength) return url;
    return url.substr(0, maxLength - 3) + "...";
};
