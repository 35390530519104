import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    AreaChart,
    Area,
    ResponsiveContainer,
} from "recharts";
import { getBounceRateChartData } from "../../redux/dashboard/dashboardSlice";
import { BounceRateTooltip } from "./Tooltip";

export default function LineChartComponent(props) {
    let userId = props.data.user.uniqueid;
    if (userId == "00000000-0000-0000-0000-000000000000") {
        userId = Math.random(10);
    }
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBounceRateChartData(userId));
    }, []);

    const { bounceRateChartData, status, error } = useSelector(
        (state) => state.dashboard
    );

    function formatDate(dateString) {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        const dateObj = new Date(dateString);
        const month = months[dateObj.getUTCMonth()];
        const day = dateObj.getUTCDate();
        return `${month} ${day}`;
    }

    const formattedData = bounceRateChartData?.map((entry) => ({
        date: formatDate(entry.date),
        bounceRate: entry.bounceRate,
        engagementRate: entry.engagementRate,
    }));

    // Bounce rate is (singlepagesession / totalsession) * 100
    // Engagement rate is ((totalsession - singlepagesession)/totalsession) * 100

    return (
        <ResponsiveContainer width="100%" height={200}>
            <AreaChart
                width={1100}
                height={200}
                data={formattedData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
                <defs>
                    <linearGradient
                        id="bounceColor"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                    >
                        <stop
                            offset="5%"
                            stopColor="#FF851B"
                            stopOpacity={0.2}
                        />
                        <stop
                            offset="95%"
                            stopColor="#FF851B"
                            stopOpacity={0}
                        />
                    </linearGradient>
                    <linearGradient
                        id="engageColor"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                    >
                        <stop
                            offset="5%"
                            stopColor="#7b3fe4"
                            stopOpacity={0.2}
                        />
                        <stop
                            offset="95%"
                            stopColor="#7b3fe4"
                            stopOpacity={0}
                        />
                    </linearGradient>
                </defs>
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip
                    wrapperStyle={{ outline: "none" }}
                    content={<BounceRateTooltip />}
                />
                <Area
                    strokeWidth={2}
                    type="monotone"
                    dataKey="bounceRate"
                    stroke="#FF851B"
                    fillOpacity={1}
                    fill="url(#bounceColor)"
                />
                {/* <Area
                    strokeWidth={2}
                    type="monotone"
                    dataKey="engagementRate"
                    stroke="#7b3fe4"
                    fillOpacity={1}
                    fill="url(#engageColor)"
                /> */}
            </AreaChart>
        </ResponsiveContainer>
    );
}
