import React from "react";
import { Route, Routes } from "react-router-dom";

import PublicSidebar from "../Common/PublicSidebar";
import { Navigate, useNavigate } from "react-router-dom";
const PublicRoutes = ({ step }) => {
    console.log("step", step);
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route
                path="/login"
                element={<PublicSidebar routeName="login" />}
            />
            <Route
                path="/forgotpassword"
                element={<PublicSidebar routeName="forgotpassword" />}
            />
            <Route
                path="/signup"
                element={<PublicSidebar routeName="signup" />}
            />
            <Route
                path="/companydetails"
                element={
                    step >= 1 ? (
                        <PublicSidebar routeName="companydetails" />
                    ) : (
                        <Navigate to="/signup" />
                    )
                }
            />
            <Route
                path="/codeintegration"
                element={
                    step >= 1 ? (
                        <PublicSidebar routeName="codeintegration" />
                    ) : (
                        // <Navigate to="/signup" />
                        <PublicSidebar routeName="codeintegration" />
                    )
                }
            />
        </Routes>
    );
};

export default PublicRoutes;
