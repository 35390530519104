import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import { getTopLinkCount } from "../../../redux/dashboard/dashboardSlice";
import { Link } from "react-router-dom";
import { ThreeDotsIcon } from "../../../Assets/icons";

function TopPages(props) {
    const dispatch = useDispatch();

    const { topLinkCount, status, error } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        if (props.userId) {
            dispatch(getTopLinkCount(props.userId));
        }
    }, [dispatch]);

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    };

    const topPagesObj = topLinkCount?.data.map((obj) => {
        let name = obj.page.split("/").filter(Boolean).pop();
        if (!name) {
            name = "Home";
        } else {
            name = capitalizeFirstLetter(name);
        }

        let page = obj.page;
        if (page.length > 20) {
            try {
                const url = new URL(page);
                let shortUrl = url.hostname + url.pathname;
                if (shortUrl.length > 10) {
                    shortUrl =
                        shortUrl.substring(0, 5) +
                        "..." +
                        shortUrl.substring(
                            shortUrl.length - 5,
                            shortUrl.length
                        );
                }
                page = shortUrl;
            } catch (e) {
                console.error("Invalid URL:", page, e);
            }
        }
        return {
            ...obj,
            page,
            name,
        };
    });

    let maxClicks;
    if (topPagesObj) {
        maxClicks = Math.max(
            ...topPagesObj.map((item) => Number(item.total_count + 1))
        );
    } else {
        return <div>Loading...</div>;
    }

    return (
        <Col md="6">
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h4 className="dash-card-title mb-0 h6">Top Pages</h4>
                        <a href="#!">
                            <ThreeDotsIcon />
                        </a>
                    </div>
                </div>
                <div className="card-body scroll--height pt-0 top-click-section">
                    {topPagesObj.map((item, index) => {
                        const progressPercentage =
                            (item.total_count / maxClicks) * 100;
                        return (
                            <div key={index} className="progress-item">
                                <div className="progress-label">
                                    <span className="left">
                                        {item.name}
                                        <a href={item.page} className="link">
                                            {item.page}
                                        </a>
                                    </span>
                                    <div className="right">
                                        <Link
                                            to={`/heatmapdetails/${
                                                item.id
                                            }/${encodeURIComponent(
                                                item.full_page_url
                                            )}`}
                                            className="text-gray h7 d-inline-block mt-1"
                                        >
                                            {item.total_count} visitors
                                        </Link>
                                    </div>
                                </div>
                                <div className="progress">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            width: `${progressPercentage}%`,
                                        }}
                                        aria-valuenow={progressPercentage}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Col>
    );
}

export default TopPages;
