import React from "react";
import {
    BrowserEdgeIcon,
    BrowserGoogleIcon,
    BrowserIEIcon,
    BrowserMozillaIcon,
    BrowserSafariIcon,
    BrowserYandexIcon,
} from "../../../Assets/icons";

const BrowserIcon = ({ browser }) => {
    return (
        <span className="browser-item">
            {browser === "Safari" || browser === "Mobile Safari" ? (
                <BrowserSafariIcon />
            ) : browser === "Chrome" ? (
                <BrowserGoogleIcon />
            ) : browser === "Firefox" ? (
                <BrowserMozillaIcon />
            ) : browser === "Edge" ? (
                <BrowserEdgeIcon />
            ) : browser === "Opera" ? (
                <BrowserGoogleIcon />
            ) : browser === "IE" || browser === "Samsung Internet" ? (
                <BrowserIEIcon />
            ) : browser === "Yandex Browser" ? (
                <BrowserYandexIcon />
            ) : (
                <BrowserGoogleIcon />
            )}
        </span>
    );
};

export default BrowserIcon;
