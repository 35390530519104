import React from "react";
import {
    OSAndroidIcon,
    OSBlackBerryIcon,
    OSChromeIcon,
    OSLinuxIcon,
    OSMacIcon,
    OSWindowsIcon,
    OSiOSIcon,
} from "../../../Assets/icons";

const OsIcon = ({ os }) => {
    return (
        <span className="browser-item">
            {os === "Mac OS X" ? (
                <OSMacIcon />
            ) : os === "iOS" ? (
                <OSiOSIcon />
            ) : os === "Windows" ? (
                <OSWindowsIcon />
            ) : os === "Android" ? (
                <OSAndroidIcon />
            ) : os === "Linux" ? (
                <OSLinuxIcon />
            ) : os === "Chrome OS" ? (
                <OSChromeIcon />
            ) : os === "Windows Phone" ? (
                <OSWindowsIcon />
            ) : os === "BlackBerry" ? (
                <OSBlackBerryIcon />
            ) : os === "Other" ? (
                <OSMacIcon />
            ) : (
                <OSiOSIcon />
            )}
        </span>
    );
};

export default OsIcon;
