export function JSScript(props) {
    return `
    <script>
            ;(function (w, d, u, i) {
                w.userId = w.userId || i
                w.websiteUrl = w.websiteUrl || u
                var scriptUrl =
                'https://riddleretro.com/clientsideRoutes/scripts/' +
                w.userId +
                '/' +
                encodeURIComponent(w.websiteUrl)
                var a = d.getElementsByTagName('head')[0]
                var r = d.createElement('script')
                r.async = 1
                r.src = scriptUrl
                a.appendChild(r)
            })(
                window,
                document,
                '${props.website_url}',
                '${props.uniqueId}'
            )
            </script>
        `;
}

export function NextJSScript(props) {
    return `
        <script
            dangerouslySetInnerHTML={{
                        __html: (function (w, d, u, i) {
        w.userId = w.userId || i;
        w.websiteUrl = w.websiteUrl || u;
        var scriptUrl =
            "https://riddleretro.com/clientsideRoutes/scripts/" +
            w.userId +
            "/" +
            encodeURIComponent(w.websiteUrl);
        var a = d.getElementsByTagName("head")[0];
        var r = d.createElement("script");
        r.async = 1;
        r.src = scriptUrl;
        a.appendChild(r);
    })(
        window,
        document,
        '${props.website_url}',
        '${props.uniqueId}'
    );
}}
/>`;
}
