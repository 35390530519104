import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import {
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import ClickHeatmap from "./Heatmap/ClickHeatmap";
import MouseMoveHeatmap from "./Heatmap/MouseMoveHeatmap";
import Heatmap from "./Heatmap/NewHeatmap";
import ScrollHeatmap from "./Heatmap/ScrollHeatmap";
import { useParams } from "react-router-dom";
import { getHeatMapPageDataSlice } from "../../redux/heatmap/heatmapSlice";
import { useDispatch, useSelector } from "react-redux";
const initialState = {
    heatmapPageData: {},
};

function HeatmapDetails(props) {
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const [activeIndex, setActiveIndex] = useState(1);

    const buttons = ["Click", "Move", "Scroll"];

    const { pagename, pageurl } = useParams();
    const [state, setState] = useState(initialState);

    // console.log({ pagename, pageurl });

    const { heatmapPageData, status, error } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        const fetchData = async () => {
            const data = await dispatch(getHeatMapPageDataSlice(pageurl));
            setState(data.payload.details);
        };

        fetchData();
    }, []);

    const [progress, setProgress] = useState(75);
    const progressBarRef = useRef(null);

    const handleMouseDown = (event) => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseMove = (event) => {
        const rect = progressBarRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left; // x position within the element
        const width = rect.width;
        const newProgress = (x / width) * 100;
        setProgress(Math.min(Math.max(newProgress, 0), 100));
    };

    const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    const getFilteredData = (range) => {
        // let args = {
        //     userId,
        //     range,
        // };
        // dispatch(getTotalSessionDateFilter(args));
    };

    return (
        <div className="main--wrapper">
            <header className="header--wrapper">
                <div className="header--inner container">
                    <div className="left">
                        <h1 className="h4 mb-0 d-flex align-items-center">
                            <Link
                                to={`/heatmap`}
                                className="text-gray h7 d-inline-block mt-1"
                            >
                                <svg
                                    width="24"
                                    className="me-2"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 12.5H5M5 12.5L12 19.5M5 12.5L12 5.5"
                                        stroke="#B0B0BB"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </Link>
                            Heatmap Detail
                        </h1>
                    </div>
                    <div className="right d-flex">
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle className="btn-sm d-flex align-items-center">
                                <svg
                                    className="dropdown-left-icon"
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.5 7.83366H1.5M12.3333 1.16699V4.50033M5.66667 1.16699V4.50033M5.5 17.8337H12.5C13.9001 17.8337 14.6002 17.8337 15.135 17.5612C15.6054 17.3215 15.9878 16.939 16.2275 16.4686C16.5 15.9339 16.5 15.2338 16.5 13.8337V6.83366C16.5 5.43353 16.5 4.73346 16.2275 4.19868C15.9878 3.72828 15.6054 3.34583 15.135 3.10614C14.6002 2.83366 13.9001 2.83366 12.5 2.83366H5.5C4.09987 2.83366 3.3998 2.83366 2.86502 3.10614C2.39462 3.34583 2.01217 3.72828 1.77248 4.19868C1.5 4.73346 1.5 5.43353 1.5 6.83366V13.8337C1.5 15.2338 1.5 15.9339 1.77248 16.4686C2.01217 16.939 2.39462 17.3215 2.86502 17.5612C3.3998 17.8337 4.09987 17.8337 5.5 17.8337Z"
                                        stroke="#74747F"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                12 Months
                                <svg
                                    className="dropdown-right-icon"
                                    width="10"
                                    height="7"
                                    viewBox="0 0 10 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L5 5.5L9 1.5"
                                        stroke="#74747F"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>3 Months</DropdownItem>
                                <DropdownItem>6 Months</DropdownItem>
                                <DropdownItem>9 Months</DropdownItem>
                                <DropdownItem>12 Months</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Button
                            color="secondary"
                            size="sm"
                            className="notification--btn px-2 ms-2"
                        >
                            <svg
                                className="btn-icon"
                                width="20"
                                height="23"
                                viewBox="0 0 20 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.35395 20.5C8.05908 21.1224 8.98533 21.5 9.99977 21.5C11.0142 21.5 11.9405 21.1224 12.6456 20.5M15.9998 7.5C15.9998 5.9087 15.3676 4.38258 14.2424 3.25736C13.1172 2.13214 11.5911 1.5 9.99977 1.5C8.40848 1.5 6.88235 2.13214 5.75713 3.25736C4.63192 4.38258 3.99977 5.9087 3.99977 7.5C3.99977 10.5902 3.22024 12.706 2.34944 14.1054C1.6149 15.2859 1.24763 15.8761 1.2611 16.0408C1.27601 16.2231 1.31463 16.2926 1.46155 16.4016C1.59423 16.5 2.19237 16.5 3.38863 16.5H16.6109C17.8072 16.5 18.4053 16.5 18.538 16.4016C18.6849 16.2926 18.7235 16.2231 18.7384 16.0408C18.7519 15.8761 18.3846 15.2859 17.6501 14.1054C16.7793 12.706 15.9998 10.5902 15.9998 7.5Z"
                                    stroke="#292934"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="badge badge-danger">03</span>
                        </Button>
                    </div>
                </div>
            </header>
            <div className="content">
                <div className="content-inner">
                    <div className="container">
                        <div className="recent-heatmapes-list">
                            <Row className="g-3">
                                <Col lg="70" md="6">
                                    <div className="card">
                                        <div className="card-header p-0 border-bottom border-light d-flex align-items-center">
                                            <ul className="nav border-0 nav-tabs d-inline-flex">
                                                <li className="nav-item">
                                                    <a className="active nav-link">
                                                        <svg
                                                            className="me-2"
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <g clipPath="url(#clip0_1005_7529)">
                                                                <path
                                                                    d="M8.50033 15.5837C12.4123 15.5837 15.5837 12.4123 15.5837 8.50033C15.5837 4.58831 12.4123 1.41699 8.50033 1.41699C4.58831 1.41699 1.41699 4.58831 1.41699 8.50033C1.41699 12.4123 4.58831 15.5837 8.50033 15.5837Z"
                                                                    stroke="#B0B0BB"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M8.50033 12.7503C10.8475 12.7503 12.7503 10.8475 12.7503 8.50033C12.7503 6.15312 10.8475 4.25033 8.50033 4.25033C6.15312 4.25033 4.25033 6.15312 4.25033 8.50033C4.25033 10.8475 6.15312 12.7503 8.50033 12.7503Z"
                                                                    stroke="#B0B0BB"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M8.50033 9.91699C9.28273 9.91699 9.91699 9.28273 9.91699 8.50033C9.91699 7.71792 9.28273 7.08366 8.50033 7.08366C7.71792 7.08366 7.08366 7.71792 7.08366 8.50033C7.08366 9.28273 7.71792 9.91699 8.50033 9.91699Z"
                                                                    stroke="#B0B0BB"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1005_7529">
                                                                    <rect
                                                                        width="17"
                                                                        height="17"
                                                                        fill="white"
                                                                    />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        Show highlights
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link">
                                                        <svg
                                                            className="me-2"
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.54199 3.53416C3.54199 2.84625 3.54199 2.5023 3.68542 2.31269C3.81038 2.14752 4.00136 2.04531 4.20811 2.03296C4.44543 2.01879 4.73161 2.20958 5.30399 2.59117L12.7525 7.55685C13.2255 7.87214 13.4619 8.02979 13.5443 8.2285C13.6164 8.40222 13.6164 8.59746 13.5443 8.77118C13.4619 8.96988 13.2255 9.12753 12.7525 9.44283L5.30398 14.4085C4.73161 14.7901 4.44543 14.9809 4.20811 14.9667C4.00136 14.9544 3.81038 14.8522 3.68542 14.687C3.54199 14.4974 3.54199 14.1534 3.54199 13.4655V3.53416Z"
                                                                stroke="#B0B0BB"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        View recording
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link">
                                                        <svg
                                                            className="me-2"
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M14.875 10.625V11.475C14.875 12.6651 14.875 13.2602 14.6434 13.7147C14.4397 14.1146 14.1146 14.4397 13.7147 14.6434C13.2602 14.875 12.6651 14.875 11.475 14.875H5.525C4.33489 14.875 3.73983 14.875 3.28527 14.6434C2.88543 14.4397 2.56034 14.1146 2.35661 13.7147C2.125 13.2602 2.125 12.6651 2.125 11.475V10.625M12.0417 7.08333L8.5 10.625M8.5 10.625L4.95833 7.08333M8.5 10.625V2.125"
                                                                stroke="#B0B0BB"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Download
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="ms-auto d-inline-flex pe-3">
                                                <Link
                                                    to={{
                                                        pathname: `/fullscreenheatmap`,
                                                        search: `?pagename=${pagename}&pageurl=${pageurl}`,
                                                    }}
                                                    className="text-gray h7 d-inline-block mt-1"
                                                >
                                                    <svg
                                                        width="17"
                                                        height="17"
                                                        viewBox="0 0 17 17"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M14.875 6.37501L14.875 2.12501M14.875 2.12501H10.625M14.875 2.12501L8.5 8.5M7.08333 2.125H5.525C4.33489 2.125 3.73983 2.125 3.28527 2.35661C2.88543 2.56034 2.56034 2.88543 2.35661 3.28527C2.125 3.73983 2.125 4.33489 2.125 5.525V11.475C2.125 12.6651 2.125 13.2602 2.35661 13.7147C2.56034 14.1146 2.88543 14.4397 3.28527 14.6434C3.73983 14.875 4.33489 14.875 5.525 14.875H11.475C12.6651 14.875 13.2602 14.875 13.7147 14.6434C14.1146 14.4397 14.4397 14.1146 14.6434 13.7147C14.875 13.2602 14.875 12.6651 14.875 11.475V9.91667"
                                                            stroke="#B0B0BB"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </Link>
                                                <a
                                                    href="#!"
                                                    className="action-item d-inline-flex ms-2 p-2"
                                                >
                                                    <svg
                                                        width="17"
                                                        height="17"
                                                        viewBox="0 0 17 17"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M1.41699 9.91667C1.41699 9.91667 1.50293 10.5182 3.99252 13.0078C6.48211 15.4974 10.5185 15.4974 13.0081 13.0078C13.8902 12.1257 14.4598 11.0495 14.7168 9.91667M1.41699 9.91667V14.1667M1.41699 9.91667H5.66699M15.5837 7.08333C15.5837 7.08333 15.4977 6.48179 13.0081 3.99219C10.5185 1.5026 6.48211 1.5026 3.99252 3.99219C3.11045 4.87426 2.5409 5.95051 2.28387 7.08333M15.5837 7.08333V2.83333M15.5837 7.08333H11.3337"
                                                            stroke="#B0B0BB"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-body d-flex">
                                            {activeIndex == 0 ? (
                                                <ClickHeatmap
                                                    dynamicWidth={"860px"}
                                                />
                                            ) : activeIndex == 1 ? (
                                                <MouseMoveHeatmap
                                                    dynamicWidth={"860px"}
                                                />
                                            ) : (
                                                <ScrollHeatmap />
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    lg="30"
                                    md="6"
                                    className="d-flex custom-height-20"
                                >
                                    <div className="card card-body w-100">
                                        <div className="top mb-4">
                                            <h2 className="h8 mb-3 pb-1">
                                                About this heatmap
                                            </h2>
                                            <Row className="g-4">
                                                <Col sm="5">
                                                    <h3 className="h6 mb-1">
                                                        {state?.totalClick}
                                                    </h3>
                                                    <p className="h8 mb-0 text-muted">
                                                        Total clicks
                                                    </p>
                                                </Col>
                                                <Col sm="7">
                                                    <h3 className="h6 mb-1">
                                                        {state.lastSessionClick}
                                                    </h3>
                                                    <p className="h8 mb-0 text-muted">
                                                        last session clicks
                                                    </p>
                                                </Col>
                                                {/* <Col sm="5">
                                                    <h3 className="h6 mb-1">
                                                        04
                                                    </h3>
                                                    <p className="h8 mb-0 text-muted">
                                                        Dead clicks
                                                    </p>
                                                </Col> */}
                                                <Col sm="5">
                                                    <h3 className="h6 mb-1">
                                                        {Number(
                                                            state.averageTime
                                                        ) < 60
                                                            ? Number(
                                                                  Math.round(
                                                                      state.averageTime *
                                                                          100
                                                                  ) / 100
                                                              ) + " Sec"
                                                            : Math.round(
                                                                  (Number(
                                                                      state.averageTime
                                                                  ) /
                                                                      60) *
                                                                      100
                                                              ) /
                                                                  100 +
                                                              " Min"}
                                                    </h3>
                                                    <p className="h8 mb-0 text-muted">
                                                        Average time
                                                    </p>
                                                </Col>
                                                <Col sm="5">
                                                    <h3 className="h6 mb-1">
                                                        {state.visitors}
                                                    </h3>
                                                    <p className="h8 mb-0 text-muted">
                                                        visitors
                                                    </p>
                                                </Col>
                                                <Col sm="7">
                                                    <h3 className="h6 mb-1">
                                                        Jan 09, 2023
                                                    </h3>
                                                    <p className="h8 mb-0 text-muted">
                                                        Screenshot date
                                                    </p>
                                                </Col>
                                            </Row>
                                            <div className="checkbox-wrapper bg-light mb-3 p-2 rounded-1 mt-40">
                                                <FormGroup
                                                    check
                                                    inline
                                                    className="mb-0 filled"
                                                >
                                                    <Input
                                                        type="checkbox"
                                                        id="agree-term"
                                                    />
                                                    <Label
                                                        check
                                                        for="agree-term"
                                                        className="fw-400 h7 text-muted"
                                                    >
                                                        Highlight{" "}
                                                        <span className="text-black">
                                                            {" "}
                                                            top 3 clicks
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                            <div className="progress-item border-0 pt-1">
                                                <div className="progress-label mb-1">
                                                    <span className="left h9 fw-500 text-muted">
                                                        Opacity
                                                    </span>
                                                </div>
                                                <div
                                                    className="progress"
                                                    onMouseDown={
                                                        handleMouseDown
                                                    }
                                                    ref={progressBarRef}
                                                >
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: `${progress}%`,
                                                        }}
                                                        aria-valuenow={progress}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                            <div className="progress-item border-0 pt-0">
                                                <div className="progress-label mb-1">
                                                    <span className="left h9 fw-500 text-muted">
                                                        Intencity
                                                    </span>
                                                </div>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: "45%" }}
                                                        aria-valuenow="45"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom mt-auto">
                                            <ButtonGroup className="w-100 rounded-5 group-tab">
                                                <Button
                                                    size="sm"
                                                    color="light"
                                                    className="h8 rounded-5 tab-item border-0"
                                                >
                                                    All visitors
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    color="light"
                                                    className="h8 rounded-5 tab-item border-0 active"
                                                >
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        className="me-1"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M5.33301 14H10.6663M7.99967 11.3333V14M4.53301 11.3333H11.4663C12.5864 11.3333 13.1465 11.3333 13.5743 11.1153C13.9506 10.9236 14.2566 10.6176 14.4484 10.2413C14.6663 9.81349 14.6663 9.25344 14.6663 8.13333V5.2C14.6663 4.0799 14.6663 3.51984 14.4484 3.09202C14.2566 2.71569 13.9506 2.40973 13.5743 2.21799C13.1465 2 12.5864 2 11.4663 2H4.53301C3.4129 2 2.85285 2 2.42503 2.21799C2.0487 2.40973 1.74274 2.71569 1.55099 3.09202C1.33301 3.51984 1.33301 4.0799 1.33301 5.2V8.13333C1.33301 9.25344 1.33301 9.81349 1.55099 10.2413C1.74274 10.6176 2.0487 10.9236 2.42503 11.1153C2.85285 11.3333 3.4129 11.3333 4.53301 11.3333Z"
                                                            stroke="#292934"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    Middle
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    color="light"
                                                    className="h8 rounded-5 tab-item border-0"
                                                >
                                                    <svg
                                                        width="17"
                                                        height="16"
                                                        viewBox="0 0 17 16"
                                                        fill="none"
                                                        className="me-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8.83366 11.6663H8.84033M6.30033 14.6663H11.367C12.1137 14.6663 12.4871 14.6663 12.7723 14.521C13.0232 14.3932 13.2272 14.1892 13.355 13.9383C13.5003 13.6531 13.5003 13.2797 13.5003 12.533V3.46634C13.5003 2.7196 13.5003 2.34624 13.355 2.06102C13.2272 1.81014 13.0232 1.60616 12.7723 1.47833C12.4871 1.33301 12.1137 1.33301 11.367 1.33301H6.30033C5.55359 1.33301 5.18022 1.33301 4.895 1.47833C4.64412 1.60616 4.44015 1.81014 4.31232 2.06102C4.16699 2.34624 4.16699 2.7196 4.16699 3.46634V12.533C4.16699 13.2797 4.16699 13.6531 4.31232 13.9383C4.44015 14.1892 4.64412 14.3932 4.895 14.521C5.18022 14.6663 5.55359 14.6663 6.30033 14.6663ZM9.16699 11.6663C9.16699 11.8504 9.01775 11.9997 8.83366 11.9997C8.64956 11.9997 8.50033 11.8504 8.50033 11.6663C8.50033 11.4822 8.64956 11.333 8.83366 11.333C9.01775 11.333 9.16699 11.4822 9.16699 11.6663Z"
                                                            stroke="#74747F"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    Right
                                                </Button>
                                            </ButtonGroup>
                                            {/* <ButtonGroup className="w-100 rounded-5 group-tab mt-2">
                                                <Button
                                                    size="sm"
                                                    color="light"
                                                    className="h8 rounded-5 tab-item border-0"
                                                >
                                                    Click
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    color="light"
                                                    className="h8 rounded-5 tab-item border-0 active"
                                                >
                                                    Move
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    color="light"
                                                    className="h8 rounded-5 tab-item border-0"
                                                >
                                                    Scroll
                                                </Button>
                                            </ButtonGroup> */}
                                            <ButtonGroup className="w-100 rounded-5 group-tab mt-2 button-group-custom">
                                                {buttons.map((label, index) => (
                                                    <Button
                                                        key={index}
                                                        size="sm"
                                                        color="light"
                                                        className={`h8 rounded-5 tab-item border-0 fw-400 ${
                                                            activeIndex ===
                                                            index
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            setActiveIndex(
                                                                index
                                                            );
                                                            getFilteredData(
                                                                label
                                                            );
                                                        }}
                                                    >
                                                        {label}
                                                    </Button>
                                                ))}
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeatmapDetails;
