import "./App.scss";
import RouteApp from "./Components/Routes/App";

function App() {
    return (
        <>
            <RouteApp />
        </>
    );
}

export default App;
