import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import { DirectLinkIcon } from "../../../Assets/icons";
import { truncateUrl } from "../Helper";

const Referer = () => {
    const { referrerCounts, status, error } = useSelector(
        (state) => state.dashboard
    );
    const list = referrerCounts?.data;
    const getFaviconUrl = (url) => {
        return `https://www.google.com/s2/favicons?domain=${url}`;
    };
    return (
        <Col md="6">
            <div className="card">
                <div className="card-header">
                    <h4 className="dash-card-title mb-0 h6 tab-color tab-active">
                        Referer
                    </h4>
                </div>
                <div className="card-body scroll--height pt-0 top-click-section">
                    {list?.map((item, index) => {
                        return (
                            <div key={index} className="progress-item">
                                <div
                                    key={index}
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="d-flex align-items-center">
                                        {console.log(item.referrer)}
                                        {item.referrer === "" ? (
                                            <DirectLinkIcon />
                                        ) : (
                                            <img
                                                src={getFaviconUrl(
                                                    item.referrer
                                                )}
                                                alt="icon"
                                                className="icon-item"
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                }}
                                            />
                                        )}

                                        <span className="text-muted ms-2">
                                            <a
                                                href={
                                                    item.referrer
                                                        ? item.referrer
                                                        : "Direct / None"
                                                }
                                                className="link"
                                                title={
                                                    item.referrer
                                                        ? item.referrer
                                                        : "Direct / None"
                                                }
                                            >
                                                {truncateUrl(
                                                    item.referrer
                                                        ? item.referrer
                                                        : "Direct / None"
                                                )}
                                            </a>
                                        </span>
                                    </div>
                                    <span className="text-muted">
                                        {item.count}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Col>
    );
};

export default Referer;
