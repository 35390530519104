import React from "react";
import {
    PieChart,
    Pie,
    Legend,
    Cell,
    ResponsiveContainer,
    Label,
} from "recharts";

export default function PieChartComponent({
    ChartTotalData,
    ChartLabelOne,
    ChartLabelTwo,
    ChartColorOne,
    ChartColorTwo,
    ChartValueOne,
    ChartValueTwo,
}) {
    const COLORS = [ChartColorOne, ChartColorTwo];
    const data01 = [
        { name: "Active Campagins", value: ChartValueOne },
        { name: "Inactive Campagins", value: ChartValueTwo },
    ];

    const CustomLabel = ({ viewBox, labelText, value }) => {
        const { cx, cy } = viewBox;
        return (
            <g>
                <text
                    x={cx}
                    y={cy - 5}
                    className="recharts-text recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                    alignmentBaseline="middle"
                    fill="#292934"
                    fontSize="32"
                    fontWeight="600"
                >
                    {value}
                </text>
                <text
                    x={cx}
                    y={cy + 25}
                    className="recharts-text recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                    alignmentBaseline="middle"
                    fill="#292934"
                    fontSize="14"
                    lineheight="16.8"
                >
                    {labelText}
                </text>
            </g>
        );
    };

    return (
        <div style={{ width: "40%", height: 210 }}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        data={data01}
                        dataKey="value"
                        cx={100}
                        cy={100}
                        innerRadius={80}
                        outerRadius={97}
                    >
                        {data01.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                        <Label
                            content={
                                <CustomLabel
                                    labelText="Total users"
                                    value={ChartTotalData}
                                />
                            }
                            position="center"
                        />
                    </Pie>
                    {/* <Legend content={<CustomizedLegend />} /> */}
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}
