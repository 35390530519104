import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "@emotion/styled";
import crossicon from "../../Assets/Images/crossIcon.svg";
import ProfileSidebar from "./ProfileSidebar";
import InstallScript from "../Private/ProfileSection/InstallScript";
import UserProfile from "../Private/ProfileSection/UserProfile";
import { performLogout } from "../../redux/authentication/logout";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Modal = styled(motion.div)`
    position: fixed;
    top: 10%;
    left: 30%;
    transform: translate(-50%, -50%);
    width: 752px;
    height: 674px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Header = styled.div`
    width: 100%;
    // padding: 50px;
    height: 58px;
    // border-bottom: 1px solid #ccc;
    border-bottom: 1px solid var(--gray-200, #eeeef2);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Sidebar = styled.div`
    width: 252px;
    border-right: 1px solid var(--gray-200, #eeeef2);
    // height: calc(100% - 10px);
    overflow-y: auto;
`;

const ContentSection = styled.div`
    width: 500px;
    padding: 10px;
    height: calc(100% - 40px);
    overflow-y: auto;
`;

const CloseButton = styled.button`
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
`;

const modalVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.5 },
};

const UserDialogModel = ({ isOpen, setIsOpen }) => {
    const [activeSection, setActiveSection] = useState("profile");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = performLogout(dispatch, navigate);

    useEffect(() => {
        if (activeSection === "logout") {
            setIsOpen(false);
            handleLogout();
        }
    }, [activeSection]);

    const getContent = () => {
        switch (activeSection) {
            case "profile":
                return <UserProfile />;
            case "organization":
                return "Organization Settings Content Here";
            case "install":
                return <InstallScript />;
            case "notification":
                return "Notification Settings Content Here";
            case "logout":
                return "You have been logged out.";
            default:
                return "Select a section from the sidebar";
        }
    };

    return (
        <AnimatePresence mode="wait">
            {isOpen && (
                <Modal
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={modalVariants}
                >
                    <Header>
                        <div className="profile-header">
                            <div className="profile-title">
                                Profile and account settings
                            </div>
                            <div className="profile-close-button">
                                <CloseButton onClick={() => setIsOpen(false)}>
                                    <img src={crossicon} />
                                </CloseButton>
                            </div>
                        </div>
                    </Header>
                    <div style={{ display: "flex", height: "100%" }}>
                        <Sidebar>
                            <ProfileSidebar
                                setActiveSection={setActiveSection}
                                activeSection={activeSection}
                            />
                        </Sidebar>
                        <ContentSection>{getContent()}</ContentSection>
                    </div>
                </Modal>
            )}
        </AnimatePresence>
    );
};

export default UserDialogModel;
