import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Ganesha from "../../../Assets/Images/Lalbaugh_Ganesha.jpg";
import { PenIcon } from "../../../Assets/icons";

function UserProfile(props) {
    const userObject = useSelector((state) => state.user.userData);
    const companyName = userObject?.cd[0]?.company_name;
    const websiteURL = userObject?.cd[0]?.website_url;
    const userName = userObject.user.username;
    const userEmail = userObject.user.email;

    return (
        <div className="profile-section">
            {/* <div className="profile-image-section">
                <img src={Ganesha} className="user-image" />
                <div className="upload-button-section">
                    <div className="upload-button">
                        <PenIcon />
                        <em className="file-text">Choose file</em>
                    </div>
                    <div className="choosefile-text">
                        Ideal dimensions: 200x200px
                    </div>
                </div>
            </div> */}
            <div className="user-details-section">
                <form>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="User name"
                            className="form-control profile-text-box"
                            value={userName}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="User email"
                            className="form-control profile-text-box"
                            value={userEmail}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Company Name"
                            className="form-control profile-text-box"
                            value={companyName}
                            disabled
                        />
                    </div>
                    <div className="row form-group gx-2">
                        {/* <div className="col-sm-6 col-md-7"> */}
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Website URL"
                                className="form-control profile-text-box"
                                value={websiteURL}
                                disabled
                            />
                        </div>
                        {/* </div> */}
                        {/* <div className="col-sm-6 col-md-5">
                            <div className="form-group">
                                <button className="change-password-button">
                                    Chnage Password
                                </button>
                            </div>
                        </div> */}
                    </div>

                    <div className="form-group">
                        <button className="save-button btn btn-primary rounded-12 w-100">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UserProfile;
