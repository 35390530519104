import React, { useState } from "react";
import { Col } from "reactstrap";
import {
    HeavyResourceIcon,
    LightResourceIcon,
    MediumResourceIcon,
} from "../../../Assets/icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHeaviestResources } from "../../../redux/dashboard/dashboardSlice";
import { extractFileName, truncateUrl } from "../Helper";
import SolwestResources from "./SolwestResources";

const HeavyResources = (props) => {
    const dispatch = useDispatch();
    const { heavyResources } = useSelector((state) => state.dashboard);

    const [selectedView, setSelectedView] = useState("heavy");

    const handleClick = (view) => {
        setSelectedView(view);
    };

    const list = heavyResources?.data;

    // const maxTransferSize = getMaxTransferSize(list);

    useEffect(() => {
        dispatch(getHeaviestResources(props.userId));
    }, [dispatch]);

    const formatBytes = (bytes) => {
        const kb = bytes / 1024;
        if (kb < 1024) {
            return `${kb.toFixed(1)} KB`;
        } else {
            const mb = kb / 1024;
            return `${mb.toFixed(1)} MB`;
        }
    };

    return (
        <Col md="6">
            <div className="card">
                <div className="card-header">
                    <div className="d-flex gap-3">
                        <h4
                            className={`dash-card-title mb-0 h6 tab-color ${
                                selectedView === "heavy" ? "tab-active" : ""
                            }`}
                            onClick={() => handleClick("heavy")}
                            style={{ cursor: "pointer" }}
                        >
                            Heavy Resources
                        </h4>
                        <h4
                            className={`dash-card-title mb-0 h6 tab-color ${
                                selectedView === "slow" ? "tab-active" : ""
                            }`}
                            onClick={() => handleClick("slow")}
                            style={{ cursor: "pointer" }}
                        >
                            Slowest resources
                        </h4>
                    </div>
                </div>
                {selectedView === "heavy" && (
                    <div className="card-body scroll--height pt-0 top-click-section">
                        {list &&
                            list.length > 0 &&
                            list?.map((item, index) => {
                                // const truncatedName = truncateText(item.name, 20);
                                return (
                                    <div key={index} className="progress-item">
                                        <div className="progress-label">
                                            <div>
                                                {index == 0 ||
                                                index == 1 ||
                                                index == 2 ? (
                                                    <HeavyResourceIcon />
                                                ) : index == 3 || index == 4 ? (
                                                    <MediumResourceIcon />
                                                ) : index == 5 || index == 6 ? (
                                                    <LightResourceIcon />
                                                ) : (
                                                    ""
                                                )}

                                                <span className="left">
                                                    <a
                                                        href={item.name}
                                                        className="link"
                                                        title={item.name}
                                                    >
                                                        {extractFileName(
                                                            item.name
                                                        )}{" "}
                                                        {truncateUrl(item.name)}
                                                    </a>
                                                </span>
                                            </div>
                                            <div className="right">
                                                {formatBytes(
                                                    parseInt(item.transfersize)
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                                width: `${normalizeTransferSize(
                                                    item.transfersize,
                                                    maxTransferSize
                                                )}%`,
                                            }}
                                            aria-valuenow={normalizeTransferSize(
                                                item.transfersize,
                                                maxTransferSize
                                            )}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div> */}
                                    </div>
                                );
                            })}
                    </div>
                )}
                {selectedView === "slow" && (
                    <SolwestResources userId={props.userId} />
                )}
            </div>
        </Col>
    );
};

export default HeavyResources;
