import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    submitAlertData,
    getAlertsList,
    deleteAlertAPI,
} from "../../services/api";
export const addAlert = createAsyncThunk(
    "alerts/addAlert",
    async (alertData, thunkAPI) => {
        console.log("alertData", alertData);
        try {
            const response = await submitAlertData(alertData);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const fetchAlerts = createAsyncThunk(
    "alerts/fetchAlertsList",
    async (url, thunkAPI) => {
        try {
            const response = await getAlertsList();
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const deleteAlert = createAsyncThunk(
    "alerts/deleteAlert",
    async (alertId, thunkAPI) => {
        try {
            const response = await deleteAlertAPI(alertId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const alertsSlice = createSlice({
    name: "alerts",
    initialState: {
        alertsData: null,
        error: null,
        alertResponse: null,
    },
    reducers: {},
    extraReducers: {
        [addAlert.pending]: (state) => {
            state.status = "loading";
        },
        [addAlert.fulfilled]: (state, { payload }) => {
            state.alertsData = payload.details;
            state.status = "fulfilled";
            state.alertResponse = payload;
        },
        [addAlert.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
        [fetchAlerts.pending]: (state) => {
            state.status = "loading";
        },
        [fetchAlerts.fulfilled]: (state, { payload }) => {
            state.alertsList = payload;
            state.status = "fulfilled";
        },
        [fetchAlerts.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
        [deleteAlert.pending]: (state) => {
            state.status = "loading";
        },
        [deleteAlert.fulfilled]: (state, { payload }) => {
            state.alertsList = payload;
            state.status = "fulfilled";
        },
        [deleteAlert.rejected]: (state, { payload }) => {
            state.error = payload.error;
            state.status = "failed";
        },
    },
});

export const alertsReducer = alertsSlice.reducer;
