import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
} from "reactstrap";
import pcIcon from "./../../Assets/Images/monitor.svg";
import mobileIcon from "./../../Assets/Images/mobile.svg";
import { getSessionReplayData } from "../../redux/sessionrecord/sessionRecordSlice";
import { getSessionReplayErrorList } from "../../redux/sessionrecord/sessionRecordSlice";
import Lottie from "react-lottie";
import * as animationData from "../../Assets/Gif/heatmapherogif.json";
import {
    ViewInFullScreenIcon,
    RefreshIcon,
    DownloadIcon,
    TerminalIcon,
    TargetIcon,
} from "../../Assets/icons";
import ErrorList from "./SessionReplay/ErrorList";
import ErrorListData from "./SessionReplay/ErrorListData";
import getCountryFlag, { convertMilliseconds } from "./Helper";
import OsIcon from "./Helper/OsIcon";
import BrowserIcon from "./Helper/BrowserIcon";

function HeatMapViewRecording(props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { session_id } = useParams();
    const [currentSection, setCurrentSection] = useState("replay");
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const dispatch = useDispatch();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    useEffect(() => {
        dispatch(getSessionReplayData(session_id));
        dispatch(getSessionReplayErrorList(session_id));
    }, [session_id]);

    const sessionRecordRawData = useSelector(
        (state) => state.sessionrecord.sessionRecordRawData
    );

    const sessionReplayErrors = useSelector(
        (state) => state.sessionrecord.sessionReplayErrors
    );

    const clicks = sessionRecordRawData?.details
        ?.filter((event) => {
            return event.event_type === 3 && event.event_data.type === 1;
        })
        .map((clickEvent) => {
            return {
                x: clickEvent.event_data.x,
                y: clickEvent.event_data.y,
                time: clickEvent.event_time,
            };
        });
    const playerElRef = useRef();
    const playerRef = useRef();
    const [scale, setScale] = useState(1);

    const switchTab = (section) => {
        setCurrentSection(section);
    };

    const convertToRrwebFormat = (data) => {
        if (data.status !== "success") {
            throw new Error("Data retrieval was unsuccessful.");
        }

        return data.details.map((detail) => {
            return {
                type: detail.event_type,
                data: detail.event_data,
                timestamp: new Date(detail.event_time).getTime(),
            };
        });
    };

    const [seekToDateTime, setSeekToDateTime] = useState(null);
    useEffect(() => {
        if (sessionRecordRawData && playerElRef.current) {
            try {
                if (playerRef.current) {
                    playerElRef.current.innerHTML = "";
                    playerRef.current = null;
                }
                const events = convertToRrwebFormat(sessionRecordRawData);
                const player = new rrwebPlayer({
                    target: playerElRef.current,
                    props: { events },
                });
                playerRef.current = player;
                if (seekToDateTime) {
                    let rrwebTimestamp = 1000;
                    if (typeof seekToDateTime === "string") {
                        rrwebTimestamp = convertDateTimeToRrwebTimestamp(
                            seekToDateTime,
                            events
                        );
                    }
                    if (rrwebTimestamp) {
                        setTimeout(() => {
                            if (playerRef.current) {
                                playerRef.current.goto(rrwebTimestamp, true);
                                playerRef.current.pause();
                            }
                        }, 0);
                    }
                }
            } catch (error) {
                console.error(
                    "An error occurred while processing session replay data:",
                    error
                );
            }
        }
        setScale(0.81);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [sessionRecordRawData, playerElRef, currentSection, seekToDateTime]);

    function convertDateTimeToRrwebTimestamp(dateTime, events) {
        if (events.length) {
            const startTime = events[0].timestamp;
            const dateTimeTimestamp = new Date(dateTime).getTime();
            const differenceInSeconds = Math.round(
                (dateTimeTimestamp - startTime) / 1000
            );
            return differenceInSeconds * 1000;
        } else {
            return 0;
        }
    }
    function handleListItemClick(dateTime) {
        switchTab("replay");
        setSeekToDateTime(dateTime);
    }
    return (
        <>
            <div className="main--wrapper">
                <header className="header--wrapper">
                    <div className="header--inner container">
                        <div className="left">
                            <h1 className="h4 mb-0 d-flex align-items-center">
                                <svg
                                    width="24"
                                    className="me-2"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 12.5H5M5 12.5L12 19.5M5 12.5L12 5.5"
                                        stroke="#B0B0BB"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Session Replay
                            </h1>
                        </div>
                        <div className="right d-flex">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle className="btn-sm d-flex align-items-center">
                                    <svg
                                        className="dropdown-left-icon"
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.5 7.83366H1.5M12.3333 1.16699V4.50033M5.66667 1.16699V4.50033M5.5 17.8337H12.5C13.9001 17.8337 14.6002 17.8337 15.135 17.5612C15.6054 17.3215 15.9878 16.939 16.2275 16.4686C16.5 15.9339 16.5 15.2338 16.5 13.8337V6.83366C16.5 5.43353 16.5 4.73346 16.2275 4.19868C15.9878 3.72828 15.6054 3.34583 15.135 3.10614C14.6002 2.83366 13.9001 2.83366 12.5 2.83366H5.5C4.09987 2.83366 3.3998 2.83366 2.86502 3.10614C2.39462 3.34583 2.01217 3.72828 1.77248 4.19868C1.5 4.73346 1.5 5.43353 1.5 6.83366V13.8337C1.5 15.2338 1.5 15.9339 1.77248 16.4686C2.01217 16.939 2.39462 17.3215 2.86502 17.5612C3.3998 17.8337 4.09987 17.8337 5.5 17.8337Z"
                                            stroke="#74747F"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    12 Months
                                    <svg
                                        className="dropdown-right-icon"
                                        width="10"
                                        height="7"
                                        viewBox="0 0 10 7"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 1.5L5 5.5L9 1.5"
                                            stroke="#74747F"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>3 Months</DropdownItem>
                                    <DropdownItem>6 Months</DropdownItem>
                                    <DropdownItem>9 Months</DropdownItem>
                                    <DropdownItem>12 Months</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Button
                                color="secondary"
                                size="sm"
                                className="notification--btn px-2 ms-2"
                            >
                                <svg
                                    className="btn-icon"
                                    width="20"
                                    height="23"
                                    viewBox="0 0 20 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.35395 20.5C8.05908 21.1224 8.98533 21.5 9.99977 21.5C11.0142 21.5 11.9405 21.1224 12.6456 20.5M15.9998 7.5C15.9998 5.9087 15.3676 4.38258 14.2424 3.25736C13.1172 2.13214 11.5911 1.5 9.99977 1.5C8.40848 1.5 6.88235 2.13214 5.75713 3.25736C4.63192 4.38258 3.99977 5.9087 3.99977 7.5C3.99977 10.5902 3.22024 12.706 2.34944 14.1054C1.6149 15.2859 1.24763 15.8761 1.2611 16.0408C1.27601 16.2231 1.31463 16.2926 1.46155 16.4016C1.59423 16.5 2.19237 16.5 3.38863 16.5H16.6109C17.8072 16.5 18.4053 16.5 18.538 16.4016C18.6849 16.2926 18.7235 16.2231 18.7384 16.0408C18.7519 15.8761 18.3846 15.2859 17.6501 14.1054C16.7793 12.706 15.9998 10.5902 15.9998 7.5Z"
                                        stroke="#292934"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span className="badge badge-danger">03</span>
                            </Button>
                        </div>
                    </div>
                </header>
                <div className="content">
                    <div className="content-inner">
                        <div className="container">
                            <div className="recent-heatmapes-list">
                                <Row className="g-3">
                                    <Col lg="100" md="12">
                                        <div className="card">
                                            <div className="card-header p-0 border-bottom border-light d-flex align-items-center">
                                                <ul className="nav border-0 nav-tabs d-inline-flex">
                                                    <li
                                                        className={`nav-item`}
                                                        onClick={() =>
                                                            switchTab("replay")
                                                        }
                                                    >
                                                        <a
                                                            className={`nav-link ${
                                                                currentSection ===
                                                                "replay"
                                                                    ? "active"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <TargetIcon />
                                                            Show highlights
                                                        </a>
                                                    </li>
                                                    <li className={`nav-item`}>
                                                        <a
                                                            onClick={() =>
                                                                switchTab(
                                                                    "resources"
                                                                )
                                                            }
                                                            className={`nav-link ${
                                                                currentSection ===
                                                                "resources"
                                                                    ? "active"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <DownloadIcon />
                                                            Resources
                                                        </a>
                                                    </li>
                                                    <li
                                                        className={`nav-item`}
                                                        onClick={() =>
                                                            switchTab("error")
                                                        }
                                                    >
                                                        <a
                                                            className={`nav-link ${
                                                                currentSection ===
                                                                "error"
                                                                    ? "active"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <TerminalIcon />
                                                            Error logs
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="ms-auto d-inline-flex pe-3">
                                                    <a
                                                        href="#!"
                                                        className="action-item d-inline-flex p-2"
                                                    >
                                                        <ViewInFullScreenIcon />
                                                    </a>
                                                    <a
                                                        href="#!"
                                                        className="action-item d-inline-flex ms-2 p-2"
                                                    >
                                                        <RefreshIcon />
                                                    </a>
                                                </div>
                                            </div>
                                            {currentSection == "replay" ? (
                                                <div className="session-replay-new">
                                                    <Col
                                                        lg="8"
                                                        md="9"
                                                        className=""
                                                    >
                                                        <div
                                                            style={{
                                                                transform: `scale(${scale})`,
                                                                transformOrigin:
                                                                    "left",
                                                            }}
                                                            ref={playerElRef}
                                                            className="session-replay-player-wrapper"
                                                        />
                                                    </Col>
                                                    <Col
                                                        lg="4"
                                                        md="3"
                                                        className="session-info"
                                                    >
                                                        <div
                                                            className="card card-body pb-3"
                                                            style={{
                                                                minHeight:
                                                                    "200px",
                                                            }}
                                                        >
                                                            {loading ? (
                                                                <div className="heatmaphero-logo-loader">
                                                                    <Lottie
                                                                        options={
                                                                            defaultOptions
                                                                        }
                                                                        height={
                                                                            100
                                                                        }
                                                                        width={
                                                                            100
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="top">
                                                                        <h2 className="h8 mb-2 pb-1">
                                                                            Session
                                                                            Info
                                                                        </h2>
                                                                    </div>
                                                                    <table className="table remove-last-border mb-0 spacing-sm">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    Date
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {new Date(
                                                                                        sessionRecordRawData.replayData[0].start_time
                                                                                    ).toLocaleString()}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    Country
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    <span className="text-end d-block">
                                                                                        <span className="country-item">
                                                                                            <img
                                                                                                src={getCountryFlag(
                                                                                                    sessionRecordRawData
                                                                                                        .replayData[0]
                                                                                                        .country
                                                                                                )}
                                                                                                // alt={`${item.country} flag`}
                                                                                                className="me-2 flage-image"
                                                                                            />
                                                                                            {
                                                                                                sessionRecordRawData
                                                                                                    .replayData[0]
                                                                                                    .country
                                                                                            }
                                                                                        </span>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    User
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {
                                                                                        sessionRecordRawData
                                                                                            .replayData[0]
                                                                                            .session_id
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    Actions
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {
                                                                                        clicks.length
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    Pages
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {
                                                                                        sessionRecordRawData
                                                                                            .replayData[0]
                                                                                            .page_travel
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    Duration
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {convertMilliseconds(
                                                                                        sessionRecordRawData
                                                                                            .replayData[0]
                                                                                            .duration
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Device
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    <BrowserIcon
                                                                                        browser={
                                                                                            sessionRecordRawData
                                                                                                .replayData[0]
                                                                                                .browser
                                                                                        }
                                                                                    />
                                                                                    <OsIcon
                                                                                        os={
                                                                                            sessionRecordRawData
                                                                                                .replayData[0]
                                                                                                .os
                                                                                        }
                                                                                    />

                                                                                    <span className="browser-item">
                                                                                        {sessionRecordRawData
                                                                                            .replayData[0]
                                                                                            .device ==
                                                                                        "Desktop" ? (
                                                                                            <img
                                                                                                src={
                                                                                                    pcIcon
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={
                                                                                                    mobileIcon
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        )}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Landing
                                                                                    page
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {
                                                                                        sessionRecordRawData
                                                                                            .replayData[0]
                                                                                            .start_page
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Exit
                                                                                    page
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {
                                                                                        sessionRecordRawData
                                                                                            .replayData[0]
                                                                                            .end_page
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </div>
                                            ) : currentSection == "error" ? (
                                                <ErrorListData
                                                    errorlist={
                                                        sessionReplayErrors
                                                    }
                                                    onListItemClick={
                                                        handleListItemClick
                                                    }
                                                />
                                            ) : currentSection ==
                                              "resources" ? (
                                                <ErrorList
                                                    errorlist={
                                                        sessionReplayErrors
                                                    }
                                                    onListItemClick={
                                                        handleListItemClick
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                        <style jsx>{`
                                            .error-list {
                                                background: #f5f5f5;
                                                padding: 10px;
                                            }

                                            .error-item {
                                                margin-bottom: 10px;
                                            }

                                            .error-title {
                                                font-weight: bold;
                                                cursor: pointer;
                                            }

                                            .error-details {
                                                background: #ffe6e6;
                                                padding: 10px;
                                                border: 1px solid #f55;
                                                width: 100%;
                                                max-width: 350px;
                                            }

                                            pre {
                                                white-space: pre-wrap;
                                            }
                                        `}</style>
                                    </Col>
                                    {/* <Col lg="30" md="6" className="">
                                        <div
                                            className="card card-body pb-3"
                                            style={{ minHeight: "200px" }}
                                        >
                                            {loading ? (
                                                <div className="heatmaphero-logo-loader">
                                                    <Lottie
                                                        options={defaultOptions}
                                                        height={100}
                                                        width={100}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="top">
                                                        <h2 className="h8 mb-2 pb-1">
                                                            Session Info
                                                        </h2>
                                                    </div>
                                                    <table className="table remove-last-border mb-0 spacing-sm">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-muted">
                                                                    Date
                                                                </td>
                                                                <td className="text-end">
                                                                    {new Date(
                                                                        sessionRecordRawData.replayData[0].start_time
                                                                    ).toLocaleString()}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-muted">
                                                                    Country
                                                                </td>
                                                                <td className="text-end">
                                                                    <span className="text-end d-block">
                                                                        <span className="country-item">
                                                                            <img
                                                                                src={getCountryFlag(
                                                                                    sessionRecordRawData
                                                                                        .replayData[0]
                                                                                        .country
                                                                                )}
                                                                                // alt={`${item.country} flag`}
                                                                                className="me-2 flage-image"
                                                                            />
                                                                            {
                                                                                sessionRecordRawData
                                                                                    .replayData[0]
                                                                                    .country
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-muted">
                                                                    User
                                                                </td>
                                                                <td className="text-end">
                                                                    {
                                                                        sessionRecordRawData
                                                                            .replayData[0]
                                                                            .session_id
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-muted">
                                                                    Actions
                                                                </td>
                                                                <td className="text-end">
                                                                    {
                                                                        clicks.length
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-muted">
                                                                    Pages
                                                                </td>
                                                                <td className="text-end">
                                                                    {
                                                                        sessionRecordRawData
                                                                            .replayData[0]
                                                                            .page_travel
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-muted">
                                                                    Duration
                                                                </td>
                                                                <td className="text-end">
                                                                    {convertMilliseconds(
                                                                        sessionRecordRawData
                                                                            .replayData[0]
                                                                            .duration
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Device</td>
                                                                <td className="text-end">
                                                                    <span className="browser-item">
                                                                        {sessionRecordRawData
                                                                            .replayData[0]
                                                                            .browser ==
                                                                        "Safari" ? (
                                                                            <img
                                                                                src={
                                                                                    safariIcon
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={
                                                                                    chromeIcon
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                    </span>
                                                                    <span className="browser-item">
                                                                        {sessionRecordRawData
                                                                            .replayData[0]
                                                                            .os ==
                                                                        "Mac OS X" ? (
                                                                            <img
                                                                                src={
                                                                                    appleIcon
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={
                                                                                    appleIcon
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                    </span>
                                                                    <span className="browser-item">
                                                                        {sessionRecordRawData
                                                                            .replayData[0]
                                                                            .device ==
                                                                        "Desktop" ? (
                                                                            <img
                                                                                src={
                                                                                    pcIcon
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={
                                                                                    mobileIcon
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Landing page
                                                                </td>
                                                                <td className="text-end">
                                                                    {
                                                                        sessionRecordRawData
                                                                            .replayData[0]
                                                                            .start_page
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Exit page
                                                                </td>
                                                                <td className="text-end">
                                                                    {
                                                                        sessionRecordRawData
                                                                            .replayData[0]
                                                                            .end_page
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            )}
                                        </div>
                                        <div className="card card-body mt-3 pb-3 resource-list">
                                            <div className="top">
                                                <h2 className="h8 mb-2 pb-1">
                                                    Resource list
                                                </h2>
                                            </div>
                                            <ErrorList
                                                errorlist={sessionReplayErrors}
                                            />
                                        </div>
                                    </Col> */}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeatMapViewRecording;

// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams, useLocation } from "react-router-dom";
// import { Replayer } from "rrweb";
// import rrwebPlayer from "rrweb-player";
// import { events } from "./Replayer/events";
// import "rrweb-player/dist/style.css";
// import {
//     Button,
//     Col,
//     Dropdown,
//     DropdownItem,
//     DropdownMenu,
//     DropdownToggle,
//     Row,
// } from "reactstrap";
// import fullPage from "./../../Assets/Images/full-page.jpg";
// import saudiFlag from "./../../Assets/Images/flag-saudi-arabia.svg";
// import chromeIcon from "./../../Assets/Images/chrome.svg";
// import safariIcon from "./../../Assets/Images/safari.svg";
// import pcIcon from "./../../Assets/Images/monitor.svg";
// import appleIcon from "./../../Assets/Images/apple.svg";
// import mobileIcon from "./../../Assets/Images/mobile.svg";
// import { getSessionReplayData } from "../../redux/sessionrecord/sessionRecordSlice";
// import { getSessionReplayErrorList } from "../../redux/sessionrecord/sessionRecordSlice";
// import Lottie from "react-lottie";
// import * as animationData from "../../Assets/Gif/heatmapherogif.json";
// function HeatMapViewRecording(props) {
//     const [dropdownOpen, setDropdownOpen] = useState(false);
//     const [loading, setLoading] = useState(true);
//     const [sessionTimes, setSessionTimes] = useState({
//         start: null,
//         end: null,
//     });

//     const { session_id } = useParams();
//     const toggle = () => setDropdownOpen((prevState) => !prevState);

//     const dispatch = useDispatch();

//     const defaultOptions = {
//         loop: true,
//         autoplay: true,
//         animationData: animationData,
//         rendererSettings: {
//             preserveAspectRatio: "xMidYMid slice",
//         },
//     };

//     useEffect(() => {
//         dispatch(getSessionReplayData(session_id));
//         dispatch(getSessionReplayErrorList(session_id));
//     }, [session_id]);

//     const sessionRecordRawData = useSelector(
//         (state) => state.sessionrecord.sessionRecordRawData
//     );

//     const sessionReplayErrors = useSelector(
//         (state) => state.sessionrecord.sessionReplayErrors
//     );

//     // const clicks = sessionRecordRawData?.details
//     //     ?.filter((event) => {
//     //         return event.event_type === 3 && event.event_data.type === 1;
//     //     })
//     //     .map((clickEvent) => {
//     //         return {
//     //             x: clickEvent.event_data.x,
//     //             y: clickEvent.event_data.y,
//     //             time: clickEvent.event_time,
//     //         };
//     //     });

//     // const mouseMovements = sessionRecordRawData.filter(
//     //     (event) => event.type === "mousemove"
//     // );

//     // console.log("mouseMovements ===> ", mouseMovements);

//     const playerElRef = useRef();
//     const playerRef = useRef();

//     const convertToRrwebFormat = (data) => {
//         if (data.status !== "success") {
//             throw new Error("Data retrieval was unsuccessful.");
//         }

//         return data.details.map((detail) => {
//             return {
//                 type: detail.event_type,
//                 data: detail.event_data,
//                 timestamp: new Date(detail.event_time).getTime(),
//             };
//         });
//     };

//     useEffect(() => {
//         if (sessionRecordRawData && playerElRef.current) {
//             try {
//                 if (playerRef.current) {
//                     playerElRef.current.innerHTML = ""; // Clear the target container
//                     playerRef.current = null; // Clear the player reference
//                 }

//                 const events = convertToRrwebFormat(sessionRecordRawData);

//                 const player = new rrwebPlayer({
//                     target: playerElRef.current,
//                     props: { events },
//                 });

//                 playerRef.current = player;

//                 const eventsNew = convertToRrwebFormat(sessionRecordRawData);

//                 const sessionStart = eventsNew[0]?.timestamp;
//                 const sessionEnd = eventsNew[eventsNew.length - 1]?.timestamp;

//                 setSessionTimes({ start: sessionStart, end: sessionEnd });
//             } catch (error) {
//                 console.error(
//                     "An error occurred while processing session replay data:",
//                     error
//                 );
//             }
//         }
//         setTimeout(() => {
//             setLoading(false);
//         }, 3000);
//     }, [sessionRecordRawData, playerElRef]);

//     const generateErrorMarkers = () => {
//         if (!sessionTimes.start || !sessionReplayErrors.details) return;

//         console.log(sessionReplayErrors.details);
//         return sessionReplayErrors.details.map((error) => {
//             const position = calculatePosition(
//                 new Date(error.error_time).getTime(),
//                 sessionTimes.start,
//                 sessionTimes.end
//             );
//             return (
//                 <div
//                     key={error.id}
//                     className={`error-marker ${error.error_type}`}
//                     style={{ left: `${position}%` }}
//                     title={
//                         JSON.parse(error.error_data).message || error.error_data
//                     }
//                 />
//             );
//         });
//     };

//     function calculatePosition(errorTime, startTime, endTime) {
//         const sessionDuration = endTime - startTime;
//         const elapsedTime = errorTime - startTime;
//         return (elapsedTime / sessionDuration) * 100; // Returns percentage
//     }

//     return (
//         <>
//             <div className="main--wrapper">
//                 <header className="header--wrapper">
//                     <div className="header--inner container">
//                         <div className="left">
//                             <h1 className="h4 mb-0 d-flex align-items-center">
//                                 <svg
//                                     width="24"
//                                     className="me-2"
//                                     height="25"
//                                     viewBox="0 0 24 25"
//                                     fill="none"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                 >
//                                     <path
//                                         d="M19 12.5H5M5 12.5L12 19.5M5 12.5L12 5.5"
//                                         stroke="#B0B0BB"
//                                         strokeWidth="1.5"
//                                         strokeLinecap="round"
//                                         strokeLinejoin="round"
//                                     />
//                                 </svg>
//                                 Session Replay
//                             </h1>
//                         </div>
//                         <div className="right d-flex">
//                             <Dropdown isOpen={dropdownOpen} toggle={toggle}>
//                                 <DropdownToggle className="btn-sm d-flex align-items-center">
//                                     <svg
//                                         className="dropdown-left-icon"
//                                         width="18"
//                                         height="19"
//                                         viewBox="0 0 18 19"
//                                         fill="none"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                     >
//                                         <path
//                                             d="M16.5 7.83366H1.5M12.3333 1.16699V4.50033M5.66667 1.16699V4.50033M5.5 17.8337H12.5C13.9001 17.8337 14.6002 17.8337 15.135 17.5612C15.6054 17.3215 15.9878 16.939 16.2275 16.4686C16.5 15.9339 16.5 15.2338 16.5 13.8337V6.83366C16.5 5.43353 16.5 4.73346 16.2275 4.19868C15.9878 3.72828 15.6054 3.34583 15.135 3.10614C14.6002 2.83366 13.9001 2.83366 12.5 2.83366H5.5C4.09987 2.83366 3.3998 2.83366 2.86502 3.10614C2.39462 3.34583 2.01217 3.72828 1.77248 4.19868C1.5 4.73346 1.5 5.43353 1.5 6.83366V13.8337C1.5 15.2338 1.5 15.9339 1.77248 16.4686C2.01217 16.939 2.39462 17.3215 2.86502 17.5612C3.3998 17.8337 4.09987 17.8337 5.5 17.8337Z"
//                                             stroke="#74747F"
//                                             strokeWidth="1.5"
//                                             strokeLinecap="round"
//                                             strokeLinejoin="round"
//                                         />
//                                     </svg>
//                                     12 Months
//                                     <svg
//                                         className="dropdown-right-icon"
//                                         width="10"
//                                         height="7"
//                                         viewBox="0 0 10 7"
//                                         fill="none"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                     >
//                                         <path
//                                             d="M1 1.5L5 5.5L9 1.5"
//                                             stroke="#74747F"
//                                             strokeWidth="1.5"
//                                             strokeLinecap="round"
//                                             strokeLinejoin="round"
//                                         />
//                                     </svg>
//                                 </DropdownToggle>
//                                 <DropdownMenu>
//                                     <DropdownItem>3 Months</DropdownItem>
//                                     <DropdownItem>6 Months</DropdownItem>
//                                     <DropdownItem>9 Months</DropdownItem>
//                                     <DropdownItem>12 Months</DropdownItem>
//                                 </DropdownMenu>
//                             </Dropdown>
//                             <Button
//                                 color="secondary"
//                                 size="sm"
//                                 className="notification--btn px-2 ms-2"
//                             >
//                                 <svg
//                                     className="btn-icon"
//                                     width="20"
//                                     height="23"
//                                     viewBox="0 0 20 23"
//                                     fill="none"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                 >
//                                     <path
//                                         d="M7.35395 20.5C8.05908 21.1224 8.98533 21.5 9.99977 21.5C11.0142 21.5 11.9405 21.1224 12.6456 20.5M15.9998 7.5C15.9998 5.9087 15.3676 4.38258 14.2424 3.25736C13.1172 2.13214 11.5911 1.5 9.99977 1.5C8.40848 1.5 6.88235 2.13214 5.75713 3.25736C4.63192 4.38258 3.99977 5.9087 3.99977 7.5C3.99977 10.5902 3.22024 12.706 2.34944 14.1054C1.6149 15.2859 1.24763 15.8761 1.2611 16.0408C1.27601 16.2231 1.31463 16.2926 1.46155 16.4016C1.59423 16.5 2.19237 16.5 3.38863 16.5H16.6109C17.8072 16.5 18.4053 16.5 18.538 16.4016C18.6849 16.2926 18.7235 16.2231 18.7384 16.0408C18.7519 15.8761 18.3846 15.2859 17.6501 14.1054C16.7793 12.706 15.9998 10.5902 15.9998 7.5Z"
//                                         stroke="#292934"
//                                         strokeWidth="1.5"
//                                         strokeLinecap="round"
//                                         strokeLinejoin="round"
//                                     />
//                                 </svg>
//                                 <span className="badge badge-danger">03</span>
//                             </Button>
//                         </div>
//                     </div>
//                 </header>
//                 <div className="content">
//                     <div className="content-inner">
//                         <div className="container">
//                             <div className="recent-heatmapes-list">
//                                 <Row className="g-3">
//                                     <Col lg="70" md="6">
//                                         <div className="card">
//                                             <div className="card-header p-0 border-bottom border-light d-flex align-items-center">
//                                                 <ul className="nav border-0 nav-tabs d-inline-flex">
//                                                     <li className="nav-item">
//                                                         <a className="nav-link">
//                                                             <svg
//                                                                 className="me-2"
//                                                                 width="17"
//                                                                 height="17"
//                                                                 viewBox="0 0 17 17"
//                                                                 fill="none"
//                                                                 xmlns="http://www.w3.org/2000/svg"
//                                                             >
//                                                                 <g clipPath="url(#clip0_1005_7529)">
//                                                                     <path
//                                                                         d="M8.50033 15.5837C12.4123 15.5837 15.5837 12.4123 15.5837 8.50033C15.5837 4.58831 12.4123 1.41699 8.50033 1.41699C4.58831 1.41699 1.41699 4.58831 1.41699 8.50033C1.41699 12.4123 4.58831 15.5837 8.50033 15.5837Z"
//                                                                         stroke="#B0B0BB"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                     <path
//                                                                         d="M8.50033 12.7503C10.8475 12.7503 12.7503 10.8475 12.7503 8.50033C12.7503 6.15312 10.8475 4.25033 8.50033 4.25033C6.15312 4.25033 4.25033 6.15312 4.25033 8.50033C4.25033 10.8475 6.15312 12.7503 8.50033 12.7503Z"
//                                                                         stroke="#B0B0BB"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                     <path
//                                                                         d="M8.50033 9.91699C9.28273 9.91699 9.91699 9.28273 9.91699 8.50033C9.91699 7.71792 9.28273 7.08366 8.50033 7.08366C7.71792 7.08366 7.08366 7.71792 7.08366 8.50033C7.08366 9.28273 7.71792 9.91699 8.50033 9.91699Z"
//                                                                         stroke="#B0B0BB"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                 </g>
//                                                                 <defs>
//                                                                     <clipPath id="clip0_1005_7529">
//                                                                         <rect
//                                                                             width="17"
//                                                                             height="17"
//                                                                             fill="white"
//                                                                         />
//                                                                     </clipPath>
//                                                                 </defs>
//                                                             </svg>
//                                                             Show highlights
//                                                         </a>
//                                                     </li>
//                                                     <li className="nav-item">
//                                                         <a className="nav-link active">
//                                                             <svg
//                                                                 className="me-2"
//                                                                 width="17"
//                                                                 height="17"
//                                                                 viewBox="0 0 17 17"
//                                                                 fill="none"
//                                                                 xmlns="http://www.w3.org/2000/svg"
//                                                             >
//                                                                 <path
//                                                                     d="M3.54199 3.53416C3.54199 2.84625 3.54199 2.5023 3.68542 2.31269C3.81038 2.14752 4.00136 2.04531 4.20811 2.03296C4.44543 2.01879 4.73161 2.20958 5.30399 2.59117L12.7525 7.55685C13.2255 7.87214 13.4619 8.02979 13.5443 8.2285C13.6164 8.40222 13.6164 8.59746 13.5443 8.77118C13.4619 8.96988 13.2255 9.12753 12.7525 9.44283L5.30398 14.4085C4.73161 14.7901 4.44543 14.9809 4.20811 14.9667C4.00136 14.9544 3.81038 14.8522 3.68542 14.687C3.54199 14.4974 3.54199 14.1534 3.54199 13.4655V3.53416Z"
//                                                                     stroke="#B0B0BB"
//                                                                     strokeWidth="1.5"
//                                                                     strokeLinecap="round"
//                                                                     strokeLinejoin="round"
//                                                                 />
//                                                             </svg>
//                                                             View recording
//                                                         </a>
//                                                     </li>
//                                                     <li className="nav-item">
//                                                         <a className="nav-link">
//                                                             <svg
//                                                                 className="me-2"
//                                                                 width="17"
//                                                                 height="17"
//                                                                 viewBox="0 0 17 17"
//                                                                 fill="none"
//                                                                 xmlns="http://www.w3.org/2000/svg"
//                                                             >
//                                                                 <path
//                                                                     d="M14.875 10.625V11.475C14.875 12.6651 14.875 13.2602 14.6434 13.7147C14.4397 14.1146 14.1146 14.4397 13.7147 14.6434C13.2602 14.875 12.6651 14.875 11.475 14.875H5.525C4.33489 14.875 3.73983 14.875 3.28527 14.6434C2.88543 14.4397 2.56034 14.1146 2.35661 13.7147C2.125 13.2602 2.125 12.6651 2.125 11.475V10.625M12.0417 7.08333L8.5 10.625M8.5 10.625L4.95833 7.08333M8.5 10.625V2.125"
//                                                                     stroke="#B0B0BB"
//                                                                     strokeWidth="1.5"
//                                                                     strokeLinecap="round"
//                                                                     strokeLinejoin="round"
//                                                                 />
//                                                             </svg>
//                                                             Download
//                                                         </a>
//                                                     </li>
//                                                 </ul>
//                                                 <div className="ms-auto d-inline-flex pe-3">
//                                                     <a
//                                                         href="#!"
//                                                         className="action-item d-inline-flex p-2"
//                                                     >
//                                                         <svg
//                                                             width="17"
//                                                             height="17"
//                                                             viewBox="0 0 17 17"
//                                                             fill="none"
//                                                             xmlns="http://www.w3.org/2000/svg"
//                                                         >
//                                                             <path
//                                                                 d="M14.875 6.37501L14.875 2.12501M14.875 2.12501H10.625M14.875 2.12501L8.5 8.5M7.08333 2.125H5.525C4.33489 2.125 3.73983 2.125 3.28527 2.35661C2.88543 2.56034 2.56034 2.88543 2.35661 3.28527C2.125 3.73983 2.125 4.33489 2.125 5.525V11.475C2.125 12.6651 2.125 13.2602 2.35661 13.7147C2.56034 14.1146 2.88543 14.4397 3.28527 14.6434C3.73983 14.875 4.33489 14.875 5.525 14.875H11.475C12.6651 14.875 13.2602 14.875 13.7147 14.6434C14.1146 14.4397 14.4397 14.1146 14.6434 13.7147C14.875 13.2602 14.875 12.6651 14.875 11.475V9.91667"
//                                                                 stroke="#B0B0BB"
//                                                                 strokeWidth="1.5"
//                                                                 strokeLinecap="round"
//                                                                 strokeLinejoin="round"
//                                                             />
//                                                         </svg>
//                                                     </a>
//                                                     <a
//                                                         href="#!"
//                                                         className="action-item d-inline-flex ms-2 p-2"
//                                                     >
//                                                         <svg
//                                                             width="17"
//                                                             height="17"
//                                                             viewBox="0 0 17 17"
//                                                             fill="none"
//                                                             xmlns="http://www.w3.org/2000/svg"
//                                                         >
//                                                             <path
//                                                                 d="M1.41699 9.91667C1.41699 9.91667 1.50293 10.5182 3.99252 13.0078C6.48211 15.4974 10.5185 15.4974 13.0081 13.0078C13.8902 12.1257 14.4598 11.0495 14.7168 9.91667M1.41699 9.91667V14.1667M1.41699 9.91667H5.66699M15.5837 7.08333C15.5837 7.08333 15.4977 6.48179 13.0081 3.99219C10.5185 1.5026 6.48211 1.5026 3.99252 3.99219C3.11045 4.87426 2.5409 5.95051 2.28387 7.08333M15.5837 7.08333V2.83333M15.5837 7.08333H11.3337"
//                                                                 stroke="#B0B0BB"
//                                                                 strokeWidth="1.5"
//                                                                 strokeLinecap="round"
//                                                                 strokeLinejoin="round"
//                                                             />
//                                                         </svg>
//                                                     </a>
//                                                 </div>
//                                             </div>
//                                             {/* <div className="card-body d-flex"> */}
//                                             {/* <div
//                                                     style={{
//                                                         width: 1024,
//                                                         height: 768,
//                                                     }}
//                                                     ref={playerElRef}
//                                                 /> */}

//                                             <div className="card-body d-flex">
//                                                 <div className="session-timeline">
//                                                     <div className="session-duration"></div>
//                                                     {generateErrorMarkers()}
//                                                 </div>
//                                                 <div
//                                                     style={{
//                                                         width: 1024,
//                                                         height: 768,
//                                                     }}
//                                                     ref={playerElRef}
//                                                 />
//                                             </div>
//                                             {/* </div> */}
//                                         </div>
//                                     </Col>
//                                     <Col lg="30" md="6" className="">
//                                         <div
//                                             className="card card-body pb-3"
//                                             style={{ minHeight: "200px" }}
//                                         >
//                                             {loading ? (
//                                                 <div className="heatmaphero-logo-loader">
//                                                     <Lottie
//                                                         options={defaultOptions}
//                                                         height={100}
//                                                         width={100}
//                                                     />
//                                                 </div>
//                                             ) : (
//                                                 <>
//                                                     <div className="top">
//                                                         <h2 className="h8 mb-2 pb-1">
//                                                             Session Info
//                                                         </h2>
//                                                     </div>
//                                                     <table className="table remove-last-border mb-0 spacing-sm">
//                                                         <tbody>
//                                                             <tr>
//                                                                 <td className="text-muted">
//                                                                     Date
//                                                                 </td>
//                                                                 <td className="text-end">
//                                                                     {new Date(
//                                                                         sessionRecordRawData.replayData[0].start_time
//                                                                     ).toLocaleString()}
//                                                                 </td>
//                                                             </tr>
//                                                             <tr>
//                                                                 <td className="text-muted">
//                                                                     Country
//                                                                 </td>
//                                                                 <td className="text-end">
//                                                                     <span className="text-end d-block">
//                                                                         <span className="country-item">
//                                                                             <img
//                                                                                 src={
//                                                                                     sessionRecordRawData
//                                                                                         .replayData[0]
//                                                                                         .country_flag
//                                                                                 }
//                                                                                 // alt={`${item.country} flag`}
//                                                                                 className="me-2 flage-image"
//                                                                             />
//                                                                             {
//                                                                                 sessionRecordRawData
//                                                                                     .replayData[0]
//                                                                                     .country
//                                                                             }
//                                                                         </span>
//                                                                         {/* <img
//                                                                     src={saudiFlag}
//                                                                     alt="Saudi Arabia Flag"
//                                                                     className="me-1"
//                                                                 />
//                                                                 Saudi Arabia */}
//                                                                     </span>
//                                                                 </td>
//                                                             </tr>
//                                                             <tr>
//                                                                 <td className="text-muted">
//                                                                     User
//                                                                 </td>
//                                                                 <td className="text-end">
//                                                                     34drfsdhr53
//                                                                 </td>
//                                                             </tr>
//                                                             <tr>
//                                                                 <td className="text-muted">
//                                                                     Actions
//                                                                 </td>
//                                                                 <td className="text-end">
//                                                                     56
//                                                                 </td>
//                                                             </tr>
//                                                             <tr>
//                                                                 <td className="text-muted">
//                                                                     Pages
//                                                                 </td>
//                                                                 <td className="text-end">
//                                                                     06
//                                                                 </td>
//                                                             </tr>
//                                                             <tr>
//                                                                 <td className="text-muted">
//                                                                     Duration
//                                                                 </td>
//                                                                 <td className="text-end">
//                                                                     3:45
//                                                                 </td>
//                                                             </tr>
//                                                             <tr>
//                                                                 <td>Device</td>
//                                                                 <td className="text-end">
//                                                                     {/* <span className="d-inline-block ms-1">
//                                                                 <img
//                                                                     src={chromeIcon}
//                                                                     alt=""
//                                                                 />
//                                                             </span>
//                                                             <span className="d-inline-block ms-1">
//                                                                 <img
//                                                                     src={appleIcon}
//                                                                     alt=""
//                                                                 />
//                                                             </span>
//                                                             <span className="d-inline-block ms-1">
//                                                                 <img
//                                                                     src={pcIcon}
//                                                                     alt=""
//                                                                 />
//                                                             </span>
//                                                             <span className="d-inline-block ms-1">
//                                                                 <img
//                                                                     src={safariIcon}
//                                                                     alt=""
//                                                                 />
//                                                             </span> */}
//                                                                     <span className="browser-item">
//                                                                         {sessionRecordRawData
//                                                                             .replayData[0]
//                                                                             .browser ==
//                                                                         "Safari" ? (
//                                                                             <img
//                                                                                 src={
//                                                                                     safariIcon
//                                                                                 }
//                                                                                 alt=""
//                                                                             />
//                                                                         ) : (
//                                                                             <img
//                                                                                 src={
//                                                                                     chromeIcon
//                                                                                 }
//                                                                                 alt=""
//                                                                             />
//                                                                         )}
//                                                                     </span>
//                                                                     <span className="browser-item">
//                                                                         {sessionRecordRawData
//                                                                             .replayData[0]
//                                                                             .os ==
//                                                                         "Mac OS X" ? (
//                                                                             <img
//                                                                                 src={
//                                                                                     appleIcon
//                                                                                 }
//                                                                                 alt=""
//                                                                             />
//                                                                         ) : (
//                                                                             <img
//                                                                                 src={
//                                                                                     appleIcon
//                                                                                 }
//                                                                                 alt=""
//                                                                             />
//                                                                         )}
//                                                                     </span>
//                                                                     <span className="browser-item">
//                                                                         {sessionRecordRawData
//                                                                             .replayData[0]
//                                                                             .device ==
//                                                                         "Desktop" ? (
//                                                                             <img
//                                                                                 src={
//                                                                                     pcIcon
//                                                                                 }
//                                                                                 alt=""
//                                                                             />
//                                                                         ) : (
//                                                                             <img
//                                                                                 src={
//                                                                                     mobileIcon
//                                                                                 }
//                                                                                 alt=""
//                                                                             />
//                                                                         )}
//                                                                     </span>
//                                                                 </td>
//                                                             </tr>
//                                                             <tr>
//                                                                 <td>
//                                                                     Landing page
//                                                                 </td>
//                                                                 <td className="text-end">
//                                                                     {
//                                                                         sessionRecordRawData
//                                                                             .replayData[0]
//                                                                             .start_page
//                                                                     }
//                                                                 </td>
//                                                             </tr>
//                                                             <tr>
//                                                                 <td>
//                                                                     Exit page
//                                                                 </td>
//                                                                 <td className="text-end">
//                                                                     {
//                                                                         sessionRecordRawData
//                                                                             .replayData[0]
//                                                                             .end_page
//                                                                     }
//                                                                 </td>
//                                                             </tr>
//                                                         </tbody>
//                                                     </table>
//                                                 </>
//                                             )}
//                                         </div>
//                                         <div className="card card-body mt-3 pb-3">
//                                             <div className="top">
//                                                 <h2 className="h8 mb-2 pb-1">
//                                                     Session Info
//                                                 </h2>
//                                             </div>
//                                             <table className="table remove-last-border mb-0 hover-bg-change">
//                                                 <tbody>
//                                                     <tr className="text-muted">
//                                                         <td>
//                                                             <span className="text-lightgray position-relative d-inline-block me-2 play-icon-wrapper">
//                                                                 01{" "}
//                                                                 <svg
//                                                                     className="play-icon"
//                                                                     width="16"
//                                                                     height="16"
//                                                                     viewBox="0 0 16 16"
//                                                                     fill="none"
//                                                                     xmlns="http://www.w3.org/2000/svg"
//                                                                 >
//                                                                     <path
//                                                                         d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
//                                                                         fill="#7B3FE4"
//                                                                         stroke="#7B3FE4"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                 </svg>
//                                                             </span>
//                                                             <span className="d-inline-block">
//                                                                 06 Feb, 20:34
//                                                             </span>
//                                                         </td>
//                                                         <td className="text-end">
//                                                             74747F
//                                                         </td>
//                                                     </tr>
//                                                     <tr className="text-muted">
//                                                         <td>
//                                                             <span className="text-lightgray position-relative d-inline-block me-2 play-icon-wrapper">
//                                                                 02{" "}
//                                                                 <svg
//                                                                     className="play-icon"
//                                                                     width="16"
//                                                                     height="16"
//                                                                     viewBox="0 0 16 16"
//                                                                     fill="none"
//                                                                     xmlns="http://www.w3.org/2000/svg"
//                                                                 >
//                                                                     <path
//                                                                         d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
//                                                                         fill="#7B3FE4"
//                                                                         stroke="#7B3FE4"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                 </svg>
//                                                             </span>
//                                                             <span className="d-inline-block">
//                                                                 06 Feb, 20:34
//                                                             </span>
//                                                         </td>
//                                                         <td className="text-end">
//                                                             74747F
//                                                         </td>
//                                                     </tr>
//                                                     <tr className="text-muted">
//                                                         <td>
//                                                             <span className="text-lightgray position-relative d-inline-block me-2 play-icon-wrapper">
//                                                                 03{" "}
//                                                                 <svg
//                                                                     className="play-icon"
//                                                                     width="16"
//                                                                     height="16"
//                                                                     viewBox="0 0 16 16"
//                                                                     fill="none"
//                                                                     xmlns="http://www.w3.org/2000/svg"
//                                                                 >
//                                                                     <path
//                                                                         d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
//                                                                         fill="#7B3FE4"
//                                                                         stroke="#7B3FE4"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                 </svg>
//                                                             </span>
//                                                             <span className="d-inline-block">
//                                                                 06 Feb, 20:34
//                                                             </span>
//                                                         </td>
//                                                         <td className="text-end">
//                                                             74747F
//                                                         </td>
//                                                     </tr>
//                                                     <tr className="text-muted">
//                                                         <td>
//                                                             <span className="text-lightgray position-relative d-inline-block me-2 play-icon-wrapper">
//                                                                 04{" "}
//                                                                 <svg
//                                                                     className="play-icon"
//                                                                     width="16"
//                                                                     height="16"
//                                                                     viewBox="0 0 16 16"
//                                                                     fill="none"
//                                                                     xmlns="http://www.w3.org/2000/svg"
//                                                                 >
//                                                                     <path
//                                                                         d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
//                                                                         fill="#7B3FE4"
//                                                                         stroke="#7B3FE4"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                 </svg>
//                                                             </span>
//                                                             <span className="d-inline-block">
//                                                                 06 Feb, 20:34
//                                                             </span>
//                                                         </td>
//                                                         <td className="text-end">
//                                                             74747F
//                                                         </td>
//                                                     </tr>
//                                                     <tr className="text-muted">
//                                                         <td>
//                                                             <span className="text-lightgray position-relative d-inline-block me-2 play-icon-wrapper">
//                                                                 05{" "}
//                                                                 <svg
//                                                                     className="play-icon"
//                                                                     width="16"
//                                                                     height="16"
//                                                                     viewBox="0 0 16 16"
//                                                                     fill="none"
//                                                                     xmlns="http://www.w3.org/2000/svg"
//                                                                 >
//                                                                     <path
//                                                                         d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
//                                                                         fill="#7B3FE4"
//                                                                         stroke="#7B3FE4"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                 </svg>
//                                                             </span>
//                                                             <span className="d-inline-block">
//                                                                 06 Feb, 20:34
//                                                             </span>
//                                                         </td>
//                                                         <td className="text-end">
//                                                             74747F
//                                                         </td>
//                                                     </tr>
//                                                     <tr className="text-muted">
//                                                         <td>
//                                                             <span className="text-lightgray position-relative d-inline-block me-2 play-icon-wrapper">
//                                                                 06{" "}
//                                                                 <svg
//                                                                     className="play-icon"
//                                                                     width="16"
//                                                                     height="16"
//                                                                     viewBox="0 0 16 16"
//                                                                     fill="none"
//                                                                     xmlns="http://www.w3.org/2000/svg"
//                                                                 >
//                                                                     <path
//                                                                         d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
//                                                                         fill="#7B3FE4"
//                                                                         stroke="#7B3FE4"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                 </svg>
//                                                             </span>
//                                                             <span className="d-inline-block">
//                                                                 06 Feb, 20:34
//                                                             </span>
//                                                         </td>
//                                                         <td className="text-end">
//                                                             74747F
//                                                         </td>
//                                                     </tr>
//                                                     <tr className="text-muted">
//                                                         <td>
//                                                             <span className="text-lightgray position-relative d-inline-block me-2 play-icon-wrapper">
//                                                                 07{" "}
//                                                                 <svg
//                                                                     className="play-icon"
//                                                                     width="16"
//                                                                     height="16"
//                                                                     viewBox="0 0 16 16"
//                                                                     fill="none"
//                                                                     xmlns="http://www.w3.org/2000/svg"
//                                                                 >
//                                                                     <path
//                                                                         d="M3.33301 3.32667C3.33301 2.67922 3.33301 2.3555 3.468 2.17706C3.5856 2.0216 3.76536 1.9254 3.95994 1.91378C4.1833 1.90044 4.45265 2.08001 4.99135 2.43915L12.0017 7.11273C12.4469 7.40948 12.6694 7.55785 12.747 7.74487C12.8148 7.90837 12.8148 8.09213 12.747 8.25563C12.6694 8.44264 12.4469 8.59102 12.0017 8.88777L4.99135 13.5614C4.45265 13.9205 4.1833 14.1001 3.95994 14.0867C3.76536 14.0751 3.5856 13.9789 3.468 13.8234C3.33301 13.645 3.33301 13.3213 3.33301 12.6738V3.32667Z"
//                                                                         fill="#7B3FE4"
//                                                                         stroke="#7B3FE4"
//                                                                         strokeWidth="1.5"
//                                                                         strokeLinecap="round"
//                                                                         strokeLinejoin="round"
//                                                                     />
//                                                                 </svg>
//                                                             </span>
//                                                             <span className="d-inline-block">
//                                                                 06 Feb, 20:34
//                                                             </span>
//                                                         </td>
//                                                         <td className="text-end">
//                                                             74747F
//                                                         </td>
//                                                     </tr>
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </Col>
//                                 </Row>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default HeatMapViewRecording;
