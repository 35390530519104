import { Navigate, Route, Routes } from "react-router-dom";
import Sidebar from "../Common/Sidebar";
import { useSelector } from "react-redux";
import Dashboard from "../Private/Dashboard";
import Heatmap from "../Private/Heatmap";
import SessionReplay from "../Private/SessionReplay";
import HeatmapDetails from "../Private/HeatmapDetails";
import Alerts from "../Private/Alerts";
import CreateAlert from "../Private/CreateAlert";
import HeatMapViewRecording from "../Private/HeatmapViewRecording";
import FullScreenHeatMap from "../Private/Heatmap/FullScreenHeatMap";

const PrivateRoutes = () => {
    // const userObject = useSelector((state) => state.user.userData);
    // const companyDetails = useSelector((state) => state.user)
    // return(
    //     auth.token ? (
    //         <div className="page--dashboard-wrapper">
    //             <Sidebar userData={userObject} />
    //             <Outlet />
    //         </div>
    //     ) : (
    //             <div>
    //                 <Navigate to="/login"/>
    //             </div>
    //         )
    // )

    const userObject = useSelector((state) => state.user.userData);
    return (
        <div className="page--dashboard-wrapper">
            <Sidebar userData={userObject} />
            <Routes>
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/heatmap" element={<Heatmap />} />
                <Route
                    exact
                    path="/sessionreplay"
                    element={<SessionReplay />}
                />
                <Route
                    exact
                    path="/heatmapdetails/:pagename/:pageurl"
                    element={<HeatmapDetails />}
                />
                <Route exact path="/alerts" element={<Alerts />} />
                <Route exact path="/createalert" element={<CreateAlert />} />
                <Route
                    exact
                    path="/recording/:session_id"
                    element={<HeatMapViewRecording />}
                />
                <Route
                    exact
                    path="/fullscreenheatmap"
                    element={<FullScreenHeatMap />}
                />
                <Route exact path="/create-alert" element={<CreateAlert />} />
            </Routes>
        </div>
    );
};

export default PrivateRoutes;
