import React, { useEffect } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import InputField from "./FormFields";
import googleLogo from "./../../Assets/Images/google.svg";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector, connect } from "react-redux";
import { login } from "../../services/api";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import GoogleSignIn from "../../redux/authentication/GoogleSignin";

function Login({ loginError }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);

    const notify = () => toast.error("User or email already exist");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        if (data.username && data.password) {
            dispatch(
                login({ username: data.username, password: data.password })
            );
        }
    };

    useEffect(() => {
        const jwtCookie = Cookies.get("jwt");
        if (jwtCookie) {
            window.location.href = "/dashboard";
        }
    }, [userData]);

    // if (loginError) {
    //     console.log(loginError);
    //     notify();
    // }

    useEffect(() => {
        if (loginError) {
            console.log(loginError);
            toast.error(loginError);
        }
    }, [loginError]);

    return (
        <form className="mx-w400 mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <Toaster position="bottom-center" reverseOrder={false} />
            <h1 className="h2 mb-2">Login</h1>
            <p className="text-muted mb-5">
                Welcome back, please enter your details
            </p>
            <InputField
                label="Username"
                placeholder="Enter your username"
                type="text"
                name="username"
                register={register("username", { required: true })}
            />
            {errors.username && errors.username.type === "required" && (
                <p className="form--validation error-message">
                    Username is required.
                </p>
            )}
            <InputField
                label="Password"
                placeholder="Enter your Password"
                type="password"
                instruction="Must be at least 8 characters"
                name="password"
                register={register("password", {
                    required: true,
                    minLength: 6,
                })}
            />
            {errors.password && errors.password.type === "required" && (
                <p className="form--validation error-message">
                    Password is required.
                </p>
            )}
            {errors.password && errors.password.type === "minLength" && (
                <p className="form--validation error-message">
                    Password should be at-least 6 characters.
                </p>
            )}
            <div className="form-group">
                <Button color="primary" block>
                    Login
                </Button>
            </div>
            <div className="or-else-line-wrapper form-group">
                <span className="line--item"></span>
                <span className="text--item text-muted fw-400 h7 mx-1">
                    Or else
                </span>
                <span className="line--item"></span>
            </div>
            {/* <div className="form-group">
                <Button color="light" block>
                    <img src={googleLogo} alt="" /> Sign in with Google
                </Button>
            </div> */}
            {/* <div className="form-group text-center">
                <GoogleSignIn comp={"login"} />
            </div> */}
            <div className="form-group text-center">
                <p className="text-muted">
                    Truble login?{" "}
                    <Link
                        to="/forgotpassword"
                        className="text-primary-2 h7 fw-400"
                    >
                        {" "}
                        Forgot password{" "}
                    </Link>
                </p>
            </div>
            <div className="form-group text-center">
                <p className="text-muted">
                    Don't have an account?{" "}
                    <Link to="/signup" className="text-primary-2 h7 fw-400">
                        {" "}
                        Sign Up{" "}
                    </Link>
                </p>
            </div>
        </form>
    );
}

const mapStateToProps = (state) => ({
    loginError: state.user.error,
});

export default connect(mapStateToProps)(Login);

// export default Login;
