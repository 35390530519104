// import { createSlice } from '@reduxjs/toolkit';
// import { signUpAPI } from '../../services/api'; // import the API function

// export const userSlice = createSlice({
//   name: 'user',
//   initialState: {
//     isSigningUp: false,
//     signUpError: null,
//     userData: null,
//   },
//   reducers: {
//     startSigningUp: state => {
//       state.isSigningUp = true;
//     },
//     signUpSuccess: (state, action) => {
//       state.isSigningUp = false;
//       state.userData = action.payload;
//     },
//     signUpFailure: (state, action) => {
//       state.isSigningUp = false;
//       state.signUpError = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(signUp.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(signUp.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         // Add this line to store the user id in the state
//         state.userData = action.payload.user.id;
//       })
//       .addCase(signUp.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.error.message;
//       });
//   }
// });

// export const { startSigningUp, signUpSuccess, signUpFailure } = userSlice.actions;

// export const signUp = (name, email, password) => async dispatch => {
//   dispatch(startSigningUp());
//   try {
//     const response = await signUpAPI(name, email, password);
//     dispatch(signUpSuccess(response.data));
//   } catch (error) {
//     dispatch(signUpFailure(error.toString()));
//   }
// };

// export default userSlice.reducer;

// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { signUpAPI } from '../../services/api'; // import the API function

// // Create an async thunk action
// export const signUp = createAsyncThunk(
//   'user/signUp',
//   async (userData, thunkAPI) => {
//     const response = await signUpAPI(userData.name, userData.email, userData.password);
//     return response.data;
//   }
// );

// export const userSlice = createSlice({
//   name: 'user',
//   initialState: {
//     step: 0,
//     isSigningUp: false,
//     signUpError: null,
//     userData: null,
//   },
//   reducers: {
//     // you can leave these empty as they are handled by createAsyncThunk
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(signUp.pending, (state) => {
//         state.isSigningUp = true;
//       })
//       .addCase(signUp.fulfilled, (state, action) => {
//         state.isSigningUp = false;
//         // Add this line to store the user data in the state
//         state.userData = action.payload.user;
//       })
//       .addCase(signUp.rejected, (state, action) => {
//         state.isSigningUp = false;
//         state.signUpError = action.error.message;
//       });
//   }
// });

// export default userSlice.reducer;

// import { createSlice } from '@reduxjs/toolkit';
// import { signUpAPI } from '../../services/api'; // import the API function

// export const userSlice = createSlice({
//   name: 'user',
//   initialState: {
//     step: 0,
//     isSigningUp: false,
//     signUpError: null,
//     userData: null,
//   },
//   reducers: {
//     startSigningUp: state => {
//       state.isSigningUp = true;
//     },
//     signUpSuccess: (state, action) => {
//       state.isSigningUp = false;
//       state.userData = action.payload;
//     },
//     signUpFailure: (state, action) => {
//       state.isSigningUp = false;
//       state.signUpError = action.payload;
//     },
//     updateStep: (state, action) => {
//       state.step = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(signUp.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(signUp.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         // Add this line to store the user id in the state
//         state.userData = action.payload.user.id;
//       })
//       .addCase(signUp.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.error.message;
//       });
//   }
// });

// export const { startSigningUp, signUpSuccess, signUpFailure, updateStep } = userSlice.actions;

// export const signUp = (name, email, password) => async dispatch => {
//   dispatch(startSigningUp());
//   try {
//     const response = await signUpAPI(name, email, password);
//     dispatch(signUpSuccess(response.data));
//     // If signUp is successful, set step to 1
//     dispatch(updateStep(1));
//   } catch (error) {
//     dispatch(signUpFailure(error.toString()));
//   }
// };

// export default userSlice.reducer;

// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { signUpAPI } from '../../services/api'; // import the API function

// export const signUp = createAsyncThunk(
//   'user/signUp',
//   async ({name, email, password}, thunkAPI) => {
//     try {
//       const response = await signUpAPI(name, email, password);
//       return response.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.toString());
//     }
//   }
// );

// export const login = ({username, password}) => async (dispatch) => {
//   try {
//       const res = await axios.post("http://localhost:4001/user/login", {username, password});
//       const data = res.data;
//       Cookies.set("jwt", data.token);  // Save token to cookies
//       dispatch(loginSuccess(data.user));  // Assuming data.user contains user information
//   } catch (error) {
//       console.error("Error during login: ", error);
//       dispatch(loginFailure());  // Assuming you have loginFailure action defined
//   }
// };

// export const userSlice = createSlice({
//   name: 'user',
//   initialState: {
//     step: 0,
//     isSigningUp: false,
//     signUpError: null,
//     userData: null,
//   },
//   reducers: {
//     updateStep: (state, action) => {
//       state.step = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(signUp.pending, (state) => {
//         state.status = 'loading';
//         state.isSigningUp = true;
//       })
//       .addCase(signUp.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         state.isSigningUp = false;
//         // Add this line to store the user id in the state
//         state.userData = action.payload.user;
//         // If signUp is successful, set step to 1
//         state.step = 1;
//       })
//       .addCase(signUp.rejected, (state, action) => {
//         state.status = 'failed';
//         state.isSigningUp = false;
//         state.signUpError = action.payload;
//       });
//   }
// });

// export const { updateStep } = userSlice.actions;

// export default userSlice.reducer;

// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { signUpAPI, loginAPI } from '../../services/api';
// import Cookies from "js-cookie";
// import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

// export const signUp = createAsyncThunk(
//   'user/signUp',
//   async ({name, email, password}, thunkAPI) => {
//     try {
//       const response = await signUpAPI(name, email, password);
//       return response.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.toString());
//     }
//   }
// );

// export const login = createAsyncThunk(
//   'user/login',
//   async ({username, password}, thunkAPI) => {
//     try {
//       const response = await loginAPI(username, password);
//       const data = response.data;
//       Cookies.set("jwt", data.token);
//       return data.user;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.toString());
//     }
//   }
// );

// export const userSlice = createSlice({
//   name: 'user',
//   initialState: {
//     step: 0,
//     isSigningUp: false,
//     signUpError: null,
//     userData: null,
//     isLoggingIn: false,
//     loginError: null,
//   },
//   reducers: {
//     updateStep: (state, action) => {
//       state.step = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(signUp.pending, (state) => {
//         state.status = 'loading';
//         state.isSigningUp = true;
//       })
//       .addCase(signUp.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         state.isSigningUp = false;
//         state.userData = action.payload.user;
//         state.step = 1;
//       })
//       .addCase(signUp.rejected, (state, action) => {
//         state.status = 'failed';
//         state.isSigningUp = false;
//         state.signUpError = action.payload;
//       })
//       .addCase(login.pending, (state) => {
//         state.status = 'loading';
//         state.isLoggingIn = true;
//       })
//       .addCase(login.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         state.isLoggingIn = false;
//         state.userData = action.payload;
//       })
//       .addCase(login.rejected, (state, action) => {
//         state.status = 'failed';
//         state.isLoggingIn = false;
//         state.loginError = action.payload;
//       });
//   }
// });

// export const loginSuccess = createAction('user/loginSuccess');
// export const loginFailure = createAction('user/loginFailure');

// export const { updateStep } = userSlice.actions;

// export default userSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signUpAPI, googleSignInAPI } from "../../services/api"; // import the API function

export const signUp = createAsyncThunk(
    "user/signUp",
    async ({ name, email, password }, thunkAPI) => {
        try {
            const response = await signUpAPI(name, email, password);
            console.log("Response from API", response.data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.toString());
        }
    }
);

export const googleSignIn = createAsyncThunk(
    "user/googleSignIn",
    async (idToken, thunkAPI) => {
        try {
            const response = await googleSignInAPI(idToken);
            // localStorage.setItem("userData", JSON.stringify(data.user));
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.toString());
        }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState: {
        step: 0,
        isSigningUp: false,
        signUpError: null,
        userData: JSON.parse(localStorage.getItem("userData")) || null,
    },
    reducers: {
        startSignUp: (state) => {
            state.error = null;
        },
        updateStep: (state, action) => {
            state.step = action.payload;
        },
        loginSuccess: (state, action) => {
            state.userData = action.payload;
        },
        loginFailure: (state, action) => {
            state.userData = null;
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signUp.pending, (state) => {
                state.status = "loading";
                state.isSigningUp = true;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isSigningUp = false;
                state.userData = action.payload.user;
                state.step = 1;
                state.error = action.payload.message;
            })
            .addCase(signUp.rejected, (state, action) => {
                state.status = "failed";
                state.isSigningUp = false;
                state.signUpError = action.payload;
                state.error = action.error.message;
            })
            .addCase(googleSignIn.pending, (state) => {
                state.status = "loading";
                state.isSigningIn = true;
            })
            .addCase(googleSignIn.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isSigningIn = false;
                state.userData = action.payload.user;
                state.step = 1;
                state.error = action.payload.message;
            })
            .addCase(googleSignIn.rejected, (state, action) => {
                state.status = "failed";
                state.isSigningIn = false;
                state.signInError = action.payload;
                state.error = action.error.message;
            });
    },
});

export const { updateStep, loginSuccess, loginFailure } = userSlice.actions;

export default userSlice.reducer;
export const { startSignUp } = userSlice.actions;
