import _style from "../Popups/_style.scss";

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].payload.date} : ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

export const BounceRateTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div className="custom-tooltip">
                <p className="label">Date: {data.date}</p>
                <div className="d-flex chart-indicator-tooltip">
                    <div className="chart-indicator bounce-rate"></div>:{" "}
                    {data.bounceRate}%
                </div>
                <div className="d-flex chart-indicator-tooltip">
                    <div className="chart-indicator engagement-rate"></div>:{" "}
                    {data.engagementRate}%
                </div>
            </div>
        );
    }
    return null;
};

export default CustomTooltip;
