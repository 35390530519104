import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchMouseData } from "../../redux/company/companySlice";
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
} from "reactstrap";
import Lottie from "react-lottie";
import * as animationData from "../../Assets/Gif/heatmapherogif.json";
import UserDialogModel from "../Common/UserDialogModel";

function Heatmap(props) {
    const dispatch = useDispatch();
    const mouseData = useSelector((state) => state.company.mouseData);
    const userObject = useSelector((state) => state.user.userData);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [pageData, setPageData] = useState([]);

    useEffect(() => {
        if (
            userObject &&
            userObject.cd.length &&
            userObject.cd[0].website_url
        ) {
            dispatch(fetchMouseData(userObject.cd[0].website_url));
        }
    }, [dispatch, userObject]);

    const details = mouseData?.details;
    const pagesData = Array.isArray(details) ? details : [];

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const [isModalOpen, setModalOpen] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    // const handleClick = async (pageId) => {
    //     try {
    //         const response = await fetch(`/api/page/${pageId}`);
    //         const data = await response.json();
    //         setPageData(data);
    //     } catch (error) {
    //         console.error("Failed to load page data", error);
    //     }
    // };

    if (userObject.cd.length === 0) {
        return (
            <div className="d-flex flex-column main--wrapper">
                <div className="content w-100 d-flex align-items-center justify-content-center">
                    <div className="content-inner">
                        <div className="alert-box--wrapper text-center w-400 mx-auto">
                            <Button
                                color="white"
                                size="sm"
                                className="btn btn-sm btn-primary rounded-12"
                                onClick={() => setModalOpen(true)}
                            >
                                Install Script
                            </Button>
                        </div>
                    </div>
                </div>
                <UserDialogModel
                    isOpen={isModalOpen}
                    setIsOpen={setModalOpen}
                />
            </div>
        );
    } else if (pagesData.length === 0) {
        return (
            <div className="loader-image">
                <Lottie options={defaultOptions} height={100} width={100} />
            </div>
        );
    }

    return (
        <div className="main--wrapper">
            <header className="header--wrapper">
                <div className="header--inner container">
                    <div className="left">
                        <h1 className="h4 mb-0 d-flex align-items-center">
                            Heatmaps{" "}
                            <svg
                                width="20"
                                height="20"
                                className="ms-2"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_1005_6849)">
                                    <path
                                        d="M8.50033 11.3337V8.50033M8.50033 5.66699H8.50741M15.5837 8.50033C15.5837 12.4123 12.4123 15.5837 8.50033 15.5837C4.58831 15.5837 1.41699 12.4123 1.41699 8.50033C1.41699 4.58831 4.58831 1.41699 8.50033 1.41699C12.4123 1.41699 15.5837 4.58831 15.5837 8.50033Z"
                                        stroke="#B0B0BB"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1005_6849">
                                        <rect
                                            width="17"
                                            height="17"
                                            fill="white"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </h1>
                    </div>
                    <div className="right d-flex">
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle className="btn-sm d-flex align-items-center">
                                <svg
                                    width="20"
                                    className="dropdown-left-icon"
                                    height="17"
                                    viewBox="0 0 20 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.66699 2.33333C1.66699 1.86662 1.66699 1.63327 1.75782 1.45501C1.83771 1.29821 1.9652 1.17072 2.122 1.09083C2.30026 1 2.53362 1 3.00033 1H17.0003C17.467 1 17.7004 1 17.8787 1.09083C18.0355 1.17072 18.1629 1.29821 18.2428 1.45501C18.3337 1.63327 18.3337 1.86662 18.3337 2.33333V2.89116C18.3337 3.11516 18.3337 3.22716 18.3063 3.3313C18.282 3.42359 18.2421 3.51103 18.1882 3.5898C18.1275 3.67869 18.0428 3.75204 17.8736 3.89875L12.5438 8.51792C12.3745 8.66462 12.2898 8.73797 12.2291 8.82687C12.1752 8.90564 12.1353 8.99308 12.111 9.08536C12.0837 9.18951 12.0837 9.30151 12.0837 9.52551V13.882C12.0837 14.0449 12.0837 14.1264 12.0574 14.1969C12.0342 14.2591 11.9964 14.3149 11.9472 14.3596C11.8916 14.4102 11.8159 14.4404 11.6646 14.501L8.83125 15.6343C8.52497 15.7568 8.37182 15.8181 8.24889 15.7925C8.14138 15.7702 8.04704 15.7063 7.98637 15.6148C7.91699 15.5101 7.91699 15.3452 7.91699 15.0153V9.52551C7.91699 9.30151 7.91699 9.18951 7.88962 9.08536C7.86537 8.99308 7.82544 8.90564 7.77158 8.82687C7.71081 8.73797 7.62617 8.66462 7.4569 8.51792L2.12708 3.89875C1.95781 3.75204 1.87318 3.67869 1.8124 3.5898C1.75855 3.51103 1.71862 3.42359 1.69436 3.3313C1.66699 3.22716 1.66699 3.11516 1.66699 2.89116V2.33333Z"
                                        stroke="#74747F"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Top
                                <svg
                                    className="dropdown-right-icon"
                                    width="10"
                                    height="7"
                                    viewBox="0 0 10 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L5 5.5L9 1.5"
                                        stroke="#74747F"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>3 Months</DropdownItem>
                                <DropdownItem>6 Months</DropdownItem>
                                <DropdownItem>9 Months</DropdownItem>
                                <DropdownItem>12 Months</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Button
                            color="secondary"
                            size="sm"
                            className="notification--btn px-2 ms-2"
                        >
                            <svg
                                className="btn-icon"
                                width="20"
                                height="23"
                                viewBox="0 0 20 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.35395 20.5C8.05908 21.1224 8.98533 21.5 9.99977 21.5C11.0142 21.5 11.9405 21.1224 12.6456 20.5M15.9998 7.5C15.9998 5.9087 15.3676 4.38258 14.2424 3.25736C13.1172 2.13214 11.5911 1.5 9.99977 1.5C8.40848 1.5 6.88235 2.13214 5.75713 3.25736C4.63192 4.38258 3.99977 5.9087 3.99977 7.5C3.99977 10.5902 3.22024 12.706 2.34944 14.1054C1.6149 15.2859 1.24763 15.8761 1.2611 16.0408C1.27601 16.2231 1.31463 16.2926 1.46155 16.4016C1.59423 16.5 2.19237 16.5 3.38863 16.5H16.6109C17.8072 16.5 18.4053 16.5 18.538 16.4016C18.6849 16.2926 18.7235 16.2231 18.7384 16.0408C18.7519 15.8761 18.3846 15.2859 17.6501 14.1054C16.7793 12.706 15.9998 10.5902 15.9998 7.5Z"
                                    stroke="#292934"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="badge badge-danger">03</span>
                        </Button>
                    </div>
                </div>
            </header>
            <div className="content">
                <div className="content-inner">
                    <div className="container">
                        <div className="recent-heatmapes-list">
                            <h2 className="h5 mb-3">Recent heatmaps</h2>
                            <Row className="g-4">
                                {/* <Col xxl="3" lg="4" md="6">
										<div className="card">
											<div className="card-header">
												<img src={homepageImage} alt="homepage" width="100%" />
											</div>
											<div className="card-body pt-0 d-flex">
												<div className="left flex-grow-1">
													<h4 className="dash-card-title mb-0 h6">Homepage</h4>
													<Link to={`/heatmap/${5}`} className="text-gray h7 d-inline-block mt-1">
														www.achm...om/home
													</Link>

												</div>
												<div className="right">
													<a href="#!">
														Click me
													</a>
												</div>
											</div>
										</div>
									</Col> */}
                                {pagesData?.map((page, index) => (
                                    <Col xxl="3" lg="4" md="6" key={index}>
                                        <div className="card">
                                            <div className="card-header">
                                                <img
                                                    src={page.screenshot_url}
                                                    className="img-fluid"
                                                    alt="homepage"
                                                    width="100%"
                                                />
                                            </div>
                                            <div className="card-body pt-0 d-flex">
                                                <div className="left flex-grow-1">
                                                    <h4 className="dash-card-title mb-0 h6">
                                                        Homepage
                                                    </h4>
                                                    <Link
                                                        to={`/heatmapdetails/${
                                                            page.id
                                                        }/${encodeURIComponent(
                                                            page.page_url
                                                        )}`}
                                                        className="text-gray h7 d-inline-block mt-1"
                                                    >
                                                        {page.page_url}
                                                    </Link>
                                                </div>
                                                <div className="right">
                                                    <a href="#!">
                                                        <svg
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.50033 9.20801C8.89153 9.20801 9.20866 8.89088 9.20866 8.49967C9.20866 8.10847 8.89153 7.79134 8.50033 7.79134C8.10912 7.79134 7.79199 8.10847 7.79199 8.49967C7.79199 8.89088 8.10912 9.20801 8.50033 9.20801Z"
                                                                stroke="#B0B0BB"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M8.50033 4.24967C8.89153 4.24967 9.20866 3.93254 9.20866 3.54134C9.20866 3.15014 8.89153 2.83301 8.50033 2.83301C8.10912 2.83301 7.79199 3.15014 7.79199 3.54134C7.79199 3.93254 8.10912 4.24967 8.50033 4.24967Z"
                                                                stroke="#B0B0BB"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M8.50033 14.1663C8.89153 14.1663 9.20866 13.8492 9.20866 13.458C9.20866 13.0668 8.89153 12.7497 8.50033 12.7497C8.10912 12.7497 7.79199 13.0668 7.79199 13.458C7.79199 13.8492 8.10912 14.1663 8.50033 14.1663Z"
                                                                stroke="#B0B0BB"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Heatmap;
