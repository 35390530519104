import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
} from "reactstrap";
import PieChartComponent from "../../Charts/PieChart";
import { getUserCount } from "../../../redux/dashboard/dashboardSlice";

function countUsers(data) {
    let newUserIPs = new Set();
    let returningUserIPs = new Set();

    if (!data) return 0; // Return 0 if data is null or undefined

    for (let item of data) {
        let ip = item.ip;
        let count = parseInt(item.count);

        // Ignore "Unknown" IP addresses
        if (ip === "Unknown") continue;

        if (count === 1) {
            newUserIPs.add(ip);
        } else if (count > 1) {
            returningUserIPs.add(ip);
        }
    }

    let newUsers = newUserIPs.size;
    let returningUsers = returningUserIPs.size;

    // Total users is the sum of new and returning users
    let totalUsers = newUsers + returningUsers;

    // console.log(`New users: ${newUsers}`);
    // console.log(`Returning users: ${returningUsers}`);
    // console.log(`Total users: ${totalUsers}`);

    return {
        newuser: newUsers,
        returninguser: returningUsers,
        totaluser: totalUsers,
    };
}

function UserCount(props) {
    const dispatch = useDispatch();

    const { userCountData, status, error } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        if (props.userId) {
            dispatch(getUserCount(props.userId));
        }
    }, [dispatch]);

    const totalUsers = countUsers(userCountData);

    return (
        <Col md="6">
            <div className="card">
                <div className="card-body">
                    <div className="dash-card-header">
                        <h4 className="dash-card-title mb-0 h6">
                            New vs. Returning user
                        </h4>

                        <span className="text-info h6 mb-0 icon-item d-flex align-items-center">
                            {/* 2.15% */}
                            <svg
                                className="ms-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <rect
                                    width="20"
                                    height="20"
                                    fill="#E1F1F5"
                                    rx="10"
                                ></rect>
                                <path
                                    stroke="#378A9C"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M6.667 13.334l6.667-6.667m0 0H6.667m6.667 0v6.667"
                                ></path>
                            </svg>
                        </span>
                    </div>
                    <div className="dash-card-body chart-item-wrapper">
                        {/* <div className="left"> */}
                        <PieChartComponent
                            ChartTotalData={totalUsers.totaluser}
                            ChartColorOne={"#AA84ED"}
                            ChartColorTwo={"#FABA81"}
                            ChartValueOne={totalUsers.newuser}
                            ChartValueTwo={totalUsers.returninguser}
                        />
                        {/* </div> */}
                        <div className="right">
                            <div className="chart-list-item circle-blue">
                                <h6 className="h7 fw-normal mb-1">New users</h6>
                                <h4 className="h5 mb-0">
                                    {totalUsers.newuser}
                                </h4>
                            </div>
                            <div className="chart-list-item circle-orange">
                                <h6 className="h7 fw-normal mb-1">
                                    Returning users
                                </h6>
                                <h4 className="h5 mb-0">
                                    {totalUsers.returninguser}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
}

export default UserCount;
