// import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom'
// import Dashboard from '../Private/Dashboard'
// import Heatmap from '../Private/Heatmap';
// import SessionReplay from '../Private/SessionReplay';
// import PrivateRoutes from '../Utils/PrivateRoutes';
// import PublicSidebar from '../Common/PublicSidebar';
// import HeatmapDetails from '../Private/HeatmapDetails';
// import Alerts from '../Private/Alerts';
// import CreateAlert from '../Private/CreateAlert';
// import HeatMapViewRecording from '../Private/HeatmapViewRecording';

// function RouteApp(props) {
//   const navigate = useNavigate();
//   const [auth, setAuth] = useState({ token: false });

//   useEffect(() => {
//     const token = document.cookie.split('; ').find(row => row.startsWith('jwt=')).split('=')[1];
//     setAuth({ token: token !== 'null' && token !== undefined });
//     if(auth.token){
//       navigate('/'); // navigate to home page if token exists
//     }
//   }, [auth.token, navigate]);

//   const step = useSelector((state) => state.user.step);
//     return (
//         <Router>
//           <Routes>
//           {auth.token ? (
//             <Route element={<PrivateRoutes auth={auth} />}>
//                 <Route path="*" element={<Navigate to="/" />} />
//                 <Route exact path="/" element={<Dashboard />}/>
//                 <Route exact path="/heatmap" element={<Heatmap />}/>
//                 <Route exact path="/sessionreplay" element={<SessionReplay />}/>
//                 <Route exact path="/heatmap/:pagename" element={<HeatmapDetails />} />
//                 <Route exact path="/alerts" element={<Alerts />} />
//                 <Route exact path="/createalert" element={<CreateAlert />} />
//                 <Route exact path="/recording" element={<HeatMapViewRecording />}/>
//             </Route>
//           ):(
//             <>
//                 <Route
//                   index
//                   element={
//                     auth.token ? (
//                       <Navigate to="/" />
//                     ) : (
//                       <PublicSidebar routeName={"login"} />
//                     )
//                   }
//                   path="/login"
//                 />
//                 <Route
//                   element={
//                     auth.token ? (
//                       <Navigate to="/" />
//                     ) : (
//                       <PublicSidebar routeName={"signup"} />
//                     )
//                   }
//                   path="/signup"
//                 />
//                 <Route
//                   element={
//                     auth.token ? (
//                       <Navigate to="/" />
//                     ) : (
//                       step >= 1 ? (
//                         <PublicSidebar routeName={"companydetails"} />
//                       ) : (
//                         <Navigate to="/signup" />
//                       )
//                     )
//                   }
//                   path="/companydetails"
//                 />
//                 <Route
//                   element={
//                     auth.token ? (
//                       <Navigate to="/" />
//                     ) : (
//                       <PublicSidebar routeName={"codeintegration"} />
//                     )
//                   }
//                   path="/codeintegration"
//                 />
//                 <Route path="*" element={<Navigate to="/login" />} />
//               </>
//             )}
//           </Routes>
//       </Router>
//     );
// }

// export default RouteApp;

// RouteApp
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PrivateRoutes from "../Utils/PrivateRoutes";
import PublicRoutes from "../Utils/PublicRoutes";

function RouteApp(props) {
    //   const navigate = useNavigate();
    //   const [auth, setAuth] = useState({ token: false });
    //   const [isLoading, setLoading] = useState(true);

    //   const step = useSelector((state) => {
    //     if (state.user) {
    //       return state.user.step;
    //     }
    //     return null;
    //   });

    // useEffect(() => {
    //     const jwtCookie = document.cookie.split('; ').find(row => row.startsWith('jwt='));
    //     const token = jwtCookie ? jwtCookie.split('=')[1] : null;
    //     if (token && token !== 'null') {
    //       setAuth({ token: token });
    //     } else {
    //       setAuth({ token: null });
    //     }
    //     setLoading(false);
    //   }, [navigate]);

    //   if (isLoading) {
    //     return (
    //       <div>
    //         Loading...
    //       </div>
    //     );
    //   }
    //     return (
    //           <Routes>
    //           {auth.token ? (
    //             <Route element={<PrivateRoutes auth={auth} />}>
    //                 <Route path="*" element={<Navigate to="/" />} />
    //                 <Route exact path="/" element={<Dashboard />}/>
    //                 <Route exact path="/heatmap" element={<Heatmap />}/>
    //                 <Route exact path="/sessionreplay" element={<SessionReplay />}/>
    //                 <Route exact path="/heatmap/:pagename" element={<HeatmapDetails />} />
    //                 <Route exact path="/alerts" element={<Alerts />} />
    //                 <Route exact path="/createalert" element={<CreateAlert />} />
    //                 <Route exact path="/recording" element={<HeatMapViewRecording />}/>
    //             </Route>
    //           ):(
    //             <>
    //                 <Route
    //                   index
    //                   element={
    //                     auth.token ? (
    //                       <Navigate to="/" />
    //                     ) : (
    //                       <PublicSidebar routeName={"login"} />
    //                     )
    //                   }
    //                   path="/login"
    //                 />
    //                 <Route
    //                   element={
    //                     auth.token ? (
    //                       <Navigate to="/" />
    //                     ) : (
    //                       <PublicSidebar routeName={"signup"} />
    //                     )
    //                   }
    //                   path="/signup"
    //                 />
    //                 <Route
    //                   element={
    //                     auth.token ? (
    //                       <Navigate to="/" />
    //                     ) : (
    //                       step >= 1 ? (
    //                         <PublicSidebar routeName={"companydetails"} />
    //                       ) : (
    //                         <Navigate to="/signup" />
    //                       )
    //                     )
    //                   }
    //                   path="/companydetails"
    //                 />
    //                 <Route
    //                   element={
    //                     auth.token ? (
    //                       <Navigate to="/" />
    //                     ) : (
    //                       <PublicSidebar routeName={"codeintegration"} />
    //                     )
    //                   }
    //                   path="/codeintegration"
    //                 />
    //                 <Route path="*" element={<Navigate to="/login" />} />
    //               </>
    //             )}
    //           </Routes>
    //     );

    // const navigate = useNavigate();
    const [auth, setAuth] = useState({ token: false });
    const [isLoading, setLoading] = useState(true);

    const step = useSelector((state) => {
        return state.user?.step || null;
    });

    // useEffect(() => {
    //     const jwtCookie = document.cookie
    //         .split("; ")
    //         .find((row) => row.startsWith("jwt="));
    //     const token = jwtCookie ? jwtCookie.split("=")[1] : null;
    //     if (token && token !== "null") {
    //         setAuth({ token: token });
    //     } else {
    //         setAuth({ token: null });
    //     }
    //     setLoading(false);
    // }, [navigate]);

    useEffect(() => {
        const jwtCookie = document.cookie
            .split("; ")
            .find((row) => row.startsWith("jwt="));
        const token = jwtCookie ? jwtCookie.split("=")[1] : null;
        if (token && token !== "null") {
            setAuth({ token: token });
        } else {
            setAuth({ token: null });
        }
        setLoading(false);
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }
    return auth.token ? (
        <PrivateRoutes auth={auth} />
    ) : (
        <PublicRoutes step={step} />
    );
}

export default RouteApp;
