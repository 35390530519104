import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import {
    FastResourceIcon,
    FastestResourceIcon,
    SlowResourceIcon,
    ThreeDotsIcon,
} from "../../../Assets/icons";
import { useEffect } from "react";
import { getLongestDurationResources } from "../../../redux/dashboard/dashboardSlice";
import {
    extractFileName,
    getMaxTransferSize,
    normalizeTransferSize,
    truncateText,
    truncateUrl,
} from "../Helper";

const SolwestResources = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getLongestDurationResources(props.userId));
    }, [dispatch]);
    const { longestDurationResources } = useSelector(
        (state) => state.dashboard
    );
    const list = longestDurationResources?.data;
    const maxTransferSize = getMaxTransferSize(list);
    return (
        <div className="card-body scroll--height pt-0 top-click-section">
            {list &&
                list.length > 0 &&
                list?.map((item, index) => {
                    const truncatedName = truncateText(item.name, 20);
                    return (
                        <div key={index} className="progress-item">
                            <div className="progress-label">
                                <div>
                                    {index == 0 || index == 1 || index == 2 ? (
                                        <SlowResourceIcon />
                                    ) : index == 3 || index == 4 ? (
                                        <FastResourceIcon />
                                    ) : index == 5 || index == 6 ? (
                                        <FastestResourceIcon />
                                    ) : (
                                        ""
                                    )}
                                    <span className="left">
                                        <a
                                            href={item.name}
                                            className="link"
                                            title={item.name}
                                        >
                                            {extractFileName(item.name)}{" "}
                                            {truncateUrl(item.name)}
                                        </a>
                                    </span>
                                </div>
                                <div className="right">
                                    {parseInt(item.duration)}
                                </div>
                            </div>
                            {/* <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                                width: `${normalizeTransferSize(
                                                    item.duration,
                                                    maxTransferSize
                                                )}%`,
                                            }}
                                            aria-valuenow={normalizeTransferSize(
                                                item.duration,
                                                maxTransferSize
                                            )}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div> */}
                        </div>
                    );
                })}
        </div>
    );
};

export default SolwestResources;
