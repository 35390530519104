import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import {
    getCurrentSession,
    getCurrentSessionPercentageSlice,
    getCurrentSessionChartDataSlice,
} from "../../../redux/dashboard/dashboardSlice";
import AreaChartComponent from "../../Charts/AreaChart";
import CurrentSessionPopup from "../../Popups/CurrentSessionPopup";

function CurrentSession({ userId, md }) {
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const [chartData, setChartData] = useState([]);
    let widthMD = md ? md : 4;

    const getCurrentSessionData = useCallback(() => {
        dispatch(getCurrentSession(userId));
        dispatch(getCurrentSessionPercentageSlice(userId));
        dispatch(getCurrentSessionChartDataSlice(userId));
    }, [dispatch, userId]);

    const {
        currentSession,
        currentSessionPercentage,
        currentSessionChartDataList,
        status,
        error,
    } = useSelector((state) => ({
        currentSession: state.dashboard.currentSession,
        currentSessionPercentage: state.dashboard.currentSessionPercentage,
        currentSessionChartDataList:
            state.dashboard.currentSessionChartDataList,
        status: state.dashboard.status,
        error: state.dashboard.error,
    }));

    useEffect(() => {
        getCurrentSessionData();
    }, [getCurrentSessionData]);

    useEffect(() => {
        setChartData(transformData(currentSessionChartDataList));
    }, [currentSessionChartDataList]);

    let currentSessionCount = 0;
    if (currentSession && currentSession.length) {
        currentSessionCount = currentSession[0]?.count;
    }

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    // const dataSecond = [
    //     {
    //         name: "Page A",
    //         count: 100,
    //     },
    //     {
    //         name: "Page B",
    //         count: 150,
    //     },
    //     {
    //         name: "Page C",
    //         count: 200,
    //     },
    //     {
    //         name: "Page D",
    //         count: 220,
    //     },
    //     {
    //         name: "Page E",
    //         count: 260,
    //     },
    //     {
    //         name: "Page F",
    //         count: 290,
    //     },
    //     {
    //         name: "Page G",
    //         count: 310,
    //     },
    // ];

    function transformData(data) {
        const today = new Date().toISOString().split("T")[0];
        const result = [];
        const hours = {};

        if (!data) {
            return;
        }

        // Initialize 24 hours with zeros
        for (let i = 0; i < 24; i++) {
            const key = i.toString().padStart(2, "0") + ":00";
            hours[key] = { uv: 0, pv: 0 };
        }

        data.forEach((item) => {
            const hour = new Date(item.hour).getHours();
            const key = hour.toString().padStart(2, "0") + ":00";
            if (item.hour.startsWith(today)) {
                hours[key].pv = parseInt(item.session_count, 10);
            } else {
                hours[key].uv = parseInt(item.session_count, 10);
            }
        });

        Object.keys(hours).forEach((key) => {
            result.push({
                name: key,
                Yesterday: hours[key].uv,
                Today: hours[key].pv,
            });
        });
        return result;
    }

    if (!currentSessionPercentage) {
        return <h1>Loading...</h1>;
    }

    return (
        <Col md={widthMD}>
            <div className="card" onClick={handleShowPopup}>
                <div className="card-body">
                    <div className="dash-card-header">
                        <h4 className="dash-card-title mb-0 h6">
                            Cuurent Session
                        </h4>
                        <span
                            className={`${
                                currentSessionPercentage[0]?.comparison ==
                                "Higher"
                                    ? "text-info"
                                    : "text-danger"
                            } text-danger h6 mb-0 icon-item d-flex align-items-center`}
                        >
                            {Math.round(
                                currentSessionPercentage[0]
                                    ?.percentage_difference * 100
                            ) / 100}
                            %
                            {currentSessionPercentage[0]?.comparison ==
                            "Higher" ? (
                                <svg
                                    className="ms-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <rect
                                        width="20"
                                        height="20"
                                        fill="#E1F1F5"
                                        rx="10"
                                    ></rect>
                                    <path
                                        stroke="#378A9C"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M6.667 13.334l6.667-6.667m0 0H6.667m6.667 0v6.667"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="none"
                                    className="ms-2"
                                    viewBox="0 0 20 20"
                                >
                                    <rect
                                        width="20"
                                        height="20"
                                        fill="#FBE1E6"
                                        rx="10"
                                    ></rect>
                                    <path
                                        stroke="#BC3852"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M6.667 6.667l6.667 6.667m0 0V6.667m0 6.667H6.667"
                                    ></path>
                                </svg>
                            )}
                        </span>
                    </div>
                    <div className="dash-card-body">
                        <h3 className="h2">{currentSessionCount}</h3>
                        <div className="chart-wrapper">
                            <AreaChartComponent
                                id={"orange"}
                                firstColor={"#fd7e14"}
                                strokeColor={"#f8943a"}
                                data={chartData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
                <div className="overlay">
                    <CurrentSessionPopup
                        onClose={handleClosePopup}
                        firstColor={"#f8943a"}
                        strokeColor={"#f8943a"}
                        id={"orange"}
                        rawData={chartData}
                        userId={userId}
                        parentComponent={"currentSession"}
                    />
                </div>
            )}
        </Col>
    );
}

export default CurrentSession;
