import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ComposableMap,
    Geographies,
    Geography,
    Annotation,
} from "react-simple-maps";
import countryMap from "../../../Assets/Images/mapbackground.png";
import { geoMercator } from "d3-geo";
import { getCountryWiseData } from "../../../redux/dashboard/dashboardSlice";
import countryCoordinates from "../../../Assets/Json/Countries.json";
const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

function DataWiseMap({ setTooltipContent, userId }) {
    const dispatch = useDispatch();

    let { countryWiseData, status, error } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        if (userId) {
            dispatch(getCountryWiseData(userId));
        }
    }, [dispatch]);

    function getCoordinates(countryName) {
        const countryData = countryCoordinates.find(
            (c) => c.name === countryName
        );
        if (countryData) {
            return [countryData.longitude, countryData.latitude];
        }
        return [0, 0];
    }

    if (!countryWiseData) {
        return <h1>Loading...</h1>;
    }

    const countriesCount = countryWiseData?.reduce((obj, item) => {
        obj[item.country] = item.count;
        return obj;
    }, {});

    return (
        <ComposableMap
            projection={geoMercator()}
            projectionConfig={{
                scale: 200,
                rotation: [0, 0, 0],
            }}
            width={980}
            height={551}
            style={{
                width: "100%",
                height: "auto",
            }}
        >
            <defs>
                <pattern
                    id="backgroundPattern"
                    patternUnits="userSpaceOnUse"
                    width="980"
                    height="551"
                >
                    <image
                        href={countryMap}
                        x="0"
                        y="0"
                        width="980"
                        height="551"
                    />
                </pattern>
            </defs>

            <Geographies geography={geoUrl}>
                {({ geographies }) =>
                    geographies.map((geo) => {
                        const country = geo.properties.NAME;
                        const count = countriesCount[country] || "0";
                        return (
                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                onMouseEnter={() => {
                                    setTooltipContent("Hellllo");
                                }}
                                onMouseLeave={() => {
                                    setTooltipContent("Heyyy");
                                }}
                                style={{
                                    default: {
                                        fill:
                                            count > 0
                                                ? "rebeccapurple"
                                                : "url(#backgroundPattern)",
                                        outline: "none",
                                    },
                                    hover: {
                                        fill: "#aa83ed",
                                        outline: "none",
                                    },
                                }}
                            />
                        );
                    })
                }
            </Geographies>
            {countryWiseData.map((countryData, i) => {
                const coordinates = getCoordinates(countryData.country);
                return (
                    <React.Fragment key={i}>
                        <Annotation
                            key={countryData.country}
                            subject={coordinates}
                            dx={0}
                            dy={0}
                        >
                            <circle
                                r={30}
                                style={{ pointerEvents: "none" }}
                                fill="#9E77ED"
                                opacity={0.1}
                                className="blinking-circle"
                            />
                            <circle
                                r={18}
                                style={{ pointerEvents: "none" }}
                                fill="#9E77ED"
                                opacity={0.2}
                                className="blinking-circle-smaller"
                            />
                            <circle
                                r={8}
                                style={{ pointerEvents: "none" }}
                                fill="#9E77ED"
                            />
                        </Annotation>

                        <Annotation
                            key={i}
                            subject={coordinates}
                            dx={0}
                            dy={0}
                            color="#333"
                        >
                            <text
                                textAnchor="middle"
                                alignmentBaseline="middle"
                                fontSize={10}
                            >
                                {/* {countryData.count} */}
                            </text>
                        </Annotation>
                    </React.Fragment>
                );
            })}
        </ComposableMap>
    );
}

export default DataWiseMap;
